import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useDebounceCallback } from 'boards-web-ui'

import {
  FormIntegrationStatusData,
  getValidateSpreadSheetURL,
} from '../../../../_firebase'

type Props = {
  integrationStatusState: FormIntegrationStatusData | null
  isGoogleAccountConnected: boolean
  onPaywall: () => void
}
const useGoogleSheetConnector = ({
  integrationStatusState,
  isGoogleAccountConnected,
  onPaywall,
}: Props) => {
  const { t } = useTranslation()
  const [spreadsheetURL, setSpreadsheetURL] = useState<string>('')
  const [googleSheetError, setGoogleSheetError] = useState<string>('')
  const [isGoogleAccountConnectedPending, setIsGoogleAccountConnectedPending] =
    useState<boolean>(false)

  const isGoogleFormConnected = useMemo(() => {
    return integrationStatusState?.googleAuthorization?.status !== 'authorized'
  }, [integrationStatusState])

  useEffect(() => {
    if (isGoogleAccountConnected) {
      setIsGoogleAccountConnectedPending(false)
    }
  }, [isGoogleAccountConnected])

  const googleSheetResponseError: string = useMemo(() => {
    if (isGoogleAccountConnected && !spreadsheetURL.trim().length) {
      return t('info_missing_enter_google_sheet')
    }

    if (
      isGoogleAccountConnected &&
      spreadsheetURL.trim().length &&
      googleSheetError
    ) {
      return googleSheetError
    }

    return ''
  }, [spreadsheetURL, googleSheetError, isGoogleAccountConnected, t])

  const onValidateSpreadSheetURL = useDebounceCallback(
    async (spreadSheetURL: string) => {
      try {
        const result = await getValidateSpreadSheetURL(spreadSheetURL)
        if (!result) {
          return
        }

        if (result?.status.spreadsheet !== 'found') {
          setGoogleSheetError(t('forms_valid_google_sheet_error'))
        }
      } catch (e) {
        // nothing to do there
      }
    },
    300,
  )

  const onGoogleSheetsUrlChange = useCallback(
    async (url: string) => {
      setGoogleSheetError('')
      setSpreadsheetURL(url)
      onValidateSpreadSheetURL(url)
    },
    [setSpreadsheetURL, setGoogleSheetError, onValidateSpreadSheetURL],
  )

  const onConnectAccountToGoogleSheets = useCallback(() => {
    if (integrationStatusState?.googleAuthorization?.status !== 'authorized') {
      setIsGoogleAccountConnectedPending(true)
      window.open(integrationStatusState?.googleAuthorization?.authorizationURL)
    } else {
      onPaywall()
    }
  }, [integrationStatusState, onPaywall])

  return {
    isGoogleAccountConnectedPending,
    spreadsheetURL,
    isGoogleFormConnected,
    googleSheetResponseError,
    onGoogleSheetsUrlChange,
    onConnectAccountToGoogleSheets,
  } as const
}

export default useGoogleSheetConnector
