import { useDispatch } from 'react-redux'
import { HttpsCallableResult } from '@firebase/functions'
import { t } from 'i18next'
import { useDialog } from 'boards-web-ui'
import useFolderEvents from '@features/analytics/useFolderEvents'
import useToolbarEvents from '@features/analytics/useToolbarEvents'
import DeleteNodeConfirmation from '@features/toolbar/components/DeleteNodeConfirmation'
import useBulk from '@hooks/bulk/useBulk'
import { bulkActions, nodeActions } from '../../actions'
import useBoard from '../useBoard'
import useIsApiAvailableOrNotify from '../useIsApiAvailableOrNotify'
import useShortcut from '../useShortcut'
import { Node } from '../../app/models'
import { recordSelection } from '../../_firebase/contentAPI'

const useBulkActions = () => {
  const { folder, boardId } = useBoard()
  const { open, close } = useDialog()
  const { bulk, resetBulkActions } = useBulk()
  const isApiAvailableOrNotify = useIsApiAvailableOrNotify()
  const dispatch = useDispatch()
  const targetFolderId = folder?.id
  const { getShortcut } = useShortcut()
  const { folderDeleteContentEvent } = useFolderEvents()
  const { shareSelectionButtonClicked } = useToolbarEvents()

  const shareAction = async () => {
    const shortcutResult = await recordSelection({
      boardId,
      folderId: targetFolderId,
      nodes: bulk.nodes.length ? bulk.nodes : [targetFolderId],
    })
    const shortcut = (shortcutResult as HttpsCallableResult<string>).data

    const fullShortcut = getShortcut({ shortcut } as Node)

    shareSelectionButtonClicked(bulk.nodes.length || 1, fullShortcut || '')

    return fullShortcut
  }

  const runDelete = () => {
    if (!isApiAvailableOrNotify()) return

    dispatch(
      nodeActions.deleteNodes(
        boardId,
        targetFolderId,
        bulk.nodes.map(({ id }) => id),
      ),
    )
    close()
    resetBulkActions()
    folderDeleteContentEvent(boardId)
  }

  const deleteAction = () => {
    dispatch(bulkActions.setBulkActionType('delete'))

    open(
      <DeleteNodeConfirmation
        title={t('confirmation_message_delete_selected_key')}
        body={t('confirmation_message_delete_selected_content')}
        onCancel={() => {
          close()
          resetBulkActions()
        }}
        onConfirm={runDelete}
      />,
    )
  }

  return {
    deleteAction,
    shareAction,
  } as const
}

export default useBulkActions
