import { createContext } from 'react'
import { FormField } from 'boards-web-ui'

import { FormsAction } from '../models'
import { AfterSubmitBehaviourEnum } from '../constants'

type EditFormsState = {
  isSubmitted: boolean
  isNew: boolean
  isOwn: boolean
  action: FormsAction
  formTitle: string
  formsFields: Array<FormField>
  formAction?: string | null
  responsesEmail: string
  responsesEmailError: string
  formRedirectUrl?: string | null
  formCustomMessage?: string | null
  afterSubmitBehaviour: AfterSubmitBehaviourEnum
  postSubmissionError: string
  isGoogleAccountConnected: boolean
  spreadsheetURL: string | null
  googleSheetResponseError: string
  advancedForms?: boolean
  onPaywall: () => void
  onResponsesEmailChange: (email: string) => void
  onGoogleSheetsUrlChange: (url: string) => void
  onConnectAccountToGoogleSheets: () => void
  onFormTitleChange: (updatedTitle: string) => void
  onAddFormsField: (field?: FormField) => Promise<FormField | null>
  onDeleteFormsField: (field: FormField) => void
  onUpdateFormsField: (field: Partial<FormField>) => void
  onFormFieldsReOrder: (fields: Array<FormField>) => void
  onFormActionChange: (newFormActions: string) => void
  onFormRedirectUrlChange: (newFormRedirectUrl: string) => void
  onFormCustomMessageChange: (newFormCustomMessage: string) => void
  onAfterSubmitBehaviourChange: (
    newAfterSubmitBehaviour: AfterSubmitBehaviourEnum,
  ) => void
  onFormCancel: () => void
  isGoogleAccountConnectedPending: boolean
}
const EditFormsContext = createContext<EditFormsState>({} as EditFormsState)

export default EditFormsContext
