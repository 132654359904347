import { DocumentSnapshot } from 'firebase/firestore'

import { parseBoolean } from '@helpers/bool'
import {
  DefaultClientConfig,
  EmailAdressClientConfig,
} from 'app/models/clientConfig'

export const transformEmailAddressDoc = (
  doc: DocumentSnapshot,
): EmailAdressClientConfig => {
  const data = doc.data()

  return {
    format: data?.format,
    frequentlyUsedDomains: data?.frequentlyUsedDomains || [],
    topLevelDomains: data?.topLevelDomains || [],
  }
}

export const transformClientConfigurationDoc = (
  doc: DocumentSnapshot,
): DefaultClientConfig => {
  const data = doc.data()

  return {
    reverseTrial: {
      isActive: parseBoolean(data?.options?.shareAllowed, true),
    },
  }
}
