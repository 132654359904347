import { Trans } from 'react-i18next'
import { ReactComponent as ZoomInIcon } from '../icons/ZoomIn.svg'
import { ReactComponent as ZoomOutIcon } from '../icons/ZoomOut.svg'
import { ReactComponent as ZoomResetIcon } from '../icons/ZoomReset.svg'
import { SimpleButton } from '../../../../ui/components/SimpleButton'
import Tooltip, { TooltipContent } from '../../../../ui/components/Tooltip'
import { TooltipPositions } from '../../../../ui/models/tooltip'

import styles from './ZoomButtons.module.css'

interface Props {
  reset: (() => void) | null
  increase: (() => void) | null
  decrease: (() => void) | null
}

const ZoomButtons = ({ reset, increase, decrease }: Props) => {
  const handleReset = () => reset && reset()
  const handleDecrease = () => decrease && decrease()
  const handleIncrease = () => increase && increase()

  return (
    <div className={styles.Root}>
      <Tooltip
        position={TooltipPositions.BOTTOM}
        data={
          <TooltipContent className={styles.Tooltip}>
            <Trans i18nKey={'action_zoom_out'} />
          </TooltipContent>
        }
      >
        <SimpleButton
          className={decrease ? '' : styles.IconDisabled}
          fitToContent
          darkGray
          small
          onClick={handleDecrease}
          disabled={!decrease}
        >
          <ZoomOutIcon />
        </SimpleButton>
      </Tooltip>
      <Tooltip
        position={TooltipPositions.BOTTOM}
        data={
          <TooltipContent className={styles.Tooltip}>
            <Trans i18nKey={'action_reset_zoom'} />
          </TooltipContent>
        }
      >
        <SimpleButton
          className={reset ? '' : styles.IconDisabled}
          fitToContent
          darkGray
          small
          onClick={handleReset}
          disabled={!reset}
        >
          <ZoomResetIcon />
        </SimpleButton>
      </Tooltip>
      <Tooltip
        position={TooltipPositions.BOTTOM}
        data={
          <TooltipContent className={styles.Tooltip}>
            <Trans i18nKey={'action_zoom_in'} />
          </TooltipContent>
        }
      >
        <SimpleButton
          className={increase ? '' : styles.IconDisabled}
          fitToContent
          darkGray
          small
          onClick={handleIncrease}
          disabled={!increase}
        >
          <ZoomInIcon />
        </SimpleButton>
      </Tooltip>
    </div>
  )
}

export default ZoomButtons
