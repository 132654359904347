import React, { PropsWithChildren, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import {
  ButtonNew,
  ErrorIndicator,
  KingClearIcon,
  Tooltip,
  TooltipContent,
  TooltipPositions,
  useDialog,
} from 'boards-web-ui'

import { DropDown, DropDownPosition } from '@ui/components/DropDown'
import useBoard from '@hooks/useBoard'
import { SimpleButton } from '@ui/components/SimpleButton'
import { usePreviewModalSaveActions } from '@features/nodePreview/components/usePreviewModalSaveActions'
import { BoardMode } from '@features/board/models/nodes'
import useBoardMode from '@features/board/hooks/useBoardMode'
import Grid from '../../../../elements/Grid'
import styles from './PreviewModal.module.css'
import PreviewModalHeader from './PreviewModalHeader'
import { ReactComponent as SuccessIcon } from '../../../../icons/ActionSuccess.svg'
import { ReactComponent as CloseIcon } from '../icons/Close.svg'
import { ReactComponent as ShareIcon } from '../icons/Share.svg'
import { ReactComponent as EditIcon } from '../icons/Edit.svg'
import { ReactComponent as MoreIcon } from '../icons/More.svg'
import { ReactComponent as PrevIcon } from '../icons/Prev.svg'
import { ReactComponent as NextIcon } from '../icons/Next.svg'
import PreviewModalBody from './PreviewModalBody'
import { NodeActionsMenu } from './NodeActionsMenu'
import { Folder, NodeType } from '../../../models'
import usePreviewNode from '../hooks/usePreviewNode'

type Props = PropsWithChildren<{
  nid: string
  centered?: React.ReactNode
  copyText: string
  onCopyClick: () => void
  prev?: () => void
  next?: () => void
  folder?: Folder
  isSharedFolderMode?: boolean
  canCopyContentOfSharedFolder?: boolean
  sharedBoardId?: string
  sharedFolderId?: string
  missedIndicator?: boolean
}>

const PreviewModal: React.FC<React.PropsWithChildren<Props>> = ({
  nid,
  centered,
  copyText,
  onCopyClick,
  next,
  prev,
  children,
  folder,
  isSharedFolderMode,
  canCopyContentOfSharedFolder,
  sharedBoardId,
  sharedFolderId,
  missedIndicator,
}) => {
  const { t } = useTranslation()
  const { isReadOnly: isReadOnlyBoard, board } = useBoard()
  const mode = useBoardMode()

  const isReadOnly = isSharedFolderMode && folder ? true : isReadOnlyBoard
  const isPage = isSharedFolderMode && folder ? folder?.isPage : isReadOnlyBoard

  const shareAllowed =
    isSharedFolderMode && folder ? false : board?.options?.shareAllowed

  const { node, edit } = usePreviewNode(
    nid,
    folder,
    isSharedFolderMode,
    sharedBoardId,
    sharedFolderId,
  )

  const { canCopy, onSaveActionClick } = usePreviewModalSaveActions({
    nodeId: node?.source.id,
    nodeType: node?.source.type,
  })

  const { close } = useDialog()

  const [isOpen, setIsOpen] = useState(false)

  if (!node) return null

  const onPrev = () => prev && prev()

  const onNext = () => next && next()

  let shareToastTextId = ''

  switch (node.node.type as unknown as NodeType) {
    case NodeType.TEXT:
      shareToastTextId = 'toast_text_copied_to_clipboard'
      break
    case NodeType.IMAGE:
      shareToastTextId = 'toast_image_copied_to_clipboard'
      break
    default:
      shareToastTextId = 'toast_link_copied_to_clipboard'
  }

  const threeHeaderStyle = {
    gridTemplateColumns: '1fr 119px minmax(300px, 1fr)',
  }

  return (
    <div className={styles.Root}>
      <PreviewModalHeader
        style={centered ? threeHeaderStyle : undefined}
        onClick={close}
      >
        <Grid
          rowGap={20}
          columnGap={20}
          height="38px"
          justifyContent="flex-start"
        >
          <SimpleButton
            darkGray
            mediumBtnIcon
            fitToContent
            customSize={38}
            style={{ padding: '0px 12px' }}
            onClick={() => close()}
          >
            <CloseIcon />
          </SimpleButton>
          <span className={styles.Title}>{node.node.title}</span>
        </Grid>
        {centered}

        {!isSharedFolderMode && (
          <Grid rowGap={10} height="38px" justifyContent="flex-end">
            {!isReadOnly && !isPage && (
              <SimpleButton darkGray fitToContent onClick={edit}>
                <EditIcon className={styles.Icon} />
                &nbsp; <Trans i18nKey={'action_edit'} />
              </SimpleButton>
            )}
            {shareAllowed && (
              <Tooltip
                position={
                  isReadOnly || isPage
                    ? TooltipPositions.LEFT
                    : TooltipPositions.BOTTOM
                }
                data={
                  <TooltipContent icon={<SuccessIcon />} feedback>
                    <Trans i18nKey={shareToastTextId} />
                  </TooltipContent>
                }
                feedback
              >
                <SimpleButton darkGray fitToContent onClick={onCopyClick}>
                  <ShareIcon className={styles.Icon} />
                  <span className={styles.CopyText}>{copyText}</span>
                </SimpleButton>
              </Tooltip>
            )}

            {mode === BoardMode.offline ? null : (
              <DropDown
                position={DropDownPosition.right}
                isOpen={isOpen}
                onToggle={setIsOpen}
                className={styles.MenuActions}
                content={
                  <NodeActionsMenu
                    node={node.source}
                    onItemClick={close}
                    setMenuOpen={setIsOpen}
                    isReadOnly={isReadOnly}
                  />
                }
              >
                <SimpleButton
                  darkGray
                  mediumBtnIcon
                  fitToContent
                  customSize={38}
                >
                  <MoreIcon />
                </SimpleButton>
              </DropDown>
            )}
          </Grid>
        )}
      </PreviewModalHeader>

      <PreviewModalBody
        withScroll={[
          NodeType.PDF,
          NodeType.TEXT,
          NodeType.PARAGRAPH,
          NodeType.PAGE,
          NodeType.VIDEO_STREAMING,
        ].includes(node.source.type)}
        onClick={close}
      >
        <div onClick={(e) => e.stopPropagation()}>
          <SimpleButton
            className={styles.PrevIcon}
            customSize={46}
            onClick={onPrev}
            disabled={!prev}
          >
            <PrevIcon />
          </SimpleButton>
        </div>

        {missedIndicator ? (
          <ErrorIndicator
            className={styles.Banner}
            onClick={
              !isReadOnly
                ? (e) => {
                    e.stopPropagation()
                    edit()
                  }
                : undefined
            }
          >
            {t('info_missing_page_banner')}
          </ErrorIndicator>
        ) : null}

        {children}
        <div onClick={(e) => e.stopPropagation()}>
          <SimpleButton
            className={styles.NextIcon}
            customSize={46}
            onClick={onNext}
            disabled={!next}
          >
            <NextIcon />
          </SimpleButton>
        </div>
      </PreviewModalBody>

      {isSharedFolderMode && canCopyContentOfSharedFolder ? (
        <div className={styles.SharedFolderModeSaveButtonContainer}>
          <div className={styles.SaveButton}>
            <ButtonNew
              size={'large'}
              color={'primary'}
              onClick={onSaveActionClick}
              startIcon={
                canCopy ? undefined : (
                  <KingClearIcon
                    fontSize="20px"
                    style={{ marginTop: '-2px' }}
                  />
                )
              }
            >
              <Trans i18nKey={'action_save'} />
            </ButtonNew>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default PreviewModal
