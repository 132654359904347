import { FC, memo, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import {
  ButtonNew,
  GoogleSheetsIcon,
  Icon,
  InformationIcon,
  InputField,
  Spacer,
} from 'boards-web-ui'

import useFocusState from '@hooks/useFocusState'

import Label from '../../../../ui/components/Label'
import styles from './GoogleSheetConnect.module.css'

interface Props {
  isOwn: boolean
  isGoogleAccountConnectedPending: boolean
  isGoogleAccountConnected?: boolean
  spreadsheetURL?: string | null
  googleSheetResponseError: string
  onConnectAccountToGoogleSheets: () => void
  onGoogleSheetsUrlChange: (spreadsheetURL: string) => void
}

const GoogleSheetConnect: FC<Props> = ({
  isOwn,
  isGoogleAccountConnectedPending,
  isGoogleAccountConnected,
  spreadsheetURL = '',
  googleSheetResponseError = '',
  onGoogleSheetsUrlChange,
  onConnectAccountToGoogleSheets,
}) => {
  const { t } = useTranslation()
  const { isFocused, ...focusHandlers } = useFocusState()
  const googleSheetsUrlRef = useRef<HTMLDivElement>(null)
  const googleSheetsUrlInputRef = useRef<HTMLInputElement>(null)

  if (!isOwn) {
    return (
      <div>
        <Label
          subtitle={<Trans i18nKey={'forms_collect_responses_subtitle'} />}
        />
        <Spacer height={5} />
        <p className={styles.OnlyOwn}>
          <Icon className={styles.Icon}>
            <InformationIcon />
          </Icon>
          {t('only_owners_connect_payment_provider')}
        </p>
        <Spacer height={35} />
      </div>
    )
  }

  return (
    <>
      <div>
        <div className={styles.Row}>
          <Label
            subtitle={<Trans i18nKey={'forms_collect_responses_subtitle'} />}
          />
        </div>

        <Spacer height={5} />

        {isGoogleAccountConnected ? (
          <div className={styles.ConnectedGoogleSheet}>
            <div className={styles.IconWrapper}>
              <GoogleSheetsIcon />
            </div>

            <div>
              <p className={styles.ButtonTitle}>{t('forms_google_sheets')}</p>
              <p className={styles.ButtonSubTitle}>{t('label_connected')}</p>
            </div>
          </div>
        ) : (
          <>
            <ButtonNew
              size="xl"
              fullWidth
              color="green"
              onClick={onConnectAccountToGoogleSheets}
              startIcon={<GoogleSheetsIcon />}
              isLoading={isGoogleAccountConnectedPending}
            >
              <Trans i18nKey={'forms_collect_responses_button'} />
            </ButtonNew>
            <Spacer height={35} />
          </>
        )}
      </div>

      {isGoogleAccountConnected && (
        <div ref={googleSheetsUrlRef}>
          <Spacer height={20} />
          <InputField
            redVariant={!isFocused && !!googleSheetResponseError}
            ref={googleSheetsUrlInputRef}
            className={styles.GoogleSheetsUrlInput}
            value={spreadsheetURL || ''}
            label={t('forms_google_sheet_url_helper_text')}
            placeholder={t('forms_google_sheet_url_placeholder')}
            errorMessage={!isFocused ? googleSheetResponseError : ''}
            onChange={(e) => onGoogleSheetsUrlChange(e.target.value)}
            {...focusHandlers}
          />
          <Spacer height={30} />
        </div>
      )}
    </>
  )
}

export default memo(GoogleSheetConnect)
