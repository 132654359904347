import { useParams } from 'react-router-dom'
import { useDialog } from 'boards-web-ui'

import { Checkout } from '@features/checkout'
import { INodeProps } from '@features/nodeEditor/models/IUseNodeCreatorAPI'
import { PaywallSourceEnum } from '@features/analytics/useAccountSubscriptionEvents'
import { useProfileInformation } from '@features/profile/hooks/useProfileInformation'
import usePaywall from '@hooks/usePaywall'
import useBoard from '@hooks/useBoard'

import { IUseAddNode } from '../models/IUseAddNode'

const useAddCheckout: IUseAddNode = (onCreate) => {
  const { open, close } = useDialog()
  const { openPaywall } = usePaywall()
  const { pageId } = useParams()
  const {
    hideCheckoutHint,
    profile: {
      plan: {
        features: { advancedForms },
      },
    },
  } = useProfileInformation()

  const { boardId, isOwnBoard } = useBoard()

  const onPaywall = () => {
    openPaywall(PaywallSourceEnum.FORM_POST_SUBMISSION)
  }

  const onSave = (data: INodeProps) => {
    onCreate(data)
    hideCheckoutHint()
    close()
  }

  return () => {
    open(
      <Checkout
        isOwn={isOwnBoard}
        pageId={pageId || ''}
        boardId={boardId}
        advancedForms={advancedForms}
        onPaywall={onPaywall}
        onClose={close}
        onSave={onSave}
      />,
      {
        fullScreen: true,
        blankDialog: true,
      },
    )
  }
}

export default useAddCheckout
