import React from 'react'

import BoardTitle from './components/BoardTitle'
import BoardTools from './components/BoardTools'

import styles from './BoardToolBar.module.css'

type Props = {
  className?: string
}
const BoardToolBar: React.FC<Props> = ({ className }) => {
  return (
    <div className={[styles.Root, className].join(' ')}>
      <BoardTitle />
      <BoardTools />
    </div>
  )
}

export default BoardToolBar
