import { FC } from 'react'
import { Trans } from 'react-i18next'
import { Tooltip, TooltipContent } from 'boards-web-ui'
import useCopyShortcuts from '@hooks/useCopyShortcuts'

import { SimpleButton } from '../../../../ui/components/SimpleButton'
import { ReactComponent as ShareIcon } from '../../../../icons/ActionShare.svg'
import { ReactComponent as SuccessIcon } from '../../../../icons/ActionSuccess.svg'
import { ReactComponent as Spinner } from '../../../icons/Spinner-24px.svg'

import useShortcut from '../../../../hooks/useShortcut'
import useFeatureFlags from '../../../../hooks/useFeatureFlags'

import { Folder } from '../../../models'

import styles from './PageLink.module.css'

interface Props {
  folder: Folder
  onDoneClick?: () => void
}
const PageLink: FC<Props> = ({ folder, onDoneClick }) => {
  const { sharedFolderFeatureFlag } = useFeatureFlags()

  const { copyFolderShortcut } = useCopyShortcuts()
  const { getShortcut } = useShortcut()
  const shortcut = getShortcut(folder)

  const onCopyClick = () => copyFolderShortcut(folder, false)
  const shareDisabled = !sharedFolderFeatureFlag || !shortcut

  return (
    <div className={styles.Root}>
      <div className={styles.LinkWrapper}>
        <div className={styles.Link}>
          <span className={styles.PageLink}>
            <Trans i18nKey="label_page_link" />:
          </span>
          &nbsp;
          {shortcut ? (
            <a target="_blank" rel="noreferrer" href={shortcut}>
              {shortcut.replace('https://', '')}
            </a>
          ) : (
            <Spinner
              style={{ display: 'inline-block', verticalAlign: 'middle' }}
            />
          )}
          <Tooltip
            data={
              <TooltipContent icon={<SuccessIcon />} feedback>
                <Trans i18nKey={'toast_link_copied_to_clipboard'} />
              </TooltipContent>
            }
            feedback
            disabled={shareDisabled}
            className={styles.TooltipBottom}
          >
            <SimpleButton
              fitToContent
              onClick={onCopyClick}
              disabled={shareDisabled}
              className={styles.Button}
            >
              <ShareIcon className={styles.Icon} />
              &nbsp;
              <Trans i18nKey={'action_share'} />
            </SimpleButton>
          </Tooltip>
        </div>
      </div>
      <SimpleButton primary medium name="Done" onClick={onDoneClick}>
        <Trans i18nKey="action_done" />
      </SimpleButton>
    </div>
  )
}

export default PageLink
