import React, { FC } from 'react'
import { Icon, KingClearIcon } from 'boards-web-ui'

import useBoardMenu from '../hooks/useBoardMenu'

import BoardActivateButton from './BoardActivateButton'
import MenuItem from './MenuItem'

import styles from './BoardMenuItem.module.css'

interface Props {
  inactive?: boolean
  boardId: string
  isBoardSelected: boolean
  selectedFolderId: string | undefined
  initialStateCheck?: boolean
  isPageBoard?: boolean
}

const BoardMenuItem: FC<Props> = ({
  inactive,
  boardId,
  isBoardSelected,
  selectedFolderId,
  initialStateCheck,
  isPageBoard = false,
}) => {
  const { menu } = useBoardMenu({
    boardId,
    isBoardSelected,
    selectedFolderId,
  })

  if (
    menu === undefined ||
    (!menu.isPageBoard && isPageBoard) ||
    (menu.isPageBoard && !isPageBoard)
  )
    return null

  return (
    <MenuItem
      level={1}
      menu={menu}
      inactive={inactive}
      initialStateCheck={initialStateCheck}
      className={!menu.isActive && !menu.isPageBoard ? styles.Opacity : ''}
      isRoot
    >
      {!menu.isActive && !menu.isPageBoard ? (
        <BoardActivateButton
          onActivate={menu.onActivate}
          icon={
            menu.limitReached ? (
              <Icon size="15px" color="inherit">
                <KingClearIcon />
              </Icon>
            ) : undefined
          }
        />
      ) : null}
    </MenuItem>
  )
}

export default React.memo(BoardMenuItem)
