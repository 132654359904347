import React, { FC, memo } from 'react'
import { Trans } from 'react-i18next'
import { ReactComponent as EyeIcon } from '../icons/Eye.svg'

import styles from './UpdatesMessageSeenBy.module.css'

interface Props {
  count: number
}

const UpdatesMessageSeenBy: FC<Props> = ({ count = 0 }) => (
  <span className={styles.Root}>
    <EyeIcon />
    <span className={styles.SeenBy}>
      <Trans i18nKey="updates_insights_seen_by" values={{ count }} />
    </span>
  </span>
)

export default memo(UpdatesMessageSeenBy)
