import { useCallback } from 'react'

import { EventCategories } from '@features/analytics/models/logEvents'
import { PaywallSourceEnum } from '@features/analytics/useAccountSubscriptionEvents'
import { useLogEvents } from '@features/analytics/useLogEvents'

const usePaywallEvents = () => {
  const { trigger } = useLogEvents()

  const paywallView = useCallback(
    (campaignManagerId?: string) => {
      trigger(EventCategories.APP_PAYWALL_VIEW, {
        campaign_manager_id: campaignManagerId,
      })
    },
    [trigger],
  )

  const paywallSource = (source: PaywallSourceEnum) =>
    trigger(EventCategories.APP_PAYWALL_SOURCE, {
      source,
    })

  const paywallTabClickEvent = (tab: string) =>
    trigger(EventCategories.APP_PAYWALL_TAB_CLICK, { tab })

  const paywallSubscriptionStartEvent = (
    plan: string,
    subscriptionCycle: string,
    productId?: string,
    campaignManagerId?: string,
  ) =>
    trigger(EventCategories.APP_SUBSCRIPTION_START, {
      plan,
      product_id: productId,
      campaign_manager_id: campaignManagerId,
      subscription_cycle: subscriptionCycle,
    })

  const paywallSubscribeClickEvent = (
    plan: string,
    productId?: string,
    campaignManagerId?: string,
  ) =>
    trigger(EventCategories.APP_PAYWALL_SUBSCRIBE_CLICK, {
      plan,
      product_id: productId,
      campaign_manager_id: campaignManagerId,
    })

  const paywallCycleClickEvent = (subscriptionCycle: string) =>
    trigger(EventCategories.APP_PAYWALL_CYCLE_CLICK, {
      subscription_cycle: subscriptionCycle,
    })

  return {
    paywallView,
    paywallSource,
    paywallTabClickEvent,
    paywallSubscriptionStartEvent,
    paywallSubscribeClickEvent,
    paywallCycleClickEvent,
  }
}

export default usePaywallEvents
