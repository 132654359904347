import React, { FC, memo } from 'react'
import { SimpleButton } from '../../../../ui/components/SimpleButton'
import { SimpleDialog } from '../../../../ui/components/SimpleDialog'

import { useUpdatesTranslations } from '../hooks/useUpdatesTranslations'
import { useUpdatesEvents } from '../../analytics/useUpdatesEvents'
import { useOnMount } from '../../../../ui/hooks/useOnMount'
import styles from './DeleteMessageConfirmation.module.css'

type Props = {
  onCancel: () => void
  onDelete: () => void
}

export const DeleteMessageConfirmation: FC<Props> = ({
  onCancel,
  onDelete,
}) => {
  const {
    cancelValue,
    deleteValue,
    deleteConfirmationMessage,
    deleteConfirmationTitle,
  } = useUpdatesTranslations()

  const { deletePopUpUpdateShown } = useUpdatesEvents()
  useOnMount(deletePopUpUpdateShown)

  return (
    <SimpleDialog
      className={styles.NonDefault}
      title={deleteConfirmationTitle()}
      message={<p className={styles.Centred}>{deleteConfirmationMessage()}</p>}
    >
      <SimpleButton
        className={styles.Button}
        secondary
        large
        onClick={onCancel}
      >
        {cancelValue()}
      </SimpleButton>
      <SimpleButton className={styles.Button} primary large onClick={onDelete}>
        {deleteValue()}
      </SimpleButton>
    </SimpleDialog>
  )
}

export default memo(DeleteMessageConfirmation)
