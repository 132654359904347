import { FC, memo } from 'react'

import CheckoutResponsesEmail from './CheckoutResponsesEmail'
import CheckoutGoogleSheetsConnector from './CheckoutGoogleSheetsConnector'

import styles from './CheckoutResponses.module.css'

const CheckoutSettingsResponses: FC = () => {
  return (
    <div className={styles.Root}>
      <CheckoutResponsesEmail />
      <CheckoutGoogleSheetsConnector />
    </div>
  )
}

export default memo(CheckoutSettingsResponses)
