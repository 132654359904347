import { memo, FC } from 'react'
import { Trans } from 'react-i18next'
import { isNumber } from 'lodash-es'

import { useProfileInformation } from '@features/profile/hooks/useProfileInformation'
import { SimpleButton } from '../../../../ui/components/SimpleButton'
import { useUpdatesEvents } from '../../analytics/useUpdatesEvents'
import { ReactComponent as KingIcon } from '../../../../icons/king.svg'
import useMessageLimits from '../../../../hooks/useMessageLimits'

import styles from './UpdatesMessageLimits.module.css'

const UpdatesMessageLimits: FC = () => {
  const { profile } = useProfileInformation()
  const {
    messagesFreeBannerView,
    messagesOverBannerView,
    messagesFreeBannerCLick,
    messagesOverBannerCLick,
  } = useUpdatesEvents()

  const { handleUpgrade, limitReached, freeCountLeft } = useMessageLimits(
    messagesOverBannerView,
    messagesFreeBannerView,
    messagesOverBannerCLick,
    messagesFreeBannerCLick,
    profile.plan,
    false,
  )

  return (
    <SimpleButton
      lightBlue200={!limitReached}
      primary={limitReached}
      fitToContent
      style={{ paddingLeft: '15px', paddingRight: '15px' }}
      className={styles.UpgradeButton}
      onClick={handleUpgrade}
    >
      <p>
        <Trans
          i18nKey={
            isNumber(freeCountLeft) && freeCountLeft <= 1
              ? 'messages_banner_single'
              : 'messages_banner_plural'
          }
          values={{ count: freeCountLeft }}
        />
      </p>
      <div className={styles.IconContainer}>
        <KingIcon width={15} />
        &nbsp;
        <Trans i18nKey="paywall_upgrade" />
      </div>
    </SimpleButton>
  )
}

export default memo(UpdatesMessageLimits)
