import { useNavigate } from 'react-router-dom'

import { Node, NodeType } from '../../models'
import { INodeProps } from './models/IUseNodeCreatorAPI'

import useEditPdf from './hooks/useEditPdf'
import useEditCheckout from './hooks/useEditCheckout'
import useEditForm from './hooks/useEditForm'
import useEditText from './hooks/useEditText'
import useEditLink from './hooks/useEditLink'
import useEditImage from './hooks/useEditImage'
import useEditAudio from './hooks/useEditAudio'
import useEditVideo from './hooks/useEditVideo'
import useEditButton from './hooks/useEditButton'
import useEditHeadline from './hooks/useEditHeadline'
import useEditParagraph from './hooks/useEditParagraph'
import useEditVideoStreaming from './hooks/useEditVideoStreaming'
import { IsMissedFormProperties } from '../../../utils/findMissedFieldsInForm'

const useEditNode = (
  boardId: string,
  folderId: string,
  updateNode: (id: string, node: INodeProps) => void,
  isPageEditor?: boolean,
) => {
  const navigate = useNavigate()

  const editText = useEditText(updateNode)
  const editLink = useEditLink(updateNode)
  const editPdf = useEditPdf(updateNode)
  const editVideo = useEditVideo(updateNode, isPageEditor)
  const editImage = useEditImage(updateNode, isPageEditor)
  const editButton = useEditButton(updateNode)
  const editCheckoutForm = useEditCheckout(updateNode)
  const editForm = useEditForm(updateNode)
  const editHeadline = useEditHeadline(updateNode)
  const editParagraph = useEditParagraph(updateNode)
  const editAudio = useEditAudio(updateNode)
  const editVideoStreaming = useEditVideoStreaming(updateNode, isPageEditor)

  const editNode = (
    node: Node,
    missedFormProperties?: IsMissedFormProperties,
    initialScreen?: string,
  ) => {
    if (!node) return

    switch (node.type) {
      case NodeType.CHECKOUT:
        editCheckoutForm(node, initialScreen)
        break
      case NodeType.FORM:
        editForm(node, initialScreen)
        break
      case NodeType.VIDEO_STREAMING:
        editVideoStreaming(node, folderId, boardId)
        break
      case NodeType.TEXT:
        editText(node)
        break
      case NodeType.PARAGRAPH:
        editParagraph(node)
        break
      case NodeType.BUTTON:
        editButton(node)
        break
      case NodeType.HEADLINE:
        editHeadline(node)
        break
      case NodeType.LINK:
        editLink(node)
        break
      case NodeType.PDF:
        editPdf(node)
        break
      case NodeType.VIDEO:
        editVideo(node)
        break
      case NodeType.IMAGE:
        editImage(node)
        break
      case NodeType.PAGE:
        navigate(`/${boardId}/${folderId}/edit-page/${node.id}`)
        break
      case NodeType.AUDIO:
        editAudio(node)
        break

      // no default
    }
  }

  return editNode
}

export default useEditNode
