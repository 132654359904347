import { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ProfilingForm } from 'boards-web-ui'
import getQuestionsData from '@features/profiling/util/getQuestionsData'
import useProfilingEvents from '@features/analytics/useProfilingEvents'
import {
  answerLeader,
  firstQuestion,
  ProfilingQuestionKeysEnum,
  ProfilingQuestionReportKeys,
} from '@features/profiling/models'
import useHandleAnswerSubmit, {
  TeamSizeOptionByRank,
  TimeActiveOptionByRank,
} from '@features/profiling/hooks/useHandleAnswerSubmit'
import useProfilingAPI from '@features/profiling/hooks/useProfilingAPI'
import useOverwriteRouteRedirect from '@hooks/useOverwriteRouteRedirect'
import { useAuth } from '@hooks/useAuth'
import { isMobile } from '../../../../utils/deviceParser'
import { EDITOR, PROFILING, WELCOME } from '../../../constants/routes'
import { updateUserProperties } from '../../../../api/AnalyticsAPI'
import styles from './ProfilingContent.module.css'

const convertSubmitDataToMarketingData = (submitData: {
  [id: string]: string
}) => {
  const submitDataClone = submitData
  const rankAnswer = submitData[ProfilingQuestionKeysEnum.PROFILING_RANK]
  if (rankAnswer) {
    submitDataClone[ProfilingQuestionKeysEnum.TEAM_SIZE] =
      TeamSizeOptionByRank[rankAnswer]
    submitDataClone[ProfilingQuestionKeysEnum.TIME_IN_BUSINESS] =
      TimeActiveOptionByRank[rankAnswer]
  }
  return Object.keys(submitDataClone).reduce(
    (acc: { [id: string]: string }, key: string) => {
      acc[ProfilingQuestionReportKeys[key as ProfilingQuestionKeysEnum]] =
        submitData[key]
      return acc
    },
    {},
  )
}

const ProfilingContent = () => {
  const { t } = useTranslation()
  const { user } = useAuth()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const shortcutId = searchParams.get('shortcutId')
  const boardId = searchParams.get('boardId')
  const folderId = searchParams.get('folderId')
  const handleAnswerSubmit = useHandleAnswerSubmit()
  const { answeredTeamLead, complete } = useProfilingEvents()
  const { saveMarketingForm } = useProfilingAPI()
  const questions = getQuestionsData(t)
  const [answer, setAnswer] = useState<{ [id: string]: string }>()
  const { routeHasRedirect, redirectToOverwriteRoute } =
    useOverwriteRouteRedirect()

  const handleSubmit = async (data: { [id: string]: string }) => {
    complete()
    saveMarketingForm(convertSubmitDataToMarketingData(data))

    const rankAnswer = data[ProfilingQuestionKeysEnum.PROFILING_RANK]
    if (rankAnswer) {
      if (user) {
        updateUserProperties(user, {
          [ProfilingQuestionKeysEnum.PROFILING_RANK]: rankAnswer,
        })
      }
    }

    if (routeHasRedirect(PROFILING)) {
      redirectToOverwriteRoute()
      return
    }

    if (isMobile) {
      navigate(WELCOME)
    } else {
      if (shortcutId && boardId && folderId) {
        navigate(
          `${EDITOR}?shortcutId=${shortcutId}&boardId=${boardId}&folderId=${folderId}`,
        )
        return
      }
      navigate(EDITOR)
    }
  }

  if (answer) {
    const answerKey = Object.keys(answer)
    const answerWithDetails = answerKey.map((item) => {
      if (answer[item] === answerLeader) {
        answeredTeamLead()
      }
      return {
        question: item,
        answer: answer[item],
      }
    })[0]
    if (answerWithDetails) {
      handleAnswerSubmit(answerWithDetails.question, answerWithDetails.answer)
    }
  }

  return (
    <div className={styles.Root}>
      <div>
        <ProfilingForm
          title={t('user_profiling_header_tell_us_about_yourself')}
          continueLabel={t('action_continue')}
          questions={questions}
          initialQuestion={firstQuestion}
          onSubmit={handleSubmit}
          onAnswerSubmitted={setAnswer}
          doCleanAnswers={(questionKey: string) => {
            return questionKey === ProfilingQuestionKeysEnum.INDUSTRY
          }}
        />
      </div>
    </div>
  )
}

export default ProfilingContent
