import { httpsCallable, Functions } from 'firebase/functions'
import { APP_VERSION } from '@helpers/constants'

export const callFirebaseFunction = async <T>(
  functions: Functions,
  firebaseFunctionName: string,
  parameters?: object,
): Promise<T | undefined> => {
  const callableReturnMessage = httpsCallable(functions, firebaseFunctionName)

  return callableReturnMessage({ ...parameters, client: APP_VERSION }).then(
    (response) => response.data as T | undefined,
  )
}
