import { FC } from 'react'
import {
  isPageNodeCheckoutForm,
  NODE_LINK_TYPES,
  PageNode,
  TNodeCheckoutForm,
  TPageNode,
  TPageNodeCheckoutForm,
} from 'boards-web-ui'

import { NODE_LINK_TYPES_NAME } from 'app/models/buttons'
import useBoard from '@hooks/useBoard'
import { useSelectorFactory } from '@hooks/useSelectorFactory'
import usePageEvents from '@features/analytics/usePageEvents'
import { useProfileInformation } from '@features/profile/hooks/useProfileInformation'

import { getUploadingNodes } from '../../../selectors'
import { Node, NodeType } from '../../models'
import usePageNodeTransformer from './hooks/usePageNodeTransformer'
import useGeoLocation from '../../../hooks/useGeoLocation'
import settings from '../../settings'
import useNodeEvents, { ScreenNames } from '../analytics/useNodeEvents'

import styles from './PageNodes.module.css'

interface Props {
  nodes: Node[]
  screen?: ScreenNames
}

const PageNodes: FC<Props> = ({ nodes, screen }) => {
  const { geoData } = useGeoLocation()
  const { videoPlayEvent } = useNodeEvents()
  const { pageContentClickEvent } = usePageEvents()
  const { boardId, folderId } = useBoard()
  const transformPageNode = usePageNodeTransformer()
  const transformedNodes = (nodes || []).map(transformPageNode)
  const uploadingNodes = useSelectorFactory(getUploadingNodes)
  const { pageViewContentEvent } = usePageEvents()
  const {
    profile: { payPalAuthorization },
  } = useProfileInformation()

  return (
    <>
      {transformedNodes.map((nodeItem) => {
        const itemClasses = [styles.Item]

        if (uploadingNodes.includes(nodeItem.source.id)) {
          return null
        }

        // TODO remove next If block when will have upload progress
        //  bar indicator https://heybliss.atlassian.net/browse/BLISS-7549
        if (nodeItem.node.type === NodeType.IMAGE) {
          if (nodeItem.source.isProcessing) {
            return null
          }

          if (!nodeItem.source.height) {
            return null
          }
        }

        let node =
          nodeItem.source.type === NodeType.VIDEO_STREAMING && screen
            ? {
                ...nodeItem.node,
                onVideoPlay: () => videoPlayEvent(nodeItem.source, screen),
              }
            : nodeItem.node

        if (node.type === NodeType.PDF) {
          node = node.thumbnail
            ? nodeItem.node
            : {
                ...nodeItem.node,
                thumbnail: settings.nodePdfPlaceholder,
              }
        }

        if (isPageNodeCheckoutForm(nodeItem.node as TPageNode)) {
          // TODO Fix all types annotation when it will be fixed in boards-web-ui
          const formCheckoutNode = nodeItem.node as TPageNodeCheckoutForm
          formCheckoutNode.form.isReadyToAcceptPayments =
            payPalAuthorization?.status === 'authorized' &&
            (
              nodeItem?.source as unknown as TNodeCheckoutForm
            )?.form?.paymentProviders?.includes('paypal')
        }

        return (
          <div key={nodeItem.source.id} className={itemClasses.join(' ')}>
            {
              <PageNode
                className={
                  screen === ScreenNames.PAGE_PREVIEW
                    ? styles.PdfBannerTextPagePreview
                    : styles.PdfBannerTextPagePreviewTab
                }
                node={node as TPageNode}
                countryAndDialCodes={{
                  dialCode: geoData?.dialCode,
                  countryCode: geoData?.countryCode,
                }}
                onClick={() => {
                  if (node.type === NodeType.LINK) {
                    const type =
                      nodeItem.source.link_type || NODE_LINK_TYPES.REGULAR
                    pageContentClickEvent(boardId, {
                      content_type: nodeItem.source.type,
                      label: node.title || '',
                      content: node.src || '',
                      button_type: NODE_LINK_TYPES_NAME[type] || type || '',
                    })
                  }
                  if (node.type === NodeType.PDF) {
                    pageViewContentEvent(nodeItem.source, boardId, folderId)
                  }
                }}
                target="_blank"
              />
            }
          </div>
        )
      })}
    </>
  )
}

export default PageNodes
