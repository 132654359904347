import { EditForms } from '@features/forms'
import { useProfileInformation } from '@features/profile/hooks/useProfileInformation'

import { ICreateOrUpdateNode } from '../models/IUseNodeCreatorAPI'

interface Props {
  onSave: ICreateOrUpdateNode
  onCancel: () => void
}
const AddForm = ({ onSave, onCancel }: Props) => {
  const { isGoogleAccountConnected } = useProfileInformation()

  // const node = {
  //   title: '',
  //   type: NodeType.FORM,
  //   form: {
  //     action: t('forms_submit_button_label_default'),
  //     response: t('forms_thanks_message_default'),
  //     responseEmail: user?.email || '',
  //     fields:
  //   },
  // } as Node

  return (
    <EditForms
      isNew
      isGoogleAccountConnected={isGoogleAccountConnected}
      onSave={onSave}
      onCancel={onCancel}
    />
  )
}

export default AddForm
