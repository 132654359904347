import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { useToast } from '@hooks/useToast'
import { useSharedFolder } from '@hooks/useSharedFolder'

import { Board, Folder } from '../../../models'
import { folderActions } from '../../../../actions'
import { getFoldersWithShortcuts } from '../../../../_firebase'
import {
  transformBoardFromShortcut,
  transformFolderFromShortcut,
} from '../../../../_firebase/shortcutTransformers'
import { LoadFoldersWithShortcutsResponse } from '../../../../_firebase/models/shortcutDataResponse'

interface IDataState extends Omit<LoadFoldersWithShortcutsResponse, 'board'> {
  board?: Board
  rootFolderProps?: Pick<Folder, 'icon' | 'title'>
}

const useShortcutLoader = () => {
  const navigate = useNavigate()
  const toast = useToast()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)

  const [data, setData] = useState<IDataState>()

  const [folder, setFolder] = useState<Folder>()

  const dispatch = useDispatch()

  const {
    sharedFolderState: { shortcutId, folders },
  } = useSharedFolder()

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true)
        const result = await getFoldersWithShortcuts(shortcutId)

        if (result) {
          const folderResult = transformFolderFromShortcut(
            result.folders[0].folder,
            shortcutId,
          )

          if (result.board) {
            const { icon, title } = result.board.folder.properties

            setData({
              ...result,
              board: transformBoardFromShortcut(result.board),
              rootFolderProps: { icon, title },
            })
          } else {
            setData({
              ...result,
              board: undefined,
              rootFolderProps: undefined,
            })
          }

          setFolder(folderResult)

          dispatch(folderActions.setSharedFolder(folderResult))
        }
      } catch {
        navigate(-1)
        dispatch(folderActions.deleteFolderShortcut())
        toast(t('optimistic_ui_failed'))
      } finally {
        setLoading(false)
      }
    }

    if (shortcutId) {
      const folderInBoards = folders.find(
        (item) => item.shortcut === shortcutId,
      )

      if (folderInBoards) {
        setFolder(folderInBoards)
        return
      }

      getData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shortcutId])

  return { loading, data, folder }
}

export default useShortcutLoader
