import React, { FC, PropsWithChildren } from 'react'
import { ButtonNew, Icon, PlusIcon } from 'boards-web-ui'

type CheckoutAddButtonProps = PropsWithChildren<{
  onClick: () => void
}>
const AddButton: FC<CheckoutAddButtonProps> = ({
  children,
  onClick,
  ...rest
}) => {
  return (
    <ButtonNew
      fullWidth
      color="secondary"
      size="large"
      startIcon={
        <Icon size="10px" color="#007AFF">
          <PlusIcon />
        </Icon>
      }
      onClick={onClick}
      {...rest}
    >
      {children}
    </ButtonNew>
  )
}

export default React.memo(AddButton)
