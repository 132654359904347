import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import useBoardActions from '@hooks/useBoardActions'
import useIsApiAvailableOrNotify from '@hooks/useIsApiAvailableOrNotify'
import { useLogEvents } from '@features/analytics/useLogEvents'

import useBulk from '@hooks/bulk/useBulk'
import { Folder, NodeType } from '../../../models'

import useNode from '../hooks/useNode'
import useDragAndDrop from '../hooks/useDragAndDrop'
import { BoardMode } from '../models/nodes'
import { NodeSelectionVariants } from '../models/selection'
import useBulkSelection from '../hooks/useBulkSelection'

import BoardPanel from './BoardPanel'
import NodeItem from './folderNodes/NodeItem'
import FolderListItem from './FolderListItem'
import NodeSelection from './NodeSelection'

import styles from './FolderManagement.module.css'

const nodeVariantsByMode = {
  [BoardMode.read]: NodeSelectionVariants.gray,
  [BoardMode.write]: NodeSelectionVariants.gray,
  [BoardMode.select]: NodeSelectionVariants.blue,
  [BoardMode.action]: NodeSelectionVariants.default,
  [BoardMode.offline]: NodeSelectionVariants.default,
} as const

type Props = {
  mode: BoardMode
  boardId: string
  folder: Folder
  isSharedFolderMode?: boolean
  canCopy?: boolean
  canBeShared?: boolean
  isActive?: boolean
  isPageBoard?: boolean
  canCopyContentOfSharedFolder?: boolean
}

const FolderManagement: FC<Props> = ({
  mode,
  boardId,
  folder,
  isSharedFolderMode,
  canCopy,
  canBeShared,
  isActive,
  isPageBoard,
  canCopyContentOfSharedFolder,
}) => {
  const { t } = useTranslation()
  const isApiAvailableOrNotify = useIsApiAvailableOrNotify()
  const { onClick, onSelect, moveNode } = useNode({
    boardId,
    folderId: folder.id,
    mode,
    folder,
    isSharedFolderMode,
    canCopyContentOfSharedFolder,
  })
  const { canPreviewNode } = useBoardActions()
  const { pagesBoardView } = useLogEvents()
  const { bulk } = useBulk()

  const { layout, setItems, onDrag, onDragEnd } = useDragAndDrop(
    mode === BoardMode.write,
    moveNode,
    isApiAvailableOrNotify,
  )
  const { isSelected } = useBulkSelection()

  useEffect(() => {
    if (isPageBoard) {
      pagesBoardView()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPageBoard])

  useEffect(() => {
    setItems(folder.content)
  }, [folder.content, setItems])

  return (
    <div className={styles.Root}>
      <BoardPanel
        height={layout.height}
        isSharedFolderMode={isSharedFolderMode}
        canBeShared={canBeShared}
      >
        {layout.tiles.map(({ node, rect }, index) => {
          const key = `${node.id}-edit`
          const isFolderNode = node.type === NodeType.FOLDER

          const showTooltip =
            !canPreviewNode &&
            mode !== BoardMode.select &&
            !isFolderNode &&
            !isPageBoard &&
            !isSharedFolderMode

          const unSelectable = Boolean(
            isFolderNode && bulk.nodes.length && isSharedFolderMode,
          )

          const isNotSameFolderIdForBulkAction = Boolean(
            bulk.folderId && bulk.folderId !== folder.id,
          )

          return (
            <FolderListItem
              key={key}
              rect={rect}
              index={index}
              onDrag={onDrag}
              onDragEnd={onDragEnd}
            >
              <NodeSelection
                isSelected={isSelected(node.id)}
                tooltipMessage={
                  showTooltip ? t('inactive_board_content_toast') : undefined
                }
                onClick={
                  showTooltip || (mode === BoardMode.select && unSelectable)
                    ? undefined
                    : () =>
                        onClick({
                          nodeId: node.id,
                          type: node.type,
                          unSelectable,
                          isNotSameFolderIdForBulkAction,
                        })
                }
                onSelect={
                  unSelectable ||
                  isNotSameFolderIdForBulkAction ||
                  (isSharedFolderMode &&
                    (isFolderNode || !canCopyContentOfSharedFolder))
                    ? undefined
                    : () => onSelect(node.id, node.type)
                }
                variant={nodeVariantsByMode[mode]}
                opacity={
                  unSelectable && mode === BoardMode.select && !isPageBoard
                    ? '0.5'
                    : undefined
                }
                withDarkOverlay={[NodeType.VIDEO, NodeType.IMAGE].includes(
                  node.type,
                )}
                withGrayGradient={
                  !isActive &&
                  !isPageBoard &&
                  mode !== BoardMode.select &&
                  mode !== BoardMode.offline &&
                  node.type !== NodeType.FOLDER
                }
                withOverlay={
                  canCopy || canPreviewNode || node.type === NodeType.FOLDER
                }
              >
                <NodeItem node={node} isSelected={isSelected(node.id)} />
              </NodeSelection>
            </FolderListItem>
          )
        })}
      </BoardPanel>
    </div>
  )
}

export default FolderManagement
