import { NodeType } from '../../models'

const nodeWidth = 200
const rowSpacing = 16
const rowHeight = (nodeWidth - rowSpacing * 5) / 6

export interface IBoardConfig {
  pageColumnWidth: number
  columnWidth: number
  columnSpacing: number
  rowHeight: number
  rowSpacing: number
  nodeRows: {
    [key in NodeType]: number
  }
  titleBreakLineThreshold: number
}

const BoardConfig = (): IBoardConfig => {
  return {
    pageColumnWidth: 350,
    columnWidth: nodeWidth,
    columnSpacing: 16,
    rowHeight,
    rowSpacing,
    nodeRows: {
      [NodeType.IMAGE]: 6,
      [NodeType.PDF]: 6,
      [NodeType.VIDEO]: 4,
      [NodeType.VIDEO_STREAMING]: 4,
      [NodeType.TEXT]: 3,
      [NodeType.HEADLINE]: 3,
      [NodeType.PARAGRAPH]: 3,
      [NodeType.LINK]: 3,
      [NodeType.BUTTON]: 3,
      [NodeType.FOLDER]: 3,
      [NodeType.PAGE]: 6,
      [NodeType.AUDIO]: 3,
      [NodeType.FORM]: 3,
      [NodeType.CHECKOUT]: 3,
    },
    /*
  This threshold defines when text has only one line,
  because the title may have 2 lines
  */
    titleBreakLineThreshold: 20,
  }
}

export default BoardConfig()
