import {
  RankOptions,
  TeamSizeOptions,
  TimeOptions,
} from '@features/profiling/util/questionOptions'
import {
  ProfilingQuestionEventNames,
  ProfilingQuestionKeysEnum,
} from '@features/profiling/models'
import useProfilingEvents from '@features/analytics/useProfilingEvents'

export const TeamSizeOptionByRank = {
  [RankOptions.millionaire]: TeamSizeOptions.o1_10,
  [RankOptions.president]: TeamSizeOptions.o11_50,
  [RankOptions.president15]: TeamSizeOptions.o51_100,
  [RankOptions.president20]: TeamSizeOptions.o101_200,
  [RankOptions.president30]: TeamSizeOptions.o201_300,

  [RankOptions.president40]: TeamSizeOptions.o301_500,
  [RankOptions.president50]: TeamSizeOptions.o501,
  [RankOptions.presidentExecutive]: TeamSizeOptions.o501,
  [RankOptions.chairman]: TeamSizeOptions.o501,
  [RankOptions.founder]: TeamSizeOptions.o501,
}

export const TimeActiveOptionByRank = {
  [RankOptions.millionaire]: TimeOptions.o0_3,
  [RankOptions.president]: TimeOptions.o4_12,
  [RankOptions.president15]: TimeOptions.o4_12,
  [RankOptions.president20]: TimeOptions.o12_36,
  [RankOptions.president30]: TimeOptions.o12_36,

  [RankOptions.president40]: TimeOptions.o48,
  [RankOptions.president50]: TimeOptions.o48,
  [RankOptions.presidentExecutive]: TimeOptions.o48,
  [RankOptions.chairman]: TimeOptions.o48,
  [RankOptions.founder]: TimeOptions.o48,
}

const useHandleAnswerSubmit = () => {
  const { answerQuestion } = useProfilingEvents()
  return (question: string, answer: string) => {
    if (question === ProfilingQuestionKeysEnum.PROFILING_RANK) {
      answerQuestion(
        ProfilingQuestionEventNames[
          ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_SIZE
        ],
        TeamSizeOptionByRank[answer],
      )
      answerQuestion(
        ProfilingQuestionEventNames[ProfilingQuestionKeysEnum.TIME_IN_BUSINESS],
        TimeActiveOptionByRank[answer],
      )
    } else {
      answerQuestion(
        ProfilingQuestionEventNames[question as ProfilingQuestionKeysEnum],
        answer,
      )
    }
  }
}

export default useHandleAnswerSubmit
