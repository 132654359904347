import { TFunction } from 'i18next'
import {
  answerLeader,
  ProfilingQuestionKeysEnum,
} from '@features/profiling/models'

export interface OptionProp {
  value: string
  label: string
  next?: ProfilingQuestionKeysEnum
}

export const TeamSizeOptions = {
  o1_10: '1-10',
  o11_50: '11-50',
  o51_100: '51-100',
  o101_200: '101-200',
  o201_300: '201-300',
  o301_500: '301-500',
  o501: '501+',
}

export const TimeOptions = {
  o0_3: '0m-3m',
  o4_12: '4m-12m',
  o12_36: '1y-3y',
  o48: '4y+',
}

export const RankOptions = {
  millionaire: 'Millionaire',
  president: "President's Team",
  president15: '15k President’s Team',
  president20: '20k President’s Team',
  president30: '30k President’s Team',
  president40: '40k President’s Team',
  president50: '50k President’s Team',
  presidentExecutive: 'Executive President’s Team',
  chairman: "Chairman's Club",
  founder: 'Founder’s Circle',
}

export const getIndustryOptions = (translations: TFunction): OptionProp[] => [
  {
    label: translations('profiling_industry_network'),
    value: '🚀 Network Marketing',
    next: ProfilingQuestionKeysEnum.BRAND_SELECT,
  },
  {
    label: translations('profiling_industry_direct_sales'),
    value: '🎯 Direct Sales',
    next: ProfilingQuestionKeysEnum.BRAND_SELECT,
  },
  {
    label: translations('profiling_industry_realestate'),
    value: '🏢 Real Estate',
    next: ProfilingQuestionKeysEnum.BRAND_TEXT,
  },
  {
    label: translations('profiling_industry_automotive'),
    value: '🚗 Automotive',
    next: ProfilingQuestionKeysEnum.BRAND_TEXT,
  },
  {
    label: '🤳 Influencer Marketing',
    value: translations('profiling_industry_influencer'),
    next: ProfilingQuestionKeysEnum.BRAND_TEXT,
  },
  {
    label: translations('profiling_industry_ecommerce'),
    value: '🛍️ E-commerce',
    next: ProfilingQuestionKeysEnum.BRAND_TEXT,
  },
  {
    label: translations('profiling_industry_multi_marketing'),
    value: '🏖️ Multi-level Marketing',
    next: ProfilingQuestionKeysEnum.BRAND_SELECT,
  },
  {
    label: translations('action_other'),
    value: 'Other',
    next: ProfilingQuestionKeysEnum.BRAND_TEXT,
  },
]

export const getBrandOptions = (translations: TFunction): OptionProp[] => [
  {
    label: 'Herbalife',
    value: 'Herbalife',
    next: ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_HERBALIFE,
  },
  {
    label: 'It Works',
    value: 'It Works',
    next: ProfilingQuestionKeysEnum.PROFILING_DOWNLINE,
  },
  {
    label: 'Younique',
    value: 'Younique',
    next: ProfilingQuestionKeysEnum.PROFILING_DOWNLINE,
  },
  {
    label: 'Tranont',
    value: 'Tranont',
    next: ProfilingQuestionKeysEnum.PROFILING_DOWNLINE,
  },
  {
    label: 'Pampered Chef',
    value: 'Pampered Chef',
    next: ProfilingQuestionKeysEnum.PROFILING_DOWNLINE,
  },
  {
    label: 'Xyngular',
    value: 'Xyngular',
    next: ProfilingQuestionKeysEnum.PROFILING_DOWNLINE,
  },
  {
    label: 'Modere',
    value: 'Modere',
    next: ProfilingQuestionKeysEnum.PROFILING_DOWNLINE,
  },
  {
    label: 'doTerra',
    value: 'doTerra',
    next: ProfilingQuestionKeysEnum.PROFILING_DOWNLINE,
  },
  {
    label: 'Nu Skin',
    value: 'Nu Skin',
    next: ProfilingQuestionKeysEnum.PROFILING_DOWNLINE,
  },
  {
    label: 'Max International',
    value: 'Max International',
    next: ProfilingQuestionKeysEnum.PROFILING_DOWNLINE,
  },
  {
    label: 'Mary Kay',
    value: 'Mary Kay',
    next: ProfilingQuestionKeysEnum.PROFILING_DOWNLINE,
  },
  {
    label: 'SeneGence',
    value: 'SeneGence',
    next: ProfilingQuestionKeysEnum.PROFILING_DOWNLINE,
  },
  {
    label: 'Pruvit',
    value: 'Pruvit',
    next: ProfilingQuestionKeysEnum.PROFILING_DOWNLINE,
  },
  {
    label: 'Arbonne',
    value: 'Arbonne',
    next: ProfilingQuestionKeysEnum.PROFILING_DOWNLINE,
  },
  {
    label: 'Juice Plus',
    value: 'Juice Plus',
    next: ProfilingQuestionKeysEnum.PROFILING_DOWNLINE,
  },
  {
    label: 'Oriflame',
    value: 'Oriflame',
    next: ProfilingQuestionKeysEnum.PROFILING_DOWNLINE,
  },
  {
    label: 'Rain International',
    value: 'Rain International',
    next: ProfilingQuestionKeysEnum.PROFILING_DOWNLINE,
  },
  {
    label: 'Farmasi',
    value: 'Farmasi',
    next: ProfilingQuestionKeysEnum.PROFILING_DOWNLINE,
  },
  {
    label: 'Scentsy',
    value: 'Scentsy',
    next: ProfilingQuestionKeysEnum.PROFILING_DOWNLINE,
  },
  {
    label: 'Young Living',
    value: 'Young Living',
    next: ProfilingQuestionKeysEnum.PROFILING_DOWNLINE,
  },
  {
    label: 'Monat',
    value: 'Monat',
    next: ProfilingQuestionKeysEnum.PROFILING_DOWNLINE,
  },
  {
    label: 'Melaleuca',
    value: 'Melaleuca',
    next: ProfilingQuestionKeysEnum.PROFILING_DOWNLINE,
  },
  {
    label: 'Epicure',
    value: 'Epicure',
    next: ProfilingQuestionKeysEnum.PROFILING_DOWNLINE,
  },
  {
    label: 'Isagenix',
    value: 'Isagenix',
    next: ProfilingQuestionKeysEnum.PROFILING_DOWNLINE,
  },
  {
    label: translations('action_other'),
    value: 'Other',
    next: ProfilingQuestionKeysEnum.BRAND_SELECT_TEXT,
  },
]
export const getRankOptions = (): OptionProp[] => [
  {
    label: 'Millionaire',
    value: RankOptions.millionaire,
  },
  {
    label: "President's Team",
    value: RankOptions.president,
  },
  {
    label: '15k President’s Team',
    value: RankOptions.president15,
  },
  {
    label: '20k President’s Team',
    value: RankOptions.president20,
  },
  {
    label: '30k President’s Team',
    value: RankOptions.president30,
  },
  {
    label: '40k President’s Team',
    value: RankOptions.president40,
  },
  {
    label: '50k President’s Team',
    value: RankOptions.president50,
  },
  {
    label: 'Executive President’s Team',
    value: RankOptions.presidentExecutive,
  },
  {
    label: "Chairman's Club",
    value: RankOptions.chairman,
  },
  {
    label: 'Founder’s Circle',
    value: RankOptions.founder,
  },
]

export const getTimeInBusinessOptions = (
  translations: TFunction,
): OptionProp[] => [
  {
    label: translations('profiling_time_in_bussines_3_months'),
    value: TimeOptions.o0_3,
  },
  {
    label: translations('profiling_time_in_bussines_12_months'),
    value: TimeOptions.o4_12,
  },
  {
    label: translations('profiling_time_in_bussines_3_years'),
    value: TimeOptions.o12_36,
  },
  {
    label: translations('profiling_time_in_bussines_4_years'),
    value: TimeOptions.o48,
  },
]

export const getProfilingDownline = (translations: TFunction): OptionProp[] => [
  {
    label: translations('profiling_downline_yes'),
    value: answerLeader,
    next: ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_SIZE,
  },
  { label: translations('profiling_downline_no'), value: 'member' },
]

export const getProfilingDownlineHerbalife = (
  translations: TFunction,
): OptionProp[] => [
  {
    label: translations('profiling_downline_yes'),
    value: answerLeader,
    next: ProfilingQuestionKeysEnum.PROFILING_RANK,
  },
  { label: translations('profiling_downline_no'), value: 'member' },
]

export const getProfilingDownlineSize = (): OptionProp[] => [
  {
    label: '1-10',
    value: TeamSizeOptions.o1_10,
    next: ProfilingQuestionKeysEnum.TIME_IN_BUSINESS,
  },
  {
    label: '11-50',
    value: TeamSizeOptions.o11_50,
    next: ProfilingQuestionKeysEnum.TIME_IN_BUSINESS,
  },
  {
    label: '51-100',
    value: TeamSizeOptions.o51_100,
    next: ProfilingQuestionKeysEnum.TIME_IN_BUSINESS,
  },
  {
    label: '101-200',
    value: TeamSizeOptions.o101_200,
    next: ProfilingQuestionKeysEnum.TIME_IN_BUSINESS,
  },
  {
    label: '201-300',
    value: TeamSizeOptions.o201_300,
    next: ProfilingQuestionKeysEnum.TIME_IN_BUSINESS,
  },
  {
    label: '301-500',
    value: TeamSizeOptions.o301_500,
    next: ProfilingQuestionKeysEnum.TIME_IN_BUSINESS,
  },
  {
    label: '501+',
    value: TeamSizeOptions.o501,
    next: ProfilingQuestionKeysEnum.TIME_IN_BUSINESS,
  },
]

export const getTypeOptions = (translations: TFunction): OptionProp[] => [
  {
    label: translations('profiling_who_are_you_option_team_leader'),
    value: answerLeader,
    next: ProfilingQuestionKeysEnum.TEAM_SIZE,
  },
  {
    label: translations('profiling_who_are_you_option_part_team'),
    value: 'member',
  },
  {
    label: translations('profiling_who_are_you_option_individual'),
    value: 'individual',
  },
]

export const getTeamSizeOptions = (): OptionProp[] => [
  { label: '1-10', value: TeamSizeOptions.o1_10 },
  { label: '11-50', value: TeamSizeOptions.o11_50 },
  { label: '51-100', value: TeamSizeOptions.o51_100 },
  { label: '101-200', value: TeamSizeOptions.o101_200 },
  { label: '201-300', value: TeamSizeOptions.o201_300 },
  { label: '301-500', value: TeamSizeOptions.o301_500 },
  { label: '501+', value: TeamSizeOptions.o501 },
]
