import React, { PropsWithChildren } from 'react'
import styles from './SimpleButton.module.css'

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> &
  PropsWithChildren<{
    className?: string
    primary?: boolean
    secondary?: boolean
    gray?: boolean
    darkGray?: boolean
    lightBlue?: boolean
    lightBlue200?: boolean
    lightBlue300?: boolean
    lightGray?: boolean
    customBorderRadius?: number
    regularFontWeight?: boolean
    small?: boolean
    medium?: boolean
    large?: boolean
    joinBoard?: boolean
    mediumBtnIcon?: boolean
    disabled?: boolean
    darkDisabled?: boolean
    lightDisabled?: boolean
    disabledOnHover?: boolean
    fitToContent?: boolean
    centered?: boolean
    isActive?: boolean
    fullWidth?: boolean
    type?: string
    customSize?: number
  }>

const Button: React.FC<Props> = ({
  children,
  className,
  primary,
  secondary,
  gray,
  darkGray,
  lightBlue,
  lightBlue200,
  lightBlue300,
  lightGray,
  customBorderRadius,
  regularFontWeight,
  small,
  medium,
  large,
  joinBoard,
  mediumBtnIcon,
  disabled,
  darkDisabled,
  lightDisabled,
  disabledOnHover,
  fitToContent,
  centered,
  fullWidth,
  isActive,
  onClick,
  type = 'button',
  customSize,
  ...rest
}) => {
  const style = {
    height: customSize ? `${customSize}px` : '',
    width: customSize ? `${customSize}px` : '',
    padding: customSize ? `${0}px` : '',
    borderRadius: customBorderRadius ? `${customBorderRadius}px` : '',
  }
  return (
    <button
      className={[
        styles.Button,
        secondary ? styles.Secondary : '',
        primary ? styles.Primary : '',
        gray ? styles.Gray : '',
        darkGray ? styles.DarkGray : '',
        lightBlue ? styles.LightBlue : '',
        lightBlue200 ? styles.LightBlue200 : '',
        lightBlue300 ? styles.LightBlue300 : '',
        lightGray ? styles.LightGray : '',
        regularFontWeight ? styles.RegularFontWeight : '',
        small ? styles.Small : '',
        medium ? styles.Medium : '',
        large ? styles.Large : '',
        joinBoard ? styles.JoinBoard : '',
        mediumBtnIcon ? styles.MediumBtnIcon : '',
        darkDisabled ? styles.DarkDisabled : '',
        lightDisabled ? styles.LightDisabled : '',
        disabledOnHover ? styles.DisabledOnHover : '',
        fitToContent ? styles.FitToContent : '',
        centered ? styles.Centered : '',
        isActive ? styles.IsActive : '',
        fullWidth ? styles.FullWidth : '',
        className,
      ].join(' ')}
      type={type}
      disabled={disabled}
      onClick={!disabled && onClick ? onClick : undefined}
      style={style}
      {...rest}
    >
      {children}
    </button>
  )
}

/** @deprecated Use "ButtonNew" instead from "boards-web-ui" */
export const SimpleButton = React.memo(Button)
