import {
  AdditionalField,
  AdditionalFields,
  AdditionalFieldType,
  EmailEmptyIcon,
  FieldTypes,
  MultiselectIcon,
  OrderedListIcon,
  PersonEmptyIcon,
  PhoneEmptyIcon,
} from 'boards-web-ui'
import { newKey } from '@helpers/NodeIdGenerator'

import type { AdditionalFieldTypeWithIcon } from '../models'

export const ADDITIONAL_FIELDS_TYPE: {
  [key: string]: AdditionalFieldTypeWithIcon
} = Object.freeze({
  custom: {
    type: FieldTypes.TEXT,
    title: 'forms_custom_field_label',
    icon: <OrderedListIcon />,
    defaultValue: 'forms_label_message',
  },
  string: {
    type: FieldTypes.STRING,
    title: 'forms_name_label_default',
    icon: <PersonEmptyIcon />,
    defaultValue: 'forms_name_label_default',
  },
  address: {
    type: FieldTypes.ADDRESS,
    title: 'form_field_shipping_address',
    icon: '',
    defaultValue: 'form_field_shipping_address',
  },
  email: {
    type: FieldTypes.EMAIL,
    title: 'forms_email_address_label_default',
    icon: <EmailEmptyIcon />,
    defaultValue: 'forms_email_address_label_default',
  },
  phone: {
    type: FieldTypes.PHONE,
    title: 'forms_phone_number_label_default',
    icon: <PhoneEmptyIcon />,
    defaultValue: 'forms_phone_number_label_default',
  },
  text: {
    type: FieldTypes.TEXT,
    title: 'forms_custom_field_label',
    icon: <OrderedListIcon />,
    defaultValue: 'forms_label_message',
  },
  choice: {
    type: FieldTypes.CHOICE,
    title: 'label_multiple_choice',
    icon: <MultiselectIcon />,
    defaultValue: 'label_multiple_choice',
  },
})

export const DEFAULT_SELECTED_ADDITIONAL_FIELDS: Array<AdditionalFieldTypeWithIcon> =
  [
    ADDITIONAL_FIELDS_TYPE.string,
    ADDITIONAL_FIELDS_TYPE.phone,
    ADDITIONAL_FIELDS_TYPE.email,
    ADDITIONAL_FIELDS_TYPE.choice,
    ADDITIONAL_FIELDS_TYPE.custom,
  ]

export const DEFAULT_ADDITIONAL_FIELD: AdditionalField = {
  id: newKey(),
  type: ADDITIONAL_FIELDS_TYPE.custom.type,
  value: '',
  custom: false,
  required: true,
}

export const DEFAULT_ADDITIONAL_FIELDS: AdditionalFields = [
  {
    id: 'name',
    type: ADDITIONAL_FIELDS_TYPE.string.type,
    value: '',
    custom: false,
    required: true,
  },
  {
    id: 'shippingAddress',
    type: ADDITIONAL_FIELDS_TYPE.address.type,
    value: '',
    custom: true,
    required: true,
  },
  {
    id: ADDITIONAL_FIELDS_TYPE.email.type,
    type: ADDITIONAL_FIELDS_TYPE.email.type,
    value: '',
    custom: false,
    required: true,
  },
  {
    id: ADDITIONAL_FIELDS_TYPE.phone.type,
    type: ADDITIONAL_FIELDS_TYPE.phone.type,
    value: '',
    custom: false,
    required: true,
  },
]

export const getDefaultFieldValuesByFieldType = (
  type: AdditionalFieldType,
  defaultValue: string,
) => {
  return {
    required: type !== FieldTypes.TEXT && type !== FieldTypes.CHOICE,
    value:
      type === FieldTypes.TEXT || type === FieldTypes.CHOICE
        ? ''
        : defaultValue,
    multiple: type === FieldTypes.CHOICE && true,
    choices: type === FieldTypes.CHOICE ? ['', ''] : undefined,
  }
}
