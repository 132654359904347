import React from 'react'

import { useUpdatesTools } from './hooks/useUpdatesTools'
import { useUpdatesEvents } from '../analytics/useUpdatesEvents'
import { useUpdatesTranslations } from './hooks/useUpdatesTranslations'
import { ReactComponent as AddUpdatesIcon } from '../../../icons/create-update.svg'
import { SimpleButton } from '../../../ui/components/SimpleButton'
import MessagesTooltip from '../../../ui/components/MessagesTooltip'
import { useProfileInformation } from '../profile/hooks/useProfileInformation'
import useUserFeatures, { Features } from '../../../hooks/useUserFeatures'

import styles from './UpdatesTools.module.css'

export const UpdatesTools = () => {
  const { postUpdate } = useUpdatesTranslations()
  const { isOpen, isUnAvailable, disabled, toggle } = useUpdatesTools()
  const { openUpdatesClick } = useUpdatesEvents()
  const { hideMessagesTooltip, profile } = useProfileInformation()
  const { tryFree } = useUserFeatures(Features.messages)

  if (isUnAvailable) {
    return null
  }

  const onToggle = () => {
    if (!isOpen) {
      openUpdatesClick()
    }

    toggle()
  }

  return (
    <div className={styles.ParentContainer}>
      {tryFree && !profile.uiState.freemiumMessagesHintShown && (
        <MessagesTooltip onClose={hideMessagesTooltip} />
      )}
      <SimpleButton
        secondary
        fitToContent
        className={[
          styles.FlexButton,
          isOpen ? styles.ButtonIsActive : '',
        ].join(' ')}
        style={{ paddingRight: '12px', paddingLeft: '12px' }}
        onClick={onToggle}
        disabled={disabled}
      >
        <AddUpdatesIcon className={styles.Icon} />
        &nbsp;{postUpdate()}
      </SimpleButton>
    </div>
  )
}
