import {
  CheckoutEventNames,
  CheckoutEventsTimePeriodInterval,
  PaddleEventData,
} from '@paddle/paddle-js'
import usePaywallEvents from '@features/analytics/usePaywallEvents'

const PeriodIntervalTo = {
  [CheckoutEventsTimePeriodInterval.DAY]: 'daily',
  [CheckoutEventsTimePeriodInterval.WEEK]: 'weekly',
  [CheckoutEventsTimePeriodInterval.MONTH]: 'monthly',
  [CheckoutEventsTimePeriodInterval.YEAR]: 'yearly',
}
const useHandleEventCallback = (
  campaignManagerId: string | null,
  closeTabAfterSubscription: boolean,
) => {
  const { paywallSubscriptionStartEvent } = usePaywallEvents()

  const handleEventCallback = (event: PaddleEventData) => {
    if (event.name === CheckoutEventNames.CHECKOUT_COMPLETED) {
      const item = event.data?.items[0]

      if (item) {
        const subscriptionCycle = item.billing_cycle
          ? PeriodIntervalTo[item.billing_cycle?.interval]
          : ''
        const productId = item.product.id
        const plan = item.product.name.toLowerCase()

        paywallSubscriptionStartEvent(
          plan,
          subscriptionCycle,
          productId,
          campaignManagerId || undefined,
        )
      }

      setTimeout(() => {
        if (closeTabAfterSubscription) {
          window.close()
          return
        }

        window.location.href = '/'
      }, 2000)
    }
  }

  return handleEventCallback
}

export default useHandleEventCallback
