export const enum AfterSubmitBehaviourEnum {
  REDIRECT = 'redirect',
  MESSAGE = 'message',
}

export const FORM_FIELDS_ALLOW_TO_CHANGE = [
  'action',
  'fields',
  'spreadsheetURL',
  'response',
  'email',
  'title',
]
