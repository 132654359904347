import React, { FC } from 'react'
import {
  Banner,
  Icon,
  LogoIcon,
  ButtonLink,
  RightArrowIcon,
  KingClearIcon,
} from 'boards-web-ui'

import styles from './PreviewPageBanner.module.css'

type Props = {
  unbrandedPages?: boolean
  onDelete?: () => void
}

const PreviewPageBanner: FC<Props> = ({ unbrandedPages, onDelete }) => {
  return (
    <Banner
      absolute
      position="bottom"
      className={[styles.Banner, unbrandedPages ? styles.UnBranded : ''].join(
        ' ',
      )}
    >
      <p className={styles.MessageWrap}>
        <Icon size="64px" className={styles.Logo}>
          <LogoIcon className={styles.LogoIcon} />
        </Icon>

        <span className={styles.Message}>
          Easily create a page like this 🚀
        </span>
      </p>

      <ButtonLink primary medium href="/" target="_blank">
        <span>Start now</span>
        <Icon size="18px" className={styles.Icon}>
          <RightArrowIcon />
        </Icon>
      </ButtonLink>

      {!unbrandedPages ? (
        <button type="button" className={styles.Hover} onClick={onDelete}>
          <Icon size="21px" color="#FFF" className={styles.KingIcon}>
            <KingClearIcon />
          </Icon>
          Upgrade to remove this banner
        </button>
      ) : null}
    </Banner>
  )
}

export default React.memo(PreviewPageBanner)
