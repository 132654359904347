import { Trans, useTranslation } from 'react-i18next'
import { useDialog } from 'boards-web-ui'

import Modal from '../../../../components/Modal'
import { ReactComponent as MembersIcon } from '../icons/TwoPerson.svg'
import AddMembers from './AddMembers'
import InviteLink from './InviteLink'
import useConfirmDialog from '../../../../hooks/useConfirmDialog'
import useShare from '../hooks/useShare'
import useBoard from '../../../../hooks/useBoard'

import styles from './ShareManagement.module.css'

interface Props {
  openMembersScreen: () => void
}
const ShareManagement = ({ openMembersScreen }: Props) => {
  const { close } = useDialog()
  const { t } = useTranslation()
  const { emails, addEmail, removeEmailByIndex } = useShare()
  const { canShare, board } = useBoard()
  const confirmDialog = useConfirmDialog()

  const handleOnModalClosed = () => {
    if (emails.length === 0) {
      close()
      return
    }

    confirmDialog({
      title: t('discard_changes_title'),
      message: t('discard_changes_body'),
      confirmText: t('action_discard'),
      cancelText: t('action_cancel'),
      onConfirm: close,
    })
  }

  const options = canShare
    ? [
        {
          text: t('action_members'),
          onClick: openMembersScreen,
          icon: <MembersIcon />,
        },
      ]
    : []

  return (
    <Modal
      variant="small"
      Header={undefined}
      options={options}
      onClose={handleOnModalClosed}
      onClickOutside={close}
      open
    >
      <h2 className={styles.Title}>
        <Trans
          i18nKey="share_screen_add_members_to"
          values={{ board: board?.title || '' }}
        />
      </h2>
      {canShare && (
        <AddMembers
          emails={emails}
          addEmail={addEmail}
          removeEmailByIndex={removeEmailByIndex}
        />
      )}
      <InviteLink />
    </Modal>
  )
}

export default ShareManagement
