import React, { FC } from 'react'
import { Trans } from 'react-i18next'
import { ButtonNew, KingClearIcon } from 'boards-web-ui'
import styles from './SharedBoardModalFooterSelection.module.css'

interface Props {
  selectedNodesCount: number
  onCancel: () => void
  onConfirm: () => void
  isRestricted: boolean
}

export const SharedBoardModalFooterSelection: FC<Props> = ({
  selectedNodesCount,
  onCancel,
  onConfirm,
  isRestricted,
}) => (
  <div className={styles.Root}>
    <div className={styles.SelectedLabel}>
      <Trans
        i18nKey="placeholder_bulk_action_selected"
        values={{ count: selectedNodesCount }}
      />
    </div>

    <div className={styles.ButtonsContainer}>
      <ButtonNew color={'secondary'} size={'medium'} onClick={onCancel}>
        <Trans i18nKey={'action_cancel'} />
      </ButtonNew>

      <ButtonNew
        color={'primary'}
        size={'medium'}
        onClick={onConfirm}
        startIcon={isRestricted ? <KingClearIcon /> : undefined}
      >
        <Trans i18nKey={'action_save'} />
      </ButtonNew>
    </div>
  </div>
)
