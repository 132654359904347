import React from 'react'

import FolderTools from './components/FolderTools'
import FolderTitle from './components/FolderTitle'
import PageTitle from './components/PageTitle'
import GoToParentButton from './components/GoToParentButton'

import styles from './FolderToolBar.module.css'

type Props = {
  isPage: boolean
  className?: string
}
const FolderToolBar: React.FC<Props> = ({ isPage, className }) => {
  return (
    <div className={[styles.Root, className].join(' ')}>
      <div className={styles.Inline}>
        <GoToParentButton className={styles.Button} />
        {isPage ? <PageTitle /> : <FolderTitle />}
      </div>
      <FolderTools />
    </div>
  )
}

export default FolderToolBar
