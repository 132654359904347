import { useLogEvents } from '@features/analytics/useLogEvents'

enum TargetNames {
  viewModeBadgeClick = 'app_view_mode_badge_click',
  contentCreate = 'app_content_create',
  appShareSelection = 'app_share_selection',
  appShareFolder = 'app_share_folder',
  appSharePage = 'app_share_page',
}

const useToolbarEvents = () => {
  const { trigger } = useLogEvents()

  const viewModeBadgeClick = (boardId: string) =>
    trigger(TargetNames.viewModeBadgeClick, { board_id: boardId })

  const shareSelectionButtonClicked = (count: number, shortcut: string) =>
    trigger(TargetNames.appShareSelection, {
      items_count: count,
      link_shortcut: shortcut || ',',
    })

  const shareFolderButtonClicked = (
    boardId: string,
    folderId: string,
    folderName: string,
    type: 'folder' | 'board',
    shortcut: string | null,
  ) => {
    trigger(TargetNames.appShareFolder, {
      board_id: boardId,
      folder_id: folderId,
      folder_name: folderName,
      type,
      link_shortcut: shortcut || '',
    })
  }

  const sharePageButtonClicked = (
    boardId: string,
    pageId: string,
    pageName: string,
    shortcut: string | null,
    hasForm: boolean,
    hasCheckout: boolean,
    isPageBoard: boolean | undefined,
  ) => {
    trigger(TargetNames.appSharePage, {
      board_id: boardId,
      key_id: pageId,
      page_name: pageName,
      link_shortcut: shortcut || '',
      has_form: (!!hasForm).toString(),
      has_checkout: (!!hasCheckout).toString(),
      pages_in_homescreen: (!!isPageBoard).toString(),
    })
  }

  return {
    shareSelectionButtonClicked,
    shareFolderButtonClicked,
    sharePageButtonClicked,
    viewModeBadgeClick,
  }
}
export default useToolbarEvents
