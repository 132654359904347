import React, { FC } from 'react'
import { EmailAccountMenuIcon, Menu } from 'boards-web-ui'
import { t } from 'i18next'
import {
  AccountMenuItem,
  IAccountMenuItemProps,
} from '@features/account/components/AccountMenuItem'
import { Link } from 'react-router-dom'
import { useAuth } from '@hooks/useAuth'
import {
  ProfileMenuSourceEnum,
  useProfileMenuEvents,
} from '@features/analytics/useProfileMenuEvents'
import useAccountSubscriptionEvents, {
  SubscriptionSourceEnum,
} from '@features/analytics/useAccountSubscriptionEvents'

import {
  TAccountSubRoutes,
  USER_MESSAGE_PREFERENCES,
  USER_PERSONAL_INFO,
  USER_SUBSCRIPTION,
} from 'app/constants/routes'
import { ReactComponent as NameIcon } from '../../../../icons/name.svg'
import { ReactComponent as SubscriptionIcon } from '../../../../icons/grid-full.svg'
import { ReactComponent as LogOutIcon } from '../../../../icons/back.svg'

import styles from './AccountMenu.module.css'

export type TMenuItemId = TAccountSubRoutes | 'signOut'

interface IProps {
  activeItem: TAccountSubRoutes
}

const menuItems: {
  id: TMenuItemId
  Component: FC<Pick<IAccountMenuItemProps, 'isActive' | 'onClick'>>
}[] = [
  {
    id: USER_PERSONAL_INFO,
    Component: ({ isActive, onClick }) => {
      const { appInfoClickEvent } = useProfileMenuEvents(
        ProfileMenuSourceEnum.Account,
      )

      const handleClick = () => {
        appInfoClickEvent()
        if (onClick) {
          onClick()
        }
      }

      return (
        <Link to={USER_PERSONAL_INFO}>
          <AccountMenuItem
            Icon={NameIcon}
            title={t('personal_info_title')}
            isActive={isActive}
            onClick={handleClick}
          />
        </Link>
      )
    },
  },
  {
    id: USER_SUBSCRIPTION,
    Component: ({ isActive, onClick }) => {
      const { subscriptionClickEvent } = useAccountSubscriptionEvents()
      return (
        <Link
          to={USER_SUBSCRIPTION}
          onClick={() => subscriptionClickEvent(SubscriptionSourceEnum.Account)}
        >
          <AccountMenuItem
            Icon={SubscriptionIcon}
            title={t('subscription_title')}
            isActive={isActive}
            onClick={onClick}
          />
        </Link>
      )
    },
  },
  {
    id: USER_MESSAGE_PREFERENCES,
    Component: ({ isActive, onClick }) => {
      const { appEmailPreferencesClick } = useProfileMenuEvents(
        ProfileMenuSourceEnum.Account,
      )

      const handleClick = () => {
        appEmailPreferencesClick()
        if (onClick) {
          onClick()
        }
      }

      return (
        <Link to={USER_MESSAGE_PREFERENCES}>
          <AccountMenuItem
            Icon={EmailAccountMenuIcon}
            iconSize={{ width: 14, height: 11 }}
            title={t('message_preferences_title')}
            isActive={isActive}
            onClick={handleClick}
          />
        </Link>
      )
    },
  },
  {
    id: 'signOut',
    Component: ({ onClick }) => {
      const { signOut } = useAuth()
      const { appLogoutClickEvent } = useProfileMenuEvents(
        ProfileMenuSourceEnum.Account,
      )

      const handleClick = () => {
        appLogoutClickEvent()
        if (onClick) {
          onClick()
        }
        signOut()
      }

      return (
        <AccountMenuItem
          Icon={LogOutIcon}
          title={t('action_logout')}
          onClick={handleClick}
        />
      )
    },
  },
]

export const AccountMenu: FC<IProps> = ({ activeItem }) => (
  <Menu className={styles.Menu}>
    {menuItems.map(({ id, Component }) => (
      <Component key={id} isActive={id === activeItem} />
    ))}
  </Menu>
)
