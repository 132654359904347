import React, { FC, HTMLAttributes, ReactElement } from 'react'
import clsx from 'clsx'
import { SimpleButton } from '../../../../ui/components/SimpleButton'

import styles from './PageMenuItem.module.css'

type Props = HTMLAttributes<HTMLDivElement> & {
  icon: string | ReactElement
  title: string
  chip?: string | ReactElement
  onClick: () => void
  disabledStyleMenu?: boolean
}

const PageMenuItem: FC<Props> = ({
  icon,
  title,
  chip,
  style,
  onClick,
  disabledStyleMenu,
  ...rest
}) => {
  return (
    <div className={styles.Root} onClick={onClick} {...rest}>
      <SimpleButton
        secondary
        centered
        className={clsx(styles.Button, disabledStyleMenu && styles.Disabled)}
        style={{
          width: '38px',
          height: '38px',
          borderRadius: '16px',
          ...style,
        }}
      >
        {icon}
      </SimpleButton>
      <span
        className={clsx(styles.Title, disabledStyleMenu && styles.Disabled)}
      >
        {title}
      </span>
      {chip}
    </div>
  )
}

export default PageMenuItem
