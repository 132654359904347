import { useLocation, useNavigate } from 'react-router-dom'
import { useDialog } from 'boards-web-ui'

import { IOpenNode } from '../models/nodes'

import NodePreview from '../../nodePreview/NodePreview'
import { Folder } from '../../../models'

const useNodeActions = (
  folder?: Folder,
  isSharedFolderMode?: boolean,
  canCopyContentOfSharedFolder?: boolean,
  sharedBoardId?: string,
  sharedFolderId?: string,
): IOpenNode => {
  const navigate = useNavigate()
  const { search } = useLocation()
  const { open, close } = useDialog()

  const previewNode = (nodeId: string, hideParents?: boolean) =>
    open(
      <NodePreview
        nid={nodeId}
        close={close}
        folder={folder}
        isSharedFolderMode={isSharedFolderMode}
        canCopyContentOfSharedFolder={canCopyContentOfSharedFolder}
        sharedBoardId={sharedBoardId}
        sharedFolderId={sharedFolderId}
      />,
      {
        fullScreen: true,
        closeOnEscapeButton: true,
        closeOnOverlayClick: true,
        overlayBackgroundColor: 'rgba(0, 0, 0, 0.9)',
        withFullScreenDialogContainer: false,
        hideParents,
      },
    )

  const openFolder = (boardId: string, folderId: string) =>
    navigate(`/${boardId}/${folderId}/${search}`)

  return {
    openFolder,
    previewNode,
  }
}

export default useNodeActions
