import React from 'react'

import useBulk from '@hooks/bulk/useBulk'
import { SimpleButton } from '@ui/components/SimpleButton'
import { TooltipPositions } from '@ui/models/tooltip'
import Tooltip, { TooltipContent } from '@ui/components/Tooltip'

import { ReactComponent as UndoIcon } from '../icons/Undo.svg'
import { ReactComponent as RedoIcon } from '../icons/Redo.svg'

import useUndoRedo from '../hooks/useUndoRedo'
import useToolbarTranslations from '../hooks/useToolbarTranslations'

type Props = {
  className?: string
}
const UndoRedo: React.FC<Props> = ({ className }) => {
  const { undo, redo, isDisabled } = useUndoRedo()
  const { undoAction, redoAction } = useToolbarTranslations()

  const { isSelectionModeEnabled } = useBulk()

  const selectionActive = isSelectionModeEnabled

  return (
    <>
      <Tooltip
        className={className}
        position={TooltipPositions.BOTTOM}
        data={
          selectionActive ? '' : <TooltipContent>{undoAction()}</TooltipContent>
        }
      >
        <SimpleButton
          name="undo"
          lightBlue
          lightDisabled
          fitToContent
          mediumBtnIcon
          customSize={38}
          disabled={!undo || isDisabled}
          onClick={undo}
        >
          <UndoIcon />
        </SimpleButton>
      </Tooltip>
      <Tooltip
        className={className}
        data={
          selectionActive ? '' : <TooltipContent>{redoAction()}</TooltipContent>
        }
      >
        <SimpleButton
          name="redo"
          lightBlue
          lightDisabled
          fitToContent
          mediumBtnIcon
          customSize={38}
          disabled={!redo || isDisabled}
          onClick={redo}
        >
          <RedoIcon />
        </SimpleButton>
      </Tooltip>
    </>
  )
}

export default UndoRedo
