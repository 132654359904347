import React from 'react'

import { AddNewKeyOptions } from '../models'

import Menu, { MenuItem } from '../../../../ui/components/Menu'

import styles from './AddContentMenu.module.css'

type Props = {
  options: AddNewKeyOptions
  className?: string
}
const AddContentMenu: React.FC<Props> = ({ options, className }) => (
  <Menu as="div" className={className}>
    {options.map(({ title, icon, chip, onClick, addSeparatorAbove }) => (
      <div key={`menu-${title.replace(' ', '-')}`}>
        {addSeparatorAbove && <hr className={styles.Divider} />}
        <MenuItem
          className={styles.MenuItem}
          as="button"
          type="button"
          key={`menu-${title}`}
          chip={chip}
          onClick={onClick}
        >
          {icon}
          {title}
        </MenuItem>
      </div>
    ))}
  </Menu>
)

export default AddContentMenu
