import React, { FC, PropsWithChildren, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { TNodeCheckoutForm, urlHelper } from 'boards-web-ui'

import { CheckoutActionsIdsEnum } from '@features/checkout/models'
import type { ICreateOrUpdateNode } from '@features/nodeEditor/models/IUseNodeCreatorAPI'
import { useGoogleSheetConnector } from '@features/googleSpreadsheet'
import usePaymentProviders from '@features/checkout/hooks/usePaymentProviders'
import useFormIntegrationStatusState from '@features/forms/hooks/useFormIntegrationStatusState'

import { CHECKOUT_POST_SUBMITTING_BEHAVIOUR } from '../constants'

import {
  useAdditionalFields,
  useCheckoutActions,
  useCheckoutApi,
  useCheckoutCurrency,
  useCheckoutProducts,
  useCheckoutSettingsPayment,
  useSettingsPostSubmission,
  useSettingsResponses,
} from '../hooks'
import CheckoutContext from './CheckoutContext'

type CheckoutProviderProps = {
  initialScreen?: CheckoutActionsIdsEnum
  isOwn: boolean
  isPayPalAccountConnected: boolean
  isGoogleAccountConnected: boolean
  boardId: string
  pageId: string
  initialNode?: TNodeCheckoutForm
  advancedForms: boolean
  onPaywall: () => void
  onSave: ICreateOrUpdateNode
  onCancel: () => void
}
const CheckoutProvider: FC<PropsWithChildren<CheckoutProviderProps>> = ({
  initialScreen = CheckoutActionsIdsEnum.PRODUCTS,
  isOwn,
  isPayPalAccountConnected,
  isGoogleAccountConnected,
  boardId,
  pageId,
  initialNode,
  advancedForms,
  onPaywall,
  onSave,
  onCancel,
  children,
}) => {
  const { t } = useTranslation()
  const {
    products,
    isProductsValid,
    onProductsReOrder,
    onProductAdd,
    onProductDelete,
    onProductChange,
  } = useCheckoutProducts(initialNode?.form?.products)

  const {
    additionalFields,
    isAdditionalFieldsValid,
    onAdditionalFieldsReOrder,
    onAdditionalFieldsAdd,
    onAdditionalFieldsDelete,
    onAdditionalFieldsChange,
  } = useAdditionalFields(
    initialNode?.form?.additionalFields,
    initialNode?.form?.fields,
  )

  const {
    postSubmissionBehavior,
    postSubmissionMessage,
    postSubmissionUrl,
    postSubmissionError,
    onPostSubmissionBehaviourChange,
    onPostSubmissionMessageChange,
    onPostSubmissionUrlChange,
  } = useSettingsPostSubmission({
    defaultPostSubmissionBehavior: urlHelper.isValidUrl(
      initialNode?.form?.response || '',
    )
      ? CHECKOUT_POST_SUBMITTING_BEHAVIOUR.REDIRECT
      : CHECKOUT_POST_SUBMITTING_BEHAVIOUR.MESSAGE,
    defaultPostSubmissionMessage: urlHelper.isValidUrl(
      initialNode?.form?.response,
    )
      ? ''
      : initialNode?.form?.response || t('forms_thanks_message_default'),
    postSubmissionUrl: urlHelper.isValidUrl(initialNode?.form?.response || '')
      ? initialNode?.form?.response || ''
      : '',
  })

  const { integrationStatusState, onGetFormIntegrationStatus } =
    useFormIntegrationStatusState({
      boardId,
      pageId,
      formId: initialNode?.id || '',
    })

  const {
    spreadsheetURL,
    isGoogleFormConnected,
    googleSheetResponseError,
    onGoogleSheetsUrlChange,
    onConnectAccountToGoogleSheets,
    isGoogleAccountConnectedPending,
  } = useGoogleSheetConnector({
    isGoogleAccountConnected,
    integrationStatusState,
    onPaywall,
  })

  const { responsesEmail, responsesEmailError, onResponsesEmailChange } =
    useSettingsResponses(
      integrationStatusState?.decryptedProperties?.email || '',
    )

  const { shippingFees, minOrder, onMinOrderChange, onShippingFeesChange } =
    useCheckoutSettingsPayment({
      defaultShippingFees: initialNode?.form?.shippingFee?.toString() || '0',
      defaultMinOrder: initialNode?.form?.minOrder?.toString() || '0',
    })

  const {
    paymentProviders,
    onPaypalConnect,
    payPalIntegrationError,
    isPaypalFormConnected,
    isPayPalConnectionPending,
  } = usePaymentProviders({
    isPayPalAccountConnected,
    authorizationUrl:
      integrationStatusState?.payPalAuthorization.authorizationURL || '',
    paymentProviders:
      initialNode?.form?.paymentProviders ||
      integrationStatusState?.paymentProviders ||
      [],
  })

  const { currency, currencies, onCurrencyChange } = useCheckoutCurrency(
    initialNode?.form?.currency
      ? { defaultCurrency: initialNode?.form?.currency }
      : {},
  )

  const { isSubmitted, onCheckoutSave, onCheckoutCancel } = useCheckoutApi({
    isOwn,
    action: '',
    initialForm: initialNode,

    encryptedEmail: initialNode?.form.encryptedEmail || '',
    initialResponsesEmail: integrationStatusState?.decryptedProperties?.email,
    responsesEmail,
    responsesEmailError,

    encryptedSpreadsheetURL: initialNode?.form?.encryptedSpreadsheetURL || '',
    initialSpreadsheetURL:
      integrationStatusState?.decryptedProperties?.spreadsheetURL,
    spreadsheetURL,

    googleSheetResponseError,

    products,
    additionalFields,

    currency,
    shippingFees,
    minOrder,

    postSubmissionUrl,
    postSubmissionBehavior,
    postSubmissionMessage,
    postSubmissionError,

    paymentProviders,

    isGoogleAccountConnected,
    isPayPalAccountConnected,

    onSave,
    onCancel,
  })

  const { action } = useCheckoutActions({
    initialScreen,
    isProductsValid,
    isAdditionalFieldsValid,
    onSave: () => onCheckoutSave(),
  })

  const state = useMemo(
    () => ({
      isOwn,
      action,
      advancedForms,

      products,
      onProductsReOrder,
      onProductAdd,
      onProductDelete,
      onProductChange,

      additionalFields,
      onAdditionalFieldsReOrder,
      onAdditionalFieldsAdd,
      onAdditionalFieldsDelete,
      onAdditionalFieldsChange,

      currency,
      currencies,
      onCurrencyChange,

      shippingFees,
      onShippingFeesChange,

      minOrder,
      onMinOrderChange,

      isPayPalAccountConnected,
      isGoogleFormConnected,
      isPaypalFormConnected,
      paymentProviders,
      isPayPalConnectionPending,
      payPalIntegrationError,
      onPaypalConnect,

      postSubmissionBehavior,
      postSubmissionMessage,
      postSubmissionUrl,
      postSubmissionError,
      onPostSubmissionUrlChange,
      onPostSubmissionMessageChange,
      onPostSubmissionBehaviourChange,

      responsesEmail,
      responsesEmailError,
      onResponsesEmailChange,

      isGoogleAccountConnected,
      spreadsheetURL,
      googleSheetResponseError,
      isGoogleAccountConnectedPending,
      onGoogleSheetsUrlChange,
      onConnectAccountToGoogleSheets,

      isSubmitted,
      onPaywall,
      onCheckoutCancel,
    }),
    [
      isOwn,
      action,
      advancedForms,

      products,
      onProductsReOrder,
      onProductAdd,
      onProductDelete,
      onProductChange,

      additionalFields,
      onAdditionalFieldsReOrder,
      onAdditionalFieldsAdd,
      onAdditionalFieldsDelete,
      onAdditionalFieldsChange,

      currency,
      currencies,
      onCurrencyChange,

      shippingFees,
      onShippingFeesChange,

      minOrder,
      onMinOrderChange,

      isPayPalAccountConnected,
      isPaypalFormConnected,
      paymentProviders,
      isPayPalConnectionPending,
      payPalIntegrationError,
      onPaypalConnect,

      postSubmissionBehavior,
      postSubmissionMessage,
      postSubmissionUrl,
      postSubmissionError,
      onPostSubmissionUrlChange,
      onPostSubmissionMessageChange,
      onPostSubmissionBehaviourChange,

      responsesEmail,
      responsesEmailError,
      onResponsesEmailChange,

      isGoogleAccountConnected,
      isGoogleFormConnected,
      spreadsheetURL,
      googleSheetResponseError,
      isGoogleAccountConnectedPending,
      onGoogleSheetsUrlChange,
      onConnectAccountToGoogleSheets,

      isSubmitted,
      onPaywall,
      onCheckoutCancel,
    ],
  )

  useEffect(() => {
    onGetFormIntegrationStatus(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <CheckoutContext.Provider value={state}>
      {children}
    </CheckoutContext.Provider>
  )
}

export default CheckoutProvider
