import React, { useCallback, memo } from 'react'

import ButtonIcon from '../../../../elements/ButtonIcon'
import { ReactComponent as BackIcon } from '../../../../icons/dubbleArrow.svg'
import Tooltip, { TooltipContent } from '../../../../ui/components/Tooltip'

import { useUpdatesTools } from '../hooks/useUpdatesTools'
import { useUpdatesMessages } from '../hooks/useUpdatesMessages'
import { useUpdatesApi } from '../hooks/useUpdatesApi'
import { useUpdatesTranslations } from '../hooks/useUpdatesTranslations'

import styles from './UpdatesSectionHeader.module.css'

const UpdatesSectionHeader: React.FC = () => {
  const { close } = useUpdatesTools()
  const { messages } = useUpdatesMessages()
  const { marksAllAsRead } = useUpdatesApi()
  const { closeButtonTooltip, postUpdate } = useUpdatesTranslations()

  const readAll = useCallback(() => {
    marksAllAsRead(messages)
  }, [marksAllAsRead, messages])

  return (
    <div className={styles.Root}>
      <Tooltip
        className={styles.CloseButton}
        as="div"
        data={<TooltipContent>{closeButtonTooltip()}</TooltipContent>}
      >
        <ButtonIcon
          size={38}
          round
          variant="gray"
          onClick={() => {
            close()
            readAll()
          }}
        >
          <BackIcon />
        </ButtonIcon>
      </Tooltip>
      <h3 className={styles.Header}>{postUpdate()}</h3>
    </div>
  )
}

export default memo(UpdatesSectionHeader)
