import PropTypes from 'prop-types'
import styles from './ButtonIcon.module.css'

const variantClasses = {
  gray: styles.Gray,
  blue: styles.Blue,
}

const ButtonIcon = ({
  name = 'button',
  size,
  round,
  variant,
  children,
  onClick,
  isActive,
  className = '',
}) => {
  const classes = [styles.Root]
  if (className) {
    classes.push(className)
  }
  if (variant) {
    classes.push(variantClasses[variant])
  }

  if (isActive) {
    classes.push(styles.isActive)
  }

  classes.push(round ? styles.Round : styles.Square)

  return (
    <button
      name={name}
      className={classes.join(' ')}
      onClick={onClick}
      style={{ height: `${size}px`, width: `${size}px` }}
    >
      {children}
    </button>
  )
}

ButtonIcon.defaultProps = {
  round: false,
  size: 40,
}

ButtonIcon.propTypes = {
  children: PropTypes.node,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  variant: PropTypes.oneOf(['default', 'gray', 'blue']),
  size: PropTypes.number,
  round: PropTypes.bool,
}

/** @deprecated Use "ButtonIcon" instead from "boards-web-ui" */
export default ButtonIcon
