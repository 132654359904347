import { useTranslation } from 'react-i18next'
import { FieldTypes, FormField, FormFields } from 'boards-web-ui'
import { ADDITIONAL_FIELDS_TYPE } from '@features/forms/constants'

const useFormFieldTransformer = () => {
  const { t } = useTranslation()
  const transformChoiceField = (field: FormField) => {
    const transformedChoices = field.choices?.map((choice, index) => {
      if (choice.length > 0) {
        return choice
      }
      return `${t('label_option_x')} ${index + 1}`
    })
    return {
      ...field,
      choices: transformedChoices,
    }
  }

  const transformFormFields = (fields: FormFields | undefined) => {
    return fields?.map((field) => {
      return field.type === FieldTypes.CHOICE
        ? transformChoiceField(field)
        : {
            ...field,
            label: `${
              field?.label ||
              t(ADDITIONAL_FIELDS_TYPE[field.type]?.defaultValue) ||
              ''
            }`,
          }
    })
  }

  return { transformFormFields }
}

export default useFormFieldTransformer
