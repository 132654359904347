import React, { createContext, useCallback, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { useSelectorFactory } from '@hooks/useSelectorFactory'
import { useUpdatesEvents } from '../analytics/useUpdatesEvents'

import { getIsLoading, selectBoardsCount } from '../../../selectors'

import { useUpdatesApi } from './hooks/useUpdatesApi'

export type UpdatesToolsState = {
  isOpen: boolean
  isAvailable: boolean
  disabled: boolean
  close: () => void
  toggle: () => void
}

const defaultState: UpdatesToolsState = {
  isOpen: false,
  isAvailable: false,
  disabled: false,
  /* eslint-disable */
  close: () => {},
  toggle: () => {},
  /* eslint-enable */
}

export const UpdatesContext = createContext<UpdatesToolsState>({
  ...defaultState,
})

const UpdatesProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { selectMessage } = useUpdatesApi()
  const { updatesPaneClose } = useUpdatesEvents()
  const [searchParams] = useSearchParams()
  const [isOpenState, setIsOpenState] = useState<boolean>(
    searchParams.has('message'),
  )

  const loading = useSelectorFactory(getIsLoading)
  const boardsCount = useSelectorFactory(selectBoardsCount)
  const open = useCallback(() => setIsOpenState(true), [setIsOpenState])
  const close = useCallback(() => {
    updatesPaneClose()
    setIsOpenState(false)
    selectMessage(null)
  }, [selectMessage, updatesPaneClose, setIsOpenState])
  const toggle = useCallback(
    () => (isOpenState ? close() : open()),
    [isOpenState, open, close],
  )

  const data: UpdatesToolsState = useMemo(
    () => ({
      isAvailable: !loading && !!boardsCount,
      disabled: false,
      isOpen: isOpenState,
      close,
      toggle,
    }),
    [loading, isOpenState, boardsCount, toggle, close],
  )

  return (
    <UpdatesContext.Provider value={data}>{children}</UpdatesContext.Provider>
  )
}

export default UpdatesProvider
