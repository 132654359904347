import { Board } from '../app/models'

const logEvent =
  (event: string, parameters?: Record<string, unknown>) =>
  async (
    _: never,
    getState: () => { boards: { [key: string]: Board } },
    extraArgument: {
      logEvent: (
        event: string,
        params: { [key: string]: unknown },
      ) => Promise<void>
    },
  ) => {
    const { boards } = getState()
    const extraParams = Object.keys(boards)
      .filter((boardId) => !boards[boardId].isOwn)
      .reduce(
        (acc, boardId, index) => ({
          ...acc,
          [`received_board_id${index + 1}`]: boardId,
        }),
        {},
      )

    extraArgument.logEvent(event, { ...parameters, ...extraParams })
  }

const logUsageEvent =
  (event: string, parameters?: Record<string, unknown>) =>
  async (
    _: never,
    __: never,
    {
      logUsage,
    }: {
      logUsage: (props: {
        event: string
        [key: string]: unknown
      }) => Promise<void>
    },
  ) => {
    logUsage({
      event,
      ...parameters,
    })
  }

export const asyncActions = {
  logEvent,
  logUsageEvent,
} as const
