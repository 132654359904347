import React, { useRef } from 'react'

import useSideBarBody from '../hooks/useSideBarBody'

import BoardsMenu from '../../boardsMenu/Menu'
import BoardsMenuTitle from '../../boardsMenu/BoardsMenuTitle'

import styles from './SideBarBody.module.css'

type Props = {
  className?: string
  titleClassName?: string
}
const SideBarBody: React.FC<Props> = ({ className, titleClassName }) => {
  const scrollableRef = useRef<HTMLDivElement>(null)
  const { loading, scrollToBottom } = useSideBarBody(scrollableRef)

  const scrollToNewBoard = () => setTimeout(scrollToBottom, 100)

  return (
    <div className={[styles.Root, className].join(' ')}>
      <BoardsMenuTitle
        className={titleClassName}
        loading={loading}
        onCreate={scrollToNewBoard}
      />
      {!loading ? (
        <div className={styles.Scrollable} ref={scrollableRef}>
          <BoardsMenu />
        </div>
      ) : null}
    </div>
  )
}

export default React.memo(SideBarBody)
