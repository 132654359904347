import React from 'react'
import { EmailAccountMenuIcon, Menu, MenuItem } from 'boards-web-ui'
import { Link, useLocation } from 'react-router-dom'
import {
  ProfileMenuSourceEnum,
  useProfileMenuEvents,
} from '@features/analytics/useProfileMenuEvents'
import { useAuth } from '@hooks/useAuth'
import useAccountSubscriptionEvents, {
  SubscriptionSourceEnum,
} from '@features/analytics/useAccountSubscriptionEvents'

import { useProfileTranslations } from '../hooks/useProfileTranslations'
import { useUpdatesTools } from '../../updates/hooks/useUpdatesTools'
import VideoUsage from '../../../../ui/components/VideoUsage'
import { useProfileInformation } from '../hooks/useProfileInformation'
import useUserFeatures, { Features } from '../../../../hooks/useUserFeatures'

import { ReactComponent as FeedBackIcon } from '../../../../icons/feedback.svg'
import { ReactComponent as NameIcon } from '../../../../icons/name.svg'
import { ReactComponent as LogOutIcon } from '../../../../icons/back.svg'
import { ReactComponent as SubscriptionIcon } from '../../../../icons/grid-full.svg'

import links from '../../../constants/links'
import {
  ACCOUNT,
  USER_MESSAGE_PREFERENCES,
  USER_PERSONAL_INFO,
  USER_SUBSCRIPTION,
} from '../../../constants/routes'

import styles from './ProfileMenu.module.css'

export const ProfileMenu: React.FC = () => {
  const { logOut, feedBack, personalInfo, messagePreferences, subscription } =
    useProfileTranslations()
  const { signOut } = useAuth()

  const { close } = useUpdatesTools()
  const { profile } = useProfileInformation()
  const location = useLocation()

  const { subscriptionClickEvent } = useAccountSubscriptionEvents()
  const {
    appFeedBackClickEvent,
    appLogoutClickEvent,
    appInfoClickEvent,
    appEmailPreferencesClick,
  } = useProfileMenuEvents(ProfileMenuSourceEnum.Menu)

  const { enabled, tryFree } = useUserFeatures(Features.uploadVideo)

  const handleLogOutClick = () => {
    appLogoutClickEvent()
    signOut()
    close()
  }

  const isShowVideoUsage =
    enabled && !tryFree && Number.isInteger(profile.plan.limits.videos)

  return (
    <>
      {isShowVideoUsage ? (
        <div className={styles.VideoUsageContainer}>
          <VideoUsage
            limit={profile.plan.limits.videos}
            used={profile.usage.videos}
          />
        </div>
      ) : null}

      <Menu className={styles.Menu}>
        <MenuItem as="span">
          <Link
            to={`${ACCOUNT}/${USER_PERSONAL_INFO}`}
            state={location.pathname}
            onClick={appInfoClickEvent}
          >
            <NameIcon className={styles.Icon} />
            {personalInfo()}
          </Link>
        </MenuItem>

        <MenuItem as="span">
          <Link
            to={`${ACCOUNT}/${USER_SUBSCRIPTION}`}
            state={location.pathname}
            onClick={() => subscriptionClickEvent(SubscriptionSourceEnum.Menu)}
          >
            <SubscriptionIcon className={styles.Icon} />
            {subscription()}
          </Link>
        </MenuItem>

        <MenuItem as="span">
          <Link
            to={`${ACCOUNT}/${USER_MESSAGE_PREFERENCES}`}
            state={location.pathname}
            onClick={appEmailPreferencesClick}
          >
            <EmailAccountMenuIcon
              className={styles.Icon}
              width={14}
              height={11}
            />
            {messagePreferences()}
          </Link>
        </MenuItem>
      </Menu>

      <div className={styles.Divider} />

      <Menu className={styles.Menu}>
        <MenuItem
          as="a"
          href={links.supportUrl}
          target="_blank"
          rel="noopener noreferrer"
          onClick={appFeedBackClickEvent}
        >
          <FeedBackIcon className={styles.Icon} />
          {feedBack()}
        </MenuItem>

        <MenuItem as="button" type="button" onClick={handleLogOutClick}>
          <LogOutIcon className={styles.Icon} />
          {logOut()}
        </MenuItem>
      </Menu>
    </>
  )
}
