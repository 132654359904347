import { FC, PropsWithChildren } from 'react'
import { Trans } from 'react-i18next'
import { ButtonNew, KingIcon, getCurrencySymbol } from 'boards-web-ui'

import useGeoLocation from '@hooks/useGeoLocation'
import SubscriptionRow from '@features/account/components/SubscriptionRow'
import PriceInfo from '@features/account/components/PriceInfo'

type Props = PropsWithChildren<{
  onChangePlanClick: () => void
}>
const FreePlan: FC<Props> = ({ onChangePlanClick }) => {
  const { geoData } = useGeoLocation()

  const currency = getCurrencySymbol(geoData?.currency || 'USD')

  return (
    <SubscriptionRow
      planInfo={
        <PriceInfo
          planTitle={<Trans i18nKey={'free_plan_title'} />}
          planPrice={`${currency}0`}
        />
      }
      button={
        <ButtonNew
          color={'primary'}
          size={'medium'}
          startIcon={<KingIcon />}
          onClick={onChangePlanClick}
        >
          <Trans i18nKey={'paywall_upgrade'} />
        </ButtonNew>
      }
    />
  )
}

export default FreePlan
