import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useSelectorFactory } from '@hooks/useSelectorFactory'
import useBoard from '../../../../hooks/useBoard'

import { getParentFolder } from '../../../../selectors'

import Tooltip, { TooltipContent } from '../../../../ui/components/Tooltip'
import { SimpleButton } from '../../../../ui/components/SimpleButton'

import { ReactComponent as BackIcon } from '../icons/GoBack.svg'

import styles from './GoToParentButton.module.css'

type Props = {
  className?: string
}
const GoToParentButton: React.FC<Props> = ({ className }) => {
  const navigate = useNavigate()
  const { search } = useLocation()
  const { boardId, folderId, isRoot } = useBoard()

  const nextParent = useSelectorFactory(getParentFolder, boardId, folderId)

  if (isRoot || !nextParent) {
    return null
  }

  const goToParent = () => navigate(`/${boardId}/${nextParent.id}${search}`)

  return (
    <Tooltip
      className={className}
      data={<TooltipContent>{nextParent?.title}</TooltipContent>}
    >
      <SimpleButton
        name="go to parent folder"
        lightGray
        mediumBtnIcon
        fitToContent
        disabled={isRoot}
        onClick={goToParent}
        customSize={38}
      >
        <BackIcon className={styles.Icon} />
      </SimpleButton>
    </Tooltip>
  )
}

export default GoToParentButton
