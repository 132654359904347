import React, { ReactElement } from 'react'
import { useDialog } from 'boards-web-ui'

import { SimpleButton } from '../../../ui/components/SimpleButton'
import { ReactComponent as PeopleIcon } from '../../../icons/shared.svg'
import Share from './Share'
import useBoard from '../../../hooks/useBoard'
import useSharePublicLink from './hooks/useSharePublicLink'
import { ShareScreens } from './hooks/useShareNav'

const DefaultTrigger = () => (
  <SimpleButton>
    <PeopleIcon />
  </SimpleButton>
)

interface Props {
  membersScreen?: boolean
  trigger?: ReactElement
}
const ShareButton = ({
  membersScreen,
  trigger = <DefaultTrigger />,
}: Props) => {
  const { open } = useDialog()
  const { canShare } = useBoard()
  const { publicKeyEnabled } = useSharePublicLink()

  const onClick = () => {
    open(<Share screen={membersScreen ? ShareScreens.MEMBERS : undefined} />, {
      fullScreen: true,
    })
  }

  if (!canShare && !publicKeyEnabled) {
    return null
  }

  return React.cloneElement(trigger, { onClick })
}

export default ShareButton
