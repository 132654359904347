import { Grid } from 'boards-web-ui'
import { Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import useFolderEvents from '@features/analytics/useFolderEvents'
import useNodeCreatorAPI from '@features/nodeEditor/hooks/useNodeCreatorAPI'
import useNodeEditorAPI from '@features/nodeEditor/hooks/useNodeEditorAPI'
import { INodeProps } from '@features/nodeEditor/models/IUseNodeCreatorAPI'

import { ReactComponent as FolderIcon } from '../icons/Folder.svg'
import { ReactComponent as TextIcon } from '../icons/Text.svg'
import { ReactComponent as VideoIcon } from '../icons/Video.svg'
import { ReactComponent as VideoStreamingIcon } from '../icons/VideoStreaming.svg'
import { ReactComponent as LinkIcon } from '../icons/Link.svg'
import { ReactComponent as ImageIcon } from '../icons/Image.svg'
import { ReactComponent as PdfIcon } from '../icons/PDF.svg'
import { ReactComponent as PageIcon } from '../icons/Page.svg'
import { ReactComponent as CrownIcon } from '../icons/Crown.svg'

import Chip from '../../../../ui/components/Chip'
import { AddNewKeyOptions } from '../models'
import { ScrollAreaContainer } from '../../../../ui/models/ScrollArea'
import useAddNode from '../../nodeEditor/useAddNode'
import useScrollArea from '../../../../hooks/useScrollArea'
import useFeatureFlags from '../../../../hooks/useFeatureFlags'
import useAddContentTranslations from './useAddContentTranslations'
import { useProfileInformation } from '../../profile/hooks/useProfileInformation'
import useRouterParams from '../../../../hooks/useRouterParams'
import useUserFeatures, { Features } from '../../../../hooks/useUserFeatures'
import { Node, NodeType } from '../../../models'

const useAddFolderMenu = (onMenuItemClick: () => void) => {
  const navigate = useNavigate()
  const { folderCreateContentEvent, addContentMenuItemClick } =
    useFolderEvents()
  const { profile } = useProfileInformation()
  const { tryFree, enabled, limitReached } = useUserFeatures(
    Features.uploadVideo,
  )
  const { pageBuilderFeatureFlag, uploadVideoEnabledFeatureFlag } =
    useFeatureFlags()
  const { scrollToBottom } = useScrollArea()
  const { boardId, folderId } = useRouterParams()
  const createNode = useNodeCreatorAPI(boardId, folderId)
  const editNode = useNodeEditorAPI(boardId, folderId)
  const {
    image,
    text,
    videoStreaming,
    videoYoutubeVimeo,
    link,
    folder,
    page,
    pdf,
    badgeNew,
  } = useAddContentTranslations()
  const {
    addFolder,
    addImages,
    addLink,
    addPdf,
    addText,
    addVideo,
    addVideoStreaming,
  } = useAddNode(
    folderId,
    (node: INodeProps, extraParams?: { [id: string]: string }) => {
      const nodeId = createNode(node)
      folderCreateContentEvent(node as Node, boardId, extraParams)
      scrollToBottom(ScrollAreaContainer.FOLDER)

      return nodeId
    },
    editNode,
  )
  const showTryFreeBadge = tryFree && !limitReached

  const closeMenu =
    (
      callback: () => void,
      type: NodeType,
      isTryFree = false,
      isBlocked = false,
    ) =>
    () => {
      if (onMenuItemClick) onMenuItemClick()
      callback()
      addContentMenuItemClick(type, isTryFree, isBlocked)
    }

  const options: AddNewKeyOptions = [
    {
      title: text(),
      icon: <TextIcon />,
      onClick: closeMenu(addText, NodeType.TEXT),
    },
    {
      title: image(),
      icon: <ImageIcon />,
      onClick: closeMenu(addImages, NodeType.IMAGE),
    },
    {
      title: link(),
      icon: <LinkIcon />,
      onClick: closeMenu(addLink, NodeType.LINK),
    },
    {
      title: videoStreaming(),
      chip: (
        <Grid rowGap={5}>
          {(!enabled || tryFree) && <CrownIcon />}
          {showTryFreeBadge && (
            <Chip blue>
              <Trans i18nKey={'badge_try_free'} />
            </Chip>
          )}
          {!showTryFreeBadge && !profile.uiState.videoHintShown && (
            <Chip blue>
              <Trans i18nKey={'badge_new'} />
            </Chip>
          )}
        </Grid>
      ),
      icon: <VideoStreamingIcon />,
      hidden: !profile.plan.monetized || !uploadVideoEnabledFeatureFlag,
      onClick: closeMenu(
        addVideoStreaming,
        NodeType.VIDEO_STREAMING,
        showTryFreeBadge,
        !enabled || tryFree,
      ),
    },
    {
      title: pdf(),
      icon: <PdfIcon />,
      onClick: closeMenu(addPdf, NodeType.PDF),
    },
    {
      title: videoYoutubeVimeo(),
      icon: <VideoIcon />,
      onClick: closeMenu(addVideo, NodeType.VIDEO),
    },
    {
      title: folder(),
      icon: <FolderIcon />,
      addSeparatorAbove: true,
      onClick: closeMenu(() => addFolder(), NodeType.FOLDER),
    },
    {
      title: page(),
      chip: profile.uiState.pagesHintShown ? undefined : (
        <Chip blue>{badgeNew()}</Chip>
      ),
      icon: <PageIcon />,
      hidden: !pageBuilderFeatureFlag,
      onClick: closeMenu(() => navigate('new-page'), NodeType.PAGE),
    },
  ]

  return options.filter(({ hidden }) => !hidden)
}

export default useAddFolderMenu
