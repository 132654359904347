import { TNodeForm, useDialog } from 'boards-web-ui'

import { FORMS_ACTION_IDS } from '@features/forms/models'
import { useProfileInformation } from '@features/profile/hooks/useProfileInformation'

import { Node } from '../../../models'
import { IUseSaveNode } from '../models/IUseEditNode'

import EditForm from '../components/EditForm'

const useEditForm: IUseSaveNode = (onSave) => {
  const { open, close } = useDialog()
  const { isGoogleAccountConnected } = useProfileInformation()

  return (node: Node, initialScreen?: string) => {
    open(
      <EditForm
        isGoogleAccountConnected={isGoogleAccountConnected}
        initialScreen={initialScreen as FORMS_ACTION_IDS}
        node={node as unknown as TNodeForm}
        onSave={(n) => {
          onSave(node.id, n)
        }}
        onCancel={close}
      />,
      {
        fullScreen: true,
        blankDialog: true,
      },
    )
  }
}

export default useEditForm
