import { EventCategories } from '@features/analytics/models/logEvents'

export enum ProfilingMarketingStatesEnum {
  PRESENTED = 'presented',
  COMPLETED = 'completed',
}

export enum ProfilingQuestionKeysEnum {
  INDUSTRY = 'industry',
  TYPE = 'type',
  TEAM_SIZE = 'team_size',
  BRAND_TEXT = 'brand_text',
  BRAND_SELECT = 'brand_select',
  BRAND_SELECT_TEXT = 'brand_select_text',
  PROFILING_DOWNLINE = 'downline',
  PROFILING_DOWNLINE_HERBALIFE = 'downline_herbalife',
  PROFILING_DOWNLINE_SIZE = 'downline_size',
  TIME_IN_BUSINESS = 'time_in_business',
  PROFILING_RANK = 'profiling_rank',
}

export const ProfilingQuestionReportKeys: { [key: string]: string } = {
  [ProfilingQuestionKeysEnum.INDUSTRY]: 'userIndustryV1',
  [ProfilingQuestionKeysEnum.BRAND_TEXT]: 'brandNameV1',
  [ProfilingQuestionKeysEnum.BRAND_SELECT]: 'brandNameV1',
  [ProfilingQuestionKeysEnum.TYPE]: 'userTypeV1',
  [ProfilingQuestionKeysEnum.TEAM_SIZE]: 'teamSizeV1',
  [ProfilingQuestionKeysEnum.BRAND_SELECT_TEXT]: 'brandNameV1',
  [ProfilingQuestionKeysEnum.TIME_IN_BUSINESS]: 'timeActive',
  [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE]: 'userTypeV1',
  [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_HERBALIFE]: 'userTypeV1',
  [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_SIZE]: 'teamSizeV1',
  [ProfilingQuestionKeysEnum.PROFILING_RANK]: 'profiling_rank',
}

export const ProfilingQuestionEventNames = {
  [ProfilingQuestionKeysEnum.INDUSTRY]: EventCategories.APP_PROFILING_INDUSTRY,
  [ProfilingQuestionKeysEnum.BRAND_TEXT]:
    EventCategories.APP_PROFILING_BRAND_TEXT,
  [ProfilingQuestionKeysEnum.BRAND_SELECT]:
    EventCategories.APP_PROFILING_BRAND_SELECT,
  [ProfilingQuestionKeysEnum.TYPE]: EventCategories.APP_PROFILING_TYPE,
  [ProfilingQuestionKeysEnum.TEAM_SIZE]:
    EventCategories.APP_PROFILING_TEAM_SIZE,
  [ProfilingQuestionKeysEnum.BRAND_SELECT_TEXT]:
    EventCategories.APP_PROFILING_BRAND_SELECT_TEXT,
  [ProfilingQuestionKeysEnum.TIME_IN_BUSINESS]:
    EventCategories.APP_PROFILING_TIME_IN_BUSINESS,
  [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE]:
    EventCategories.APP_PROFILING_PROFILING_DOWNLINE,
  [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_HERBALIFE]:
    EventCategories.APP_PROFILING_PROFILING_DOWNLINE_HERBALIFE,
  [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_SIZE]:
    EventCategories.APP_PROFILING_PROFILING_DOWNLINE_SIZE,
  [ProfilingQuestionKeysEnum.PROFILING_RANK]: 'profiling_rank',
}

export const firstQuestion = ProfilingQuestionKeysEnum.INDUSTRY
export const answerLeader = 'leader'
