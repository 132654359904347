import React from 'react'
import { Trans } from 'react-i18next'
import { Icon, KingClearIcon } from 'boards-web-ui'

import useBoard from '@hooks/useBoard'
import useBoardActions from '@hooks/useBoardActions'
import useFeatureFlags from '@hooks/useFeatureFlags'
import usePaywall from '@hooks/usePaywall'
import { PaywallSourceEnum } from '@features/analytics/useAccountSubscriptionEvents'
import { useProfileInformation } from '@features/profile/hooks/useProfileInformation'
import useToolbarTranslations from '../hooks/useToolbarTranslations'

import { UpdatesTools } from '../../updates/UpdatesTools'
import { SimpleButton } from '../../../../ui/components/SimpleButton'
import ViewModeIndicator from './ViewModeIndicator'
import Share from '../../share/ShareButton'

import { ReactComponent as PersonBadgePlus } from '../icons/AddMembers.svg'
import { ReactComponent as KingIcon } from '../../../../icons/king.svg'

import styles from './BoardTools.module.css'

const BoardTools: React.FC = () => {
  const { board, boardId, isActiveBoard, isPageBoard } = useBoard()
  const { onActivate, limitReached } = useBoard({ boardId })
  const { viewOnlyLabelFeatureEnabledFeatureFlag } = useFeatureFlags()

  const {
    canAddMembers,
    canViewMessages,
    canEditBoard,
    canViewActivateBoardButton,
  } = useBoardActions()
  const { addMembers } = useToolbarTranslations()
  const { openPaywall } = usePaywall()
  const { isSubscribed } = useProfileInformation()

  const shouldShowViewPlansButton = !isSubscribed && !isPageBoard

  const showViewModeIndicator =
    !canEditBoard && isActiveBoard && viewOnlyLabelFeatureEnabledFeatureFlag

  return (
    <div className={styles.Root}>
      {isActiveBoard && shouldShowViewPlansButton && (
        <SimpleButton
          fitToContent
          primary
          onClick={() => openPaywall(PaywallSourceEnum.BOARD_HEADER)}
          className={styles.Button}
        >
          <KingIcon />
          &nbsp;
          <Trans i18nKey="paywall_upgrade" />
        </SimpleButton>
      )}

      {canViewActivateBoardButton && (
        <SimpleButton
          className={styles.Button}
          onClick={() => onActivate(PaywallSourceEnum.ACTIVATE_BOARD_SCREEN)}
          fitToContent
          primary
        >
          {limitReached ? (
            <>
              <Icon size="14px" color="inherit" style={{ marginTop: '-2px' }}>
                <KingClearIcon />
              </Icon>
              &nbsp;
            </>
          ) : undefined}
          <Trans i18nKey="inactive_board_activate" />
        </SimpleButton>
      )}

      {showViewModeIndicator && (
        <ViewModeIndicator
          className={!board?.receivedContent ? styles.Margin : ''}
        />
      )}

      {canAddMembers && (
        <Share
          trigger={
            <SimpleButton
              style={{ paddingRight: '12px', paddingLeft: '12px' }}
              secondary
              fitToContent
            >
              <PersonBadgePlus className={styles.Icon} />
              &nbsp;{addMembers()}
            </SimpleButton>
          }
        />
      )}

      {canViewMessages && <UpdatesTools />}
    </div>
  )
}

export default BoardTools
