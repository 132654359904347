import { TFunction } from 'i18next'
import { QuestionsProps, QuestionType } from 'boards-web-ui'
import { ProfilingQuestionKeysEnum } from '@features/profiling/models'
import {
  getBrandOptions,
  getIndustryOptions,
  getProfilingDownline,
  getProfilingDownlineHerbalife,
  getProfilingDownlineSize,
  getRankOptions,
  getTeamSizeOptions,
  getTimeInBusinessOptions,
  getTypeOptions,
} from './questionOptions'

const getQuestionsData = (translations: TFunction): QuestionsProps => {
  return {
    [ProfilingQuestionKeysEnum.INDUSTRY]: {
      type: QuestionType.SELECT,
      title: translations('user_profiling_question_industry'),
      options: getIndustryOptions(translations),
    },
    [ProfilingQuestionKeysEnum.BRAND_TEXT]: {
      type: QuestionType.TEXT,
      next: ProfilingQuestionKeysEnum.TYPE,
      title: translations(
        'profiling_industry_question_organization_name_title',
      ),
      placeholder: translations(
        'profiling_industry_question_organization_name_placeholder',
      ),
      options: [],
    },
    [ProfilingQuestionKeysEnum.BRAND_SELECT]: {
      type: QuestionType.SELECT,
      title: translations(
        'profiling_industry_question_organization_name_title',
      ),
      options: getBrandOptions(translations),
    },
    [ProfilingQuestionKeysEnum.BRAND_SELECT_TEXT]: {
      type: QuestionType.TEXT,
      next: ProfilingQuestionKeysEnum.PROFILING_DOWNLINE,
      title: translations(
        'profiling_industry_question_organization_name_title',
      ),

      placeholder: translations(
        'profiling_industry_question_organization_name_placeholder',
      ),

      options: [],
    },
    [ProfilingQuestionKeysEnum.PROFILING_RANK]: {
      type: QuestionType.SELECT,
      title: translations('profiling_rank_question'),
      options: getRankOptions(),
    },
    [ProfilingQuestionKeysEnum.TIME_IN_BUSINESS]: {
      type: QuestionType.SELECT,
      title: translations('profiling_time_in_bussines_title'),
      options: getTimeInBusinessOptions(translations),
    },
    [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE]: {
      type: QuestionType.SELECT,
      title: translations('profiling_downline'),
      options: getProfilingDownline(translations),
    },
    [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_HERBALIFE]: {
      type: QuestionType.SELECT,
      title: translations('profiling_downline'),
      options: getProfilingDownlineHerbalife(translations),
    },
    [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_SIZE]: {
      type: QuestionType.SELECT,
      title: translations('profiling_downline_size'),
      options: getProfilingDownlineSize(),
    },
    [ProfilingQuestionKeysEnum.TYPE]: {
      type: QuestionType.SELECT,
      title: translations('profiling_who_are_you_question_title'),
      options: getTypeOptions(translations),
    },
    [ProfilingQuestionKeysEnum.TEAM_SIZE]: {
      type: QuestionType.SELECT,
      title: translations('profiling_team_size_title'),
      options: getTeamSizeOptions(),
    },
  }
}

export default getQuestionsData
