import React, { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'

import useBoardsOrder from './hooks/useBoardsOrder'

import BoardsMenu from './components/BoardsMenu'
import CreateBoardTooltip from './components/CreateBoardTooltip'
import CreateBoardButton from './components/CreateBoardButton'
import EmptyActiveBoardsState from './components/EmptyActiveBoardsState'

export interface PaddleSdkProductPrice {
  price: {
    gross: string
  }
}

const Menu = forwardRef<HTMLDivElement>((_, ref) => {
  const { t } = useTranslation()

  const {
    boards,
    order,
    draggedTo,
    onDragStart,
    onDragOver,
    onDrop,
    onDragLeave,
    activeBoardsCount,
    inactiveBoardsCount,
  } = useBoardsOrder()

  return (
    <>
      {activeBoardsCount === 0 && inactiveBoardsCount > 0 && (
        <EmptyActiveBoardsState />
      )}

      {activeBoardsCount > 0 && (
        <div ref={ref}>
          <BoardsMenu
            boards={boards}
            order={order}
            draggedTo={draggedTo}
            onDragStart={onDragStart}
            onDragOver={onDragOver}
            onDrop={onDrop}
            onDragLeave={onDragLeave}
          />
        </div>
      )}

      <CreateBoardTooltip isOpen={order.length === 0}>
        <CreateBoardButton />
      </CreateBoardTooltip>
      <BoardsMenu
        title={inactiveBoardsCount ? t('board_section_inactive') : undefined}
        boards={boards}
        order={order}
        draggedTo={draggedTo}
        onDragStart={onDragStart}
        onDragOver={onDragOver}
        onDrop={onDrop}
        onDragLeave={onDragLeave}
        inactive
      />
    </>
  )
})

export default React.memo(Menu)
