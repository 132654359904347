import React, { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { CheckoutSmallIcon, Grid, MeduPdfIcon } from 'boards-web-ui'

import usePageEvents from '@features/analytics/usePageEvents'
import usePageEditorEvents from '@features/analytics/usePageEditorEvents'
import {
  ICreateOrUpdateNode,
  INodeProps,
} from '@features/nodeEditor/models/IUseNodeCreatorAPI'
import useFeatureFlags from '@hooks/useFeatureFlags'
import { SelectAction, useSourcePicker } from '@features/pickers'
import { usePagePremiumLimitationMode } from '@features/editorPages/hooks/usePagePremiumLimitationMode'
import {
  AnimatedArrowDirection,
  AnimatedArrows,
} from '../../../../components/AnimatedArrows'
import useAddNode from '../../nodeEditor/useAddNode'

import useUserFeatures, { Features } from '../../../../hooks/useUserFeatures'
import { useProfileInformation } from '../../profile/hooks/useProfileInformation'
import { Folder, NodeType } from '../../../models'
import Chip from '../../../../ui/components/Chip'

import { ReactComponent as CrownIcon } from '../../toolbar/icons/Crown.svg'
import { ReactComponent as HeadlineIcon } from '../icons/MenuHeadline.svg'
import { ReactComponent as TextIcon } from '../icons/MenuText.svg'
import { ReactComponent as ImageIcon } from '../icons/MenuImage.svg'
import { ReactComponent as MenuVideoIcon } from '../icons/MenuVideo.svg'
import { ReactComponent as MenuVideoStreamingIcon } from '../icons/MenuVideoStreaming.svg'
import { ReactComponent as MenuButtonIcon } from '../icons/MenuButton.svg'
import { ReactComponent as CopyContentIcon } from '../icons/CopyContent.svg'
import { ReactComponent as MenuFormIcon } from '../icons/MenuForm.svg'

import PageMenuItem from './PageMenuItem'
import styles from './PageMenu.module.css'

interface Props {
  folder: Folder
  createNode: ICreateOrUpdateNode
  updateNode: (id: string, node: INodeProps) => void
  selectAction: SelectAction
}

const PageMenu: FC<Props> = ({
  folder,
  createNode,
  updateNode,
  selectAction,
}) => {
  const { addPageContentMenuItemClick } = usePageEvents()
  const { checkoutFeatureEnabled } = useFeatureFlags()
  const { t } = useTranslation()
  const { tryFree, limitReached, enabled } = useUserFeatures(
    Features.uploadVideo,
  )

  const { isLimitedNode, getLimitationKeyIcon } = usePagePremiumLimitationMode()

  const { profile } = useProfileInformation()
  const { selectContent } = useSourcePicker(selectAction)
  const { viewInsertContentDialogEvent } = usePageEditorEvents()

  const {
    addButton,
    addImages,
    addVideo,
    addVideoStreaming,
    addHeadline,
    addParagraph,
    addForm,
    addCheckout,
    addPdf,
  } = useAddNode(folder?.id, createNode, updateNode, true)
  const showTryFreeBadge = tryFree && !limitReached

  const onClickContentFromMyBoards = () => {
    selectContent()
    viewInsertContentDialogEvent()
  }

  const handleClickPageMenu =
    (
      callback: () => void,
      type: NodeType,
      isTryFree = false,
      isBlocked = false,
    ) =>
    () => {
      callback()
      addPageContentMenuItemClick(type, isTryFree, isBlocked)
    }

  return (
    <div className={styles.Menu}>
      {folder.content.length === 0 && (
        <AnimatedArrows
          className={styles.Arrow}
          direction={AnimatedArrowDirection.left}
        />
      )}
      <PageMenuItem
        icon={<CopyContentIcon />}
        title={t('page_menu_content_from_my_boards')}
        onClick={onClickContentFromMyBoards}
      />
      <PageMenuItem
        icon={<HeadlineIcon />}
        title={t('page_text_headline')}
        onClick={handleClickPageMenu(addHeadline, NodeType.HEADLINE)}
      />
      <PageMenuItem
        icon={<TextIcon />}
        title={t('page_text_paragraph')}
        onClick={handleClickPageMenu(addParagraph, NodeType.PARAGRAPH)}
      />
      <PageMenuItem
        icon={<ImageIcon />}
        title={t('content_key_photo')}
        onClick={handleClickPageMenu(addImages, NodeType.IMAGE)}
      />
      <PageMenuItem
        icon={<MenuVideoStreamingIcon />}
        title={t('menu_upload_video')}
        chip={
          <Grid rowGap={5}>
            {(!enabled || tryFree) && <CrownIcon />}
            {showTryFreeBadge && (
              <Chip blue>
                <Trans i18nKey={'badge_try_free'} />
              </Chip>
            )}
            {!showTryFreeBadge && !profile.uiState.videoHintShown && (
              <Chip blue>
                <Trans i18nKey={'badge_new'} />
              </Chip>
            )}
          </Grid>
        }
        onClick={handleClickPageMenu(
          addVideoStreaming,
          NodeType.VIDEO_STREAMING,
          showTryFreeBadge,
          !enabled || tryFree,
        )}
      />
      <PageMenuItem
        icon={<MeduPdfIcon fontSize={17} />}
        title={t('content_key_pdf')}
        onClick={handleClickPageMenu(addPdf, NodeType.PDF)}
      />
      <PageMenuItem
        icon={<MenuVideoIcon />}
        title={t('youtube_vimeo')}
        onClick={handleClickPageMenu(addVideo, NodeType.VIDEO)}
      />
      <PageMenuItem
        icon={<MenuButtonIcon />}
        title={t('page_button')}
        chip={
          !profile.uiState.buttonsHintShown ? (
            <Chip blue>
              <Trans i18nKey={'badge_new'} />
            </Chip>
          ) : undefined
        }
        onClick={handleClickPageMenu(addButton, NodeType.BUTTON)}
      />
      <PageMenuItem
        icon={<MenuFormIcon />}
        title={t('label_form')}
        disabledStyleMenu={isLimitedNode(NodeType.FORM)}
        style={{
          fontSize: '18px',
        }}
        chip={
          <Grid rowGap={5}>
            {getLimitationKeyIcon(NodeType.FORM)}

            {!profile.uiState.formsHintShown ? (
              <Chip blue>
                <Trans i18nKey={'badge_new'} />
              </Chip>
            ) : undefined}
          </Grid>
        }
        onClick={handleClickPageMenu(
          addForm,
          NodeType.FORM,
          false,
          isLimitedNode(NodeType.FORM),
        )}
      />

      {checkoutFeatureEnabled ? (
        <PageMenuItem
          icon={<CheckoutSmallIcon />}
          title={t('label_feature_checkout')}
          disabledStyleMenu={isLimitedNode(NodeType.CHECKOUT)}
          style={{
            fontSize: '18px',
          }}
          chip={
            <Grid rowGap={5}>
              {getLimitationKeyIcon(NodeType.CHECKOUT)}

              {!profile.uiState.checkoutHintShown ? (
                <Chip blue>
                  <Trans i18nKey={'badge_new'} />
                </Chip>
              ) : undefined}
            </Grid>
          }
          onClick={handleClickPageMenu(
            addCheckout,
            NodeType.CHECKOUT,
            false,
            isLimitedNode(NodeType.CHECKOUT),
          )}
        />
      ) : null}
    </div>
  )
}

export default PageMenu
