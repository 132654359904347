import React, { FC, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import {
  Grid,
  InputField,
  InputRadio,
  KingBadgeIcon,
  Spacer,
  nextTick,
} from 'boards-web-ui'

import useFocusState from '@hooks/useFocusState'
import { AfterSubmitBehaviourEnum } from '@features/forms/constants'

import { useCheckout } from '../hooks'
import {
  CHECKOUT_POST_SUBMITTING_BEHAVIOUR,
  DEFAULT_POST_SUBMISSION_MESSAGE,
} from '../constants'

import styles from './CheckoutSettingsPostSubmissionInformation.module.css'

const CheckoutSettingsPostSubmissionInformation: FC = () => {
  const { t } = useTranslation()
  const inputRefs = {
    [CHECKOUT_POST_SUBMITTING_BEHAVIOUR.MESSAGE]:
      useRef<HTMLInputElement>(null),
    [CHECKOUT_POST_SUBMITTING_BEHAVIOUR.REDIRECT]:
      useRef<HTMLInputElement>(null),
  }

  const {
    isSubmitted,
    postSubmissionBehavior,
    postSubmissionMessage,
    postSubmissionError,
    postSubmissionUrl,
    advancedForms,
    onPostSubmissionBehaviourChange,
    onPostSubmissionMessageChange,
    onPostSubmissionUrlChange,
    onPaywall,
  } = useCheckout()

  const { isFocused, onBlur, ...focusHandlers } = useFocusState()

  const [postSubmissionUrlState, setPostSubmissionUrlState] = useState<string>(
    postSubmissionUrl || '',
  )
  const [postSubmissionMessageState, setPostSubmissionMessageState] =
    useState<string>(postSubmissionMessage || '')

  useEffect(() => {
    setPostSubmissionUrlState(postSubmissionUrl || '')
  }, [postSubmissionUrl])

  useEffect(() => {
    setPostSubmissionMessageState(postSubmissionMessage || '')
  }, [postSubmissionMessage])

  const onPostSubmissionBehaviourSwitch = async (
    behaviourType: AfterSubmitBehaviourEnum,
  ) => {
    onPostSubmissionBehaviourChange(behaviourType, advancedForms)
    await nextTick()
    inputRefs[behaviourType]?.current?.focus()
  }

  return (
    <div className={styles.Root}>
      <h3 className={styles.SectionTitle}>
        {t('forms_post_submission_title')}
        {!advancedForms && (
          <KingBadgeIcon className={styles.KingBadge} onClick={onPaywall} />
        )}
      </h3>

      <span className={styles.SectionSubTitle}>
        {t(
          'Reveal extra information or redirect after someone fills out the form.',
        )}
      </span>

      <Grid
        justifyContent="space-between"
        alignItems="flex-start"
        columnGap={10}
      >
        <InputRadio
          checked={
            postSubmissionBehavior ===
            CHECKOUT_POST_SUBMITTING_BEHAVIOUR.MESSAGE
          }
          onClick={() =>
            onPostSubmissionBehaviourSwitch(
              CHECKOUT_POST_SUBMITTING_BEHAVIOUR.MESSAGE,
            )
          }
        />
        <div className={styles.RadioInput}>
          <InputField
            blueVariant
            value={postSubmissionMessageState || ''}
            onChange={(e) => setPostSubmissionMessageState(e.target.value)}
            onBlur={(e) => {
              setPostSubmissionMessageState(
                e.target.value || t(DEFAULT_POST_SUBMISSION_MESSAGE),
              )
              onPostSubmissionMessageChange(e.target.value, advancedForms)
            }}
            onPointerDown={async () => {
              onPostSubmissionBehaviourChange(
                CHECKOUT_POST_SUBMITTING_BEHAVIOUR.MESSAGE,
                advancedForms,
              )
              await nextTick()
              inputRefs[
                CHECKOUT_POST_SUBMITTING_BEHAVIOUR.MESSAGE
              ]?.current?.focus()
            }}
            className={clsx(
              styles.Input,
              postSubmissionBehavior !==
                CHECKOUT_POST_SUBMITTING_BEHAVIOUR.MESSAGE && styles.BlurInput,
            )}
            ref={inputRefs[CHECKOUT_POST_SUBMITTING_BEHAVIOUR.MESSAGE]}
            label={t('forms_display_custom_message')}
            placeholder={t('forms_thanks_message_placeholder')}
            disabled={
              !advancedForms ||
              postSubmissionBehavior !==
                CHECKOUT_POST_SUBMITTING_BEHAVIOUR.MESSAGE
            }
          />
        </div>
      </Grid>

      <Spacer height={25} />

      <Grid
        justifyContent="space-between"
        alignItems="flex-start"
        columnGap={10}
      >
        <InputRadio
          checked={
            postSubmissionBehavior ===
            CHECKOUT_POST_SUBMITTING_BEHAVIOUR.REDIRECT
          }
          onClick={() =>
            onPostSubmissionBehaviourSwitch(
              CHECKOUT_POST_SUBMITTING_BEHAVIOUR.REDIRECT,
            )
          }
        />
        <div className={styles.RadioInput}>
          <InputField
            redVariant={isSubmitted && !isFocused && !!postSubmissionError}
            ref={inputRefs[CHECKOUT_POST_SUBMITTING_BEHAVIOUR.REDIRECT]}
            label={t('Redirect to a different page')}
            placeholder={t('button_link_button_placeholder')}
            disabled={
              postSubmissionBehavior !==
              CHECKOUT_POST_SUBMITTING_BEHAVIOUR.REDIRECT
            }
            errorMessageOnBlur={
              isSubmitted && !isFocused ? postSubmissionError : ''
            }
            value={postSubmissionUrlState || ''}
            onPointerDown={async () => {
              onPostSubmissionBehaviourChange(
                CHECKOUT_POST_SUBMITTING_BEHAVIOUR.REDIRECT,
                advancedForms,
              )
              await nextTick()
              inputRefs[
                CHECKOUT_POST_SUBMITTING_BEHAVIOUR.REDIRECT
              ]?.current?.focus()
            }}
            onChange={(e) => setPostSubmissionUrlState(e.target.value)}
            onBlur={(e) => {
              onBlur()
              if (e.target.value !== postSubmissionUrl) {
                onPostSubmissionUrlChange(e.target.value, advancedForms)
              }
            }}
            className={[
              styles.Input,
              postSubmissionBehavior !==
              CHECKOUT_POST_SUBMITTING_BEHAVIOUR.REDIRECT
                ? styles.BlurInput
                : '',
            ].join(' ')}
            {...focusHandlers}
          />
        </div>
      </Grid>
    </div>
  )
}

export default React.memo(CheckoutSettingsPostSubmissionInformation)
