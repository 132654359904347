import { FC } from 'react'
import { Node, NodeType } from '../../models'

import NodePdf from './pageNodes/NodePdf'
import NodeHeadline from './pageNodes/NodeHeadline'
import NodeParagraph from './pageNodes/NodeParagraph'
import NodeButton from './pageNodes/NodeButton'
import NodeImage from './pageNodes/NodeImage'
import NodeVideo from './pageNodes/NodeVideo'
import NodeCheckoutForm from './pageNodes/NodeCheckoutForm'
import NodeAudio from '../board/components/folderNodes/NodeAudio'
import NodeForm from './pageNodes/NodeForm'

import styles from './PageNode.module.css'

interface Props {
  node: Node
}

const PageNode: FC<Props> = ({ node }) => {
  const { type, title, thumbnail } = node

  return (
    <>
      {[NodeType.HEADLINE, NodeType.TEXT].includes(type) && (
        <NodeHeadline node={node} />
      )}
      {type === NodeType.PARAGRAPH && <NodeParagraph node={node} />}
      {type === NodeType.IMAGE && <NodeImage node={node} />}
      {(type === NodeType.VIDEO || type === NodeType.VIDEO_STREAMING) && (
        <NodeVideo node={node} />
      )}
      {type === NodeType.BUTTON && <NodeButton node={node} />}
      {type === NodeType.FORM && <NodeForm node={node} />}
      {type === NodeType.CHECKOUT && <NodeCheckoutForm node={node} />}
      {type === NodeType.PDF && <NodePdf title={title} thumbnail={thumbnail} />}
      {type === NodeType.AUDIO && (
        <NodeAudio node={node} className={styles.WithoutBorder} />
      )}
    </>
  )
}

export default PageNode
