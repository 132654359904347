import React, { FC, memo } from 'react'

import { GoogleSheetConnect } from '@features/googleSpreadsheet'

import { useCheckout } from '../hooks'

const CheckoutGoogleSheetsConnector: FC = () => {
  const {
    isSubmitted,
    isOwn,
    isGoogleAccountConnected,
    spreadsheetURL,
    googleSheetResponseError,
    onGoogleSheetsUrlChange,
    onConnectAccountToGoogleSheets,
    isGoogleAccountConnectedPending,
  } = useCheckout()

  return (
    <GoogleSheetConnect
      isOwn={isOwn}
      isGoogleAccountConnectedPending={isGoogleAccountConnectedPending}
      spreadsheetURL={spreadsheetURL}
      googleSheetResponseError={isSubmitted ? googleSheetResponseError : ''}
      isGoogleAccountConnected={isGoogleAccountConnected}
      onConnectAccountToGoogleSheets={onConnectAccountToGoogleSheets}
      onGoogleSheetsUrlChange={onGoogleSheetsUrlChange}
    />
  )
}

export default memo(CheckoutGoogleSheetsConnector)
