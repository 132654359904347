import { ButtonNew, KingIcon } from 'boards-web-ui'
import { Trans } from 'react-i18next'
import usePaywall from '@hooks/usePaywall'
import { PaywallSourceEnum } from '@features/analytics/useAccountSubscriptionEvents'
import { FC, useCallback, useEffect } from 'react'
import clsx from 'clsx'
import { useFormsCheckoutPaidPlansEvents } from '@features/analytics/useFormsCheckoutPaidPlansEvents'
import styles from './PremiumBanner.module.css'

interface Props {
  isPagePreview?: boolean
  isEmpty?: boolean
}

export const PremiumBanner: FC<Props> = ({ isPagePreview, isEmpty }) => {
  const { openPaywall } = usePaywall()
  const { appPageUnsubscribedBannerView, appPageUnsubscribedBannerClick } =
    useFormsCheckoutPaidPlansEvents()

  const eventSource = isPagePreview
    ? PaywallSourceEnum.PAGE_PREVIEW
    : PaywallSourceEnum.PAGE_EDITOR

  const handleBannerClick = useCallback(() => {
    appPageUnsubscribedBannerClick(eventSource)
    openPaywall(eventSource)
  }, [appPageUnsubscribedBannerClick, eventSource, openPaywall])

  useEffect(() => {
    appPageUnsubscribedBannerView(eventSource)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div
      className={clsx(
        styles.Root,
        isPagePreview && styles.PagePreview,
        isEmpty && styles.isEmpty,
      )}
    >
      <span className={styles.UpgradeText}>
        <Trans i18nKey={'page_unsubscribed_banner_text'} />
      </span>

      <ButtonNew
        color={'primary'}
        size={'medium'}
        startIcon={<KingIcon />}
        onClick={(event) => {
          event.stopPropagation()
          event.preventDefault()

          handleBannerClick()
        }}
      >
        <Trans i18nKey={'action_upgrade'} />
      </ButtonNew>
    </div>
  )
}
