import { useDispatch } from 'react-redux'

import { generateNodeId } from '@helpers/NodeIdGenerator'

import { contentActions } from '../../../../actions'
import useIsApiAvailableOrNotify from '../../../../hooks/useIsApiAvailableOrNotify'

import { INodeProps } from '../models/IUseNodeCreatorAPI'

const useNodeCreatorAPI = (boardId: string, folderId: string) => {
  const dispatch = useDispatch()
  const isApiAvailableOrNotify = useIsApiAvailableOrNotify()

  const create = (node: INodeProps) => {
    if (!isApiAvailableOrNotify()) return false
    const updatedNode = {
      ...node,
      title: node.title?.trim(),
      text: node.text?.trim(),
    }

    const { nodeId, key } = generateNodeId()

    dispatch(contentActions.addContent(boardId, folderId, key, updatedNode))

    return nodeId
  }

  return create
}

export default useNodeCreatorAPI
