import React, { memo, FC } from 'react'
import {
  ButtonNew,
  Icon,
  InformationIcon,
  PaypalIcon,
  SpinnerIcon,
} from 'boards-web-ui'
import { useTranslation } from 'react-i18next'

import useBoard from '@hooks/useBoard'

import styles from './ConnectPayPalButton.module.css'

type Props = {
  isPayPalConnectionPending: boolean
  isPaypalFormConnected?: boolean
  errorMessage?: string
  onConnect?: () => void
}
const ConnectPayPalButton: FC<Props> = ({
  isPayPalConnectionPending,
  isPaypalFormConnected,
  errorMessage,
  onConnect,
}) => {
  const { t } = useTranslation()
  const { isOwnBoard } = useBoard()

  if (!isOwnBoard) {
    return (
      <p className={styles.OnlyOwn}>
        <Icon className={styles.InfoIcon}>
          <InformationIcon />
        </Icon>
        {t('only_owners_connect_payment_provider')}
      </p>
    )
  }

  if (isPaypalFormConnected) {
    return (
      <div className={styles.Connected}>
        <Icon className={styles.Icon}>
          <PaypalIcon />
        </Icon>
        <div className={styles.IndicatorWrap}>
          <span>{t('button_paypal_label')}</span>
          <span className={styles.Indicator}>{t('label_connected')}</span>
        </div>
      </div>
    )
  }

  return (
    <div className={errorMessage ? '' : styles.ButtonOverrider}>
      <ButtonNew
        size="xl"
        errorMessage={errorMessage}
        fullWidth
        color={errorMessage ? 'red' : 'primary'}
        onClick={onConnect}
      >
        <Icon size="24px" className={styles.PaypalIcon}>
          <PaypalIcon />
        </Icon>
        &nbsp;&nbsp;
        {t('action_connect_paypal')}
        {isPayPalConnectionPending ? (
          <Icon size="24px" className={styles.SpinnerIcon}>
            <SpinnerIcon />
          </Icon>
        ) : null}
      </ButtonNew>
    </div>
  )
}

export default memo(ConnectPayPalButton)
