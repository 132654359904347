import React from 'react'

import useBoard from '../../../../hooks/useBoard'
import useToolbarEvents from '../../analytics/useToolbarEvents'
import useToolbarTranslations from '../hooks/useToolbarTranslations'

import Tooltip, { TooltipContent } from '../../../../ui/components/Tooltip'
import { SimpleButton } from '../../../../ui/components/SimpleButton'
import { ReactComponent as ViewOnlyIcon } from '../icons/Eye.svg'

import styles from './ViewModeIndicator.module.css'

type Props = {
  className?: string
}
const ViewModeIndicator: React.FC<Props> = ({ className }) => {
  const { boardId } = useBoard()
  const { viewModeBadgeClick } = useToolbarEvents()
  const { viewMode, canEditViewModeTooltip } = useToolbarTranslations()

  return (
    <Tooltip
      as="div"
      className={className}
      data={
        <TooltipContent className={styles.Offset}>
          {canEditViewModeTooltip()}
        </TooltipContent>
      }
    >
      <SimpleButton
        fitToContent
        disabledOnHover
        onClick={() => viewModeBadgeClick(boardId)}
      >
        <ViewOnlyIcon className={styles.Icon} />
        &nbsp;{viewMode()}
      </SimpleButton>
    </Tooltip>
  )
}

export default ViewModeIndicator
