import { FC, useRef } from 'react'
import { Trans } from 'react-i18next'
import { useResizeObserver } from 'usehooks-ts'

import { ScreenNames } from '@features/analytics/useNodeEvents'

import ScreenFrame from './ScreenFrame'
import PageNodes from '../../nodes/PageNodes'
import { Folder } from '../../../models'

import styles from './PagePreview.module.css'

const EmptyState = () => (
  <div className={styles.EmptyState}>
    <Trans i18nKey={'page_builder_preview_empty_state'} />
  </div>
)

interface Props {
  folder: Folder
}
const PagePreview: FC<Props> = ({ folder }) => {
  const ref = useRef<HTMLDivElement>(null)
  const { height = 0 } = useResizeObserver({
    ref,
  })

  const renderPageNodes = () => {
    if (folder.content.length === 0) {
      return <EmptyState />
    }

    return (
      <PageNodes nodes={folder.content} screen={ScreenNames.PAGE_PREVIEW_TAB} />
    )
  }

  return (
    <div ref={ref} className={styles.Scroll}>
      <div className={styles.Root}>
        <ScreenFrame
          folder={folder}
          height={height}
          maxHeight={800}
          minHeight={350}
          screenHeight={800}
        >
          {renderPageNodes()}
        </ScreenFrame>
      </div>
    </div>
  )
}

export default PagePreview
