import React, { FC, PropsWithChildren, ReactNode, useCallback } from 'react'
import {
  BackIcon,
  ButtonIcon,
  CloseIcon,
  Tooltip,
  TooltipContent,
} from 'boards-web-ui'
import { Trans } from 'react-i18next'
import VerticalScrollShadow from '@features/insights/components/VerticalScrollShadow'

import { ContentPickersEnum, useContentNavigation } from '@features/pickers'
import styles from './PickerModal.module.css'

type TProps = PropsWithChildren<{
  onClose: () => void
  header?: ReactNode
  footer?: ReactNode
  hideCloseButton?: boolean
  onTopCallBack?: () => void
  picker: ContentPickersEnum
  onBeforeGoBack?: (callback: () => void) => void
  scrollKey?: string
}>

export const PickerModal: FC<TProps> = ({
  children,
  onClose,
  header,
  footer,
  hideCloseButton,
  onTopCallBack,
  picker,
  onBeforeGoBack,
  scrollKey,
}) => {
  const { goBack } = useContentNavigation(picker)

  const handleGoBack = useCallback(() => {
    if (!goBack) {
      return
    }

    if (onBeforeGoBack) {
      onBeforeGoBack(goBack)
      return
    }

    goBack()
  }, [goBack, onBeforeGoBack])

  return (
    <div className={styles.Root}>
      <div className={styles.Header}>
        {goBack ? (
          <Tooltip
            className={styles.BackButton}
            data={
              <TooltipContent>
                <Trans i18nKey={'action_back'} />
              </TooltipContent>
            }
          >
            <ButtonIcon size={38} variant="gray" onClick={handleGoBack} round>
              <BackIcon />
            </ButtonIcon>
          </Tooltip>
        ) : null}

        {header}

        {!hideCloseButton && (
          <Tooltip
            className={styles.CloseButton}
            data={
              <TooltipContent>
                <Trans i18nKey={'action_close'} />
              </TooltipContent>
            }
          >
            <ButtonIcon size={38} round variant="gray" onClick={onClose}>
              <CloseIcon />
            </ButtonIcon>
          </Tooltip>
        )}
      </div>

      <div className={styles.Body}>
        <VerticalScrollShadow
          key={scrollKey}
          className={styles.Scroll}
          onTopCallBack={onTopCallBack}
        >
          {children}
        </VerticalScrollShadow>
      </div>

      {footer ? <div className={styles.Footer}>{footer}</div> : null}
    </div>
  )
}
