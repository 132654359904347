/* eslint no-underscore-dangle: 0 */
/* eslint-disable @typescript-eslint/no-explicit-any */

export interface IUserProperties {
  topic_1?: boolean
  topic_2?: boolean
  topic_3?: boolean
  connected_to_paypal?: boolean
  connected_to_google_sheet?: boolean
  profiling_rank?: string
}

export interface CustomerIoIdentify {
  id: string
  email: string
  user_id: string
  first_name?: string
  os_language?: string
  max_boards_members?: number
  sponsored_boards?: number
  operating_system?: string
}

const identifyUser = <T>(identity: T) => {
  const _cio = (window as any)._cio || []
  ;(window as any)._cio = _cio

  _cio.identify(identity)
}

const track = (eventName: string, eventData: any) => {
  const _cio = (window as any)._cio || []
  ;(window as any)._cio = _cio

  _cio.track(eventName, eventData)
}

const CustomerIo = {
  identifyUser,
  track,
}

export default CustomerIo
