export enum REMOTE_CONFIG_KEYS {
  INSIGHTS_ENABLED = 'insights_enabled',
  INSIGHTS_DATA_AVAILABLE_DATE = 'insights_data_available_date',
  UPDATES_ENABLED = 'updates_enabled',
  CO_EDITING_ENABLED = 'co_editing_enabled',
  WEB_USER_PROFILING_ENABLED = 'web_user_profiling_enabled',
  SHARED_FOLDER_ENABLED = 'share_folder_enabled',
  PAGE_BUILDER_ENABLED = 'page_builder_enabled',
  MONETIZATION_BANNER_ENABLED = 'monetization_banner_enabled',
  GEO_MONETIZATION_BANNER_ENABLED = 'geo_monetization_banner_enabled',
  TEST_COUNTRIES_WITH_SINGLE_TIER = 'test_countries_with_single_tier',
  UPLOAD_VIDEO_ENABLED = 'upload_video_enabled',
  PHONE_VERIFICATION_OPTIONS = 'phone_verification_options',
  GOOGLE_SHEETS_FEATURE_ENABLED = 'google_sheets_feature_enabled_v2',
  CHECKOUT_FEATURE_ENABLED = 'checkout_feature_enabled',
  VIEW_ONLY_LABEL_ENABLED = 'view_only_label_enabled',
}

export enum VERIFICATION_OPTIONS {
  DISABLED = 'disabled',
  SKIP_ALLOWED = 'enabled_skip_allowed',
  SKIP_NOT_ALLOWED = 'enabled_skip_not_allowed',
}

export type RemoteConfigType = {
  [REMOTE_CONFIG_KEYS.INSIGHTS_ENABLED]: boolean
  [REMOTE_CONFIG_KEYS.INSIGHTS_DATA_AVAILABLE_DATE]: number
  [REMOTE_CONFIG_KEYS.UPDATES_ENABLED]: boolean
  [REMOTE_CONFIG_KEYS.CO_EDITING_ENABLED]: boolean
  [REMOTE_CONFIG_KEYS.SHARED_FOLDER_ENABLED]: boolean
  [REMOTE_CONFIG_KEYS.PAGE_BUILDER_ENABLED]: boolean
  [REMOTE_CONFIG_KEYS.MONETIZATION_BANNER_ENABLED]: boolean
  [REMOTE_CONFIG_KEYS.GEO_MONETIZATION_BANNER_ENABLED]: boolean
  [REMOTE_CONFIG_KEYS.TEST_COUNTRIES_WITH_SINGLE_TIER]: string
  [REMOTE_CONFIG_KEYS.UPLOAD_VIDEO_ENABLED]: boolean
  [REMOTE_CONFIG_KEYS.PHONE_VERIFICATION_OPTIONS]: VERIFICATION_OPTIONS
  [REMOTE_CONFIG_KEYS.GOOGLE_SHEETS_FEATURE_ENABLED]: boolean
  [REMOTE_CONFIG_KEYS.CHECKOUT_FEATURE_ENABLED]: boolean
  [REMOTE_CONFIG_KEYS.VIEW_ONLY_LABEL_ENABLED]: boolean
}

export const REMOTE_CONFIG_DEFAULT_VALUE: RemoteConfigType = Object.freeze({
  [REMOTE_CONFIG_KEYS.INSIGHTS_ENABLED]: false,
  [REMOTE_CONFIG_KEYS.UPDATES_ENABLED]: false,
  [REMOTE_CONFIG_KEYS.CO_EDITING_ENABLED]: false,
  [REMOTE_CONFIG_KEYS.WEB_USER_PROFILING_ENABLED]: false,
  [REMOTE_CONFIG_KEYS.INSIGHTS_DATA_AVAILABLE_DATE]: 1641038400,
  [REMOTE_CONFIG_KEYS.SHARED_FOLDER_ENABLED]: false,
  [REMOTE_CONFIG_KEYS.PAGE_BUILDER_ENABLED]: false,
  [REMOTE_CONFIG_KEYS.MONETIZATION_BANNER_ENABLED]: false,
  [REMOTE_CONFIG_KEYS.GEO_MONETIZATION_BANNER_ENABLED]: false,
  [REMOTE_CONFIG_KEYS.TEST_COUNTRIES_WITH_SINGLE_TIER]: '',
  [REMOTE_CONFIG_KEYS.UPLOAD_VIDEO_ENABLED]: true,
  [REMOTE_CONFIG_KEYS.PHONE_VERIFICATION_OPTIONS]:
    VERIFICATION_OPTIONS.DISABLED,
  [REMOTE_CONFIG_KEYS.GOOGLE_SHEETS_FEATURE_ENABLED]: false,
  [REMOTE_CONFIG_KEYS.CHECKOUT_FEATURE_ENABLED]: false,
  [REMOTE_CONFIG_KEYS.VIEW_ONLY_LABEL_ENABLED]: false,
})
