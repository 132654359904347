import { useCallback, useState } from 'react'

import {
  FormIntegrationStatusData,
  getFormIntegrationStatus,
} from '../../../../_firebase'

type Props = {
  boardId: string
  pageId: string
  formId: string
}
const useFormIntegrationStatusState = ({ boardId, pageId, formId }: Props) => {
  const [integrationStatusState, setIntegrationStatusState] =
    useState<FormIntegrationStatusData | null>(null)

  const onGetFormIntegrationStatus = useCallback(
    async (isCheckout?: boolean) => {
      try {
        const result = await getFormIntegrationStatus(
          boardId,
          pageId,
          formId,
          isCheckout,
        )
        if (result) {
          setIntegrationStatusState(result)
        }
      } catch (e) {
        setIntegrationStatusState(null)
      }
    },
    [setIntegrationStatusState, boardId, pageId, formId],
  )

  return {
    integrationStatusState,
    onGetFormIntegrationStatus,
  } as const
}

export default useFormIntegrationStatusState
