import React, { ReactNode } from 'react'
import { Trans } from 'react-i18next'
import clsx from 'clsx'

import { ButtonNew } from 'boards-web-ui'

import { SimpleDialog } from './SimpleDialog'

import styles from './ConfirmDialog.module.css'

type Props = {
  title: string
  message?: ReactNode | string
  cancelText?: string
  confirmText: string
  stacked?: boolean
  onCancel?: () => void
  onConfirm: () => void
}
const ConfirmDialog: React.FC<Props> = ({
  title,
  message,
  cancelText,
  confirmText,
  stacked,
  onCancel,
  onConfirm,
}) => {
  return (
    <SimpleDialog
      className={styles.Root}
      title={title}
      message={
        message ? (
          <p className={styles.Centred}>
            <Trans>{message}</Trans>
          </p>
        ) : undefined
      }
    >
      <div className={clsx(styles.Controls, stacked && styles.StackedControls)}>
        {cancelText && (
          <ButtonNew
            color="secondary"
            size="large"
            onClick={onCancel}
            fullWidth={stacked}
          >
            {cancelText}
          </ButtonNew>
        )}
        <ButtonNew
          color="primary"
          size="large"
          onClick={onConfirm}
          fullWidth={stacked}
        >
          {confirmText}
        </ButtonNew>
      </div>
    </SimpleDialog>
  )
}

export default ConfirmDialog
