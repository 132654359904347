import React, { SyntheticEvent, useEffect, FC, memo } from 'react'

import { useUpdatesTranslations } from '../hooks/useUpdatesTranslations'
import { useUpdatesEvents } from '../../analytics/useUpdatesEvents'
import { useUpdatesTools } from '../hooks/useUpdatesTools'
import useBoards from '../../../../hooks/useBoards'

import { useUpdatesMessages } from '../hooks/useUpdatesMessages'
import { useUpdatesApi } from '../hooks/useUpdatesApi'

import UpdatesMessage from './UpdatesMessage'
import styles from './UpdatesMessages.module.css'

type Props = {
  className?: string

  onSelectMessage: () => void
}

const UpdatesMessages: FC<Props> = ({ onSelectMessage, className }) => {
  const { count } = useBoards()
  const { messages, unReadMessages } = useUpdatesMessages()
  const { selectMessage } = useUpdatesApi()
  const { previewUpdateClick, previewUpdatesAll, updatesPaneOpen } =
    useUpdatesEvents()
  const { previousUpdatesTitle } = useUpdatesTranslations()
  const { isOpen } = useUpdatesTools()

  useEffect(() => {
    if (isOpen) {
      previewUpdatesAll(messages.length, count, unReadMessages.length)
      updatesPaneOpen(messages.length)
    }
    // disable esLint because it should trigger only once on isOpen state is equaled to ture
    // eslint-disable-next-line
  }, [isOpen])

  if (!messages || messages.length === 0) {
    return null
  }

  return (
    <section className={[styles.Root, className].join(' ')}>
      <h3 className={styles.Header}>{previousUpdatesTitle()}</h3>

      {messages.map((message) => (
        <UpdatesMessage
          presentationMode
          message={message}
          key={message.id}
          onClick={(event: SyntheticEvent) => {
            if ((event.target as HTMLElement).tagName.toLowerCase() === 'a') {
              return
            }

            onSelectMessage()
            previewUpdateClick()
            selectMessage(message)
          }}
        />
      ))}
    </section>
  )
}

export default memo(UpdatesMessages)
