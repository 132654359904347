import React, { FC } from 'react'

import { CampaignBanners } from '@features/campaign'

import useSidebarBanners from '../hooks/useSidebarBanners'
import MonetizationBanner from './MonetizationBanner'
import ChromeExtensionBanner from './ChromeExtensionBanner'
import MobileBanner from './MobileBanner'

type Props = {
  setOpen: (value: boolean) => void
}
const SideBarBanners: FC<Props> = ({ setOpen }) => {
  const { loaded, uiState, hasAndroidOrIOS } = useSidebarBanners()

  return (
    <div>
      <CampaignBanners />
      <MonetizationBanner />
      {loaded && (
        <ChromeExtensionBanner uiState={uiState.chromeExtensionBannerShown} />
      )}
      {loaded && (
        <MobileBanner
          setOpen={setOpen}
          hasAndroidOrIOS={hasAndroidOrIOS}
          uiState={uiState.installMobileAppHintShown}
        />
      )}
    </div>
  )
}

export default React.memo(SideBarBanners)
