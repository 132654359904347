import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useWindowURLTracking from '@hooks/useWindowUrlChangeTraker'
import useCheckoutAnalytics from '@features/analytics/useCheckoutAnalytics'

type Props = {
  paymentProviders: Array<string>
  isPayPalAccountConnected: boolean
  authorizationUrl?: string
}
const usePaymentProviders = ({
  paymentProviders,
  isPayPalAccountConnected,
  authorizationUrl,
}: Props) => {
  const closeIntegrationWindowRef = useRef<() => void>()
  const { t } = useTranslation()
  const { appCheckoutConnectPaypalClick, appPaypalIntegrationSuccess } =
    useCheckoutAnalytics()
  const { openWindow } = useWindowURLTracking()

  const [paymentProvidersState, setPaymentProvidersState] =
    useState<Array<string>>(paymentProviders)
  const [isPayPalConnectionPending, setIsPayPalConnectionPending] =
    useState<boolean>(false)

  const payPalIntegrationError: string = useMemo(() => {
    if (!paymentProvidersState.includes('paypal')) {
      return t('info_missing_connect_payment_provider')
    }

    return ''
  }, [paymentProvidersState, t])

  const isPaypalFormConnected = useMemo(() => {
    return isPayPalAccountConnected && paymentProvidersState.includes('paypal')
  }, [paymentProvidersState, isPayPalAccountConnected])

  useEffect(() => {
    if (
      closeIntegrationWindowRef.current &&
      isPayPalAccountConnected &&
      !paymentProvidersState.includes('paypal')
    ) {
      setPaymentProvidersState(['paypal'])

      if (closeIntegrationWindowRef.current) {
        closeIntegrationWindowRef.current()
      }

      setIsPayPalConnectionPending(false)
      appPaypalIntegrationSuccess()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isPayPalAccountConnected,
    paymentProvidersState,
    closeIntegrationWindowRef,
  ])

  const onPaypalConnect = useCallback(() => {
    appCheckoutConnectPaypalClick(isPayPalAccountConnected)

    if (isPayPalAccountConnected && !paymentProvidersState.includes('paypal')) {
      setPaymentProvidersState(['paypal'])
    }

    if (!authorizationUrl) {
      return
    }

    setIsPayPalConnectionPending(true)
    closeIntegrationWindowRef.current = openWindow(authorizationUrl)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isPayPalAccountConnected,
    paymentProvidersState,
    authorizationUrl,
    openWindow,
  ])

  return {
    paymentProviders: paymentProvidersState,
    isPaypalFormConnected,
    payPalIntegrationError,
    onPaypalConnect,
    isPayPalConnectionPending,
  } as const
}

export default usePaymentProviders
