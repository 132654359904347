import React, { useCallback, useState } from 'react'
import { PagesBoardIcon } from 'boards-web-ui'

import { TooltipPositions } from '@ui/models/tooltip'
import { DropDown } from '../../../../ui/components/DropDown'
import EmojiPicker from '../../../../components/EmojiPicker'
import DisableStateTooltip from '../../../../ui/components/DisableStateTooltip'
import { SimpleButton } from '../../../../ui/components/SimpleButton'

import styles from './ContentIcon.module.css'

type Props = {
  icon: string
  tooltip: string
  onChange: (icon: string) => void
  className?: string
  readOnly?: boolean
  isPageBoard?: boolean
  closeOnOutsideClick?: boolean
  tooltipPosition?: TooltipPositions
}
const ContentIcon: React.FC<Props> = ({
  icon,
  tooltip,
  className,
  onChange,
  readOnly,
  isPageBoard,
  closeOnOutsideClick,
  tooltipPosition,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const handleChange = (newIcon: string) => {
    setIsOpen(false)

    if (onChange) {
      onChange(newIcon)
    }
  }

  const handleClickOutside = useCallback(() => {
    if (closeOnOutsideClick) {
      setIsOpen(false)
    }
  }, [closeOnOutsideClick])

  return (
    <DropDown
      isOpen={isOpen}
      onToggle={setIsOpen}
      content={
        <EmojiPicker
          onClickEmoji={handleChange}
          onClickOutside={handleClickOutside}
        />
      }
      className={styles.Margin}
      disabled={!!readOnly || isPageBoard}
    >
      <DisableStateTooltip
        isOpen={isOpen ? false : undefined}
        data={tooltip}
        disabled={isOpen || !!readOnly || !!isPageBoard}
        position={tooltipPosition}
      >
        <SimpleButton
          lightGray
          mediumBtnIcon
          centered={isPageBoard}
          fitToContent={!isPageBoard}
          className={className}
          disabled={!!readOnly || isPageBoard}
        >
          {isPageBoard ? <PagesBoardIcon /> : icon}
        </SimpleButton>
      </DisableStateTooltip>
    </DropDown>
  )
}

export default React.memo(ContentIcon)
