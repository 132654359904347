import { useState } from 'react'

const useShare = () => {
  const [emails, setEmails] = useState<string[]>([])

  const addEmail = (email: string) => {
    setEmails((prev) => [...prev, email])
  }

  const removeEmailByIndex = (index: number) =>
    setEmails((prevEmails) => prevEmails.filter((_, i) => i !== index))

  return {
    emails,
    addEmail,
    removeEmailByIndex,
  } as const
}

export default useShare
