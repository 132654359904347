import React, { FC, useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { ButtonNew, Icon, KingClearIcon } from 'boards-web-ui'

import { useLogEvents } from '@features/analytics/useLogEvents'
import usePaywall from '@hooks/usePaywall'
import { ViewSource } from '@features/analytics/models/sourceEvents'
import { PaywallSourceEnum } from '@features/analytics/useAccountSubscriptionEvents'

import ModalWithEmojiHeader from 'ui/components/ModalWithEmojiHeader'
import CloseButtonWithTooltip from 'ui/components/CloseButtonWithTooltip'

import styles from './BoardsLimitReached.module.css'

type Props = {
  limit: string
  onCancel: () => void
  source: ViewSource
}
const BoardsLimitReached: FC<Props> = ({ limit, onCancel, source }) => {
  const { trigger } = useLogEvents()
  const { t } = useTranslation()
  const { openPaywall } = usePaywall()

  useEffect(() => {
    trigger('app_limit_reached_popup_view', { source })
    // eslint-disable-next-line
  }, [])

  const onUpgradeClick = () => {
    trigger('app_limit_reached_popup_click')

    openPaywall(PaywallSourceEnum.BOARD_LIMIT_REACHED)
  }

  const onCancelClick = () => {
    trigger('app_limit_reached_popup_dismiss', { source })
    onCancel()
  }

  return (
    <ModalWithEmojiHeader
      emoji="🚩"
      close={<CloseButtonWithTooltip onClose={onCancelClick} />}
      actions={
        <div className={styles.Actions}>
          <ButtonNew color="primary" size="large" onClick={onUpgradeClick}>
            <Icon size="18px" color="inherit">
              <KingClearIcon />
            </Icon>
            &nbsp;&nbsp;
            <Trans i18nKey="limit_reached_button" />
          </ButtonNew>
        </div>
      }
    >
      <h3 className={styles.Title}>
        <Trans i18nKey="limit_reached_title" />
      </h3>
      {t('limit_reached_body')
        ?.replaceAll('%@', limit)
        ?.split('\n\n')
        ?.map((p) => (
          <p key={p.trim()} className={styles.P}>
            {p}
          </p>
        ))}
    </ModalWithEmojiHeader>
  )
}

export default React.memo(BoardsLimitReached)
