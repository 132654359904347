import { UserProfile } from '@models/UserProfile'

export const enum ACTION_TYPE {
  UPDATE_PROFILE_INFO = 'UPDATE_PROFILE_INFO',
  SYNC_PROFILE_INFO = 'SYNC_PROFILE_INFO',
}

const updateProfileInformation = (info: UserProfile) => ({
  type: ACTION_TYPE.UPDATE_PROFILE_INFO,
  info,
})

const syncProfileInformation = (info: UserProfile) => ({
  type: ACTION_TYPE.SYNC_PROFILE_INFO,
  info,
})

const actions = {
  updateProfileInformation,
  syncProfileInformation,
} as const

export default actions
