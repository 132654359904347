import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TNodeForm, NodeType, useDialog, FormFields } from 'boards-web-ui'

import {
  AfterSubmitBehaviourEnum,
  FORM_FIELDS_ALLOW_TO_CHANGE,
} from '@features/forms/constants'
import type {
  ICreateOrUpdateNode,
  INodeProps,
} from '@features/nodeEditor/models/IUseNodeCreatorAPI'
import FormsSubmitWarning from '@features/checkout/components/FormsSubmitWarning'
import styles from '@features/checkout/components/CheckoutProviderWarning.module.css'
import { responseRequestAdapter } from '@features/checkout/utils/postCheckoutRequestAdapter'
import useNodeDiscardChanges from '@features/nodeEditor/hooks/useNodeDiscardChanges'
import { CHECKOUT_POST_SUBMITTING_BEHAVIOUR } from '@features/checkout/constants'

import { isEqualObjects } from '../../../../utils/isEqual'

type Props = {
  initialForm?: TNodeForm
  title: string
  action?: string
  postSubmissionBehavior: AfterSubmitBehaviourEnum
  postSubmissionMessage: string
  postSubmissionUrl: string
  postSubmissionError: string
  formsFields: FormFields
  encryptedEmail: string
  encryptedSpreadsheetURL: string
  email?: string
  responsesEmailError: string
  spreadsheetURL?: string
  isGoogleAccountConnected?: boolean
  onSave: ICreateOrUpdateNode
  onCancel: () => void
  initialEmail?: string
  initialSpreadsheetURL?: string
  googleSheetResponseError?: string
}
const useFormApi = ({
  initialForm,
  title,
  action,
  postSubmissionBehavior,
  postSubmissionMessage,
  postSubmissionUrl,
  postSubmissionError,
  formsFields,
  encryptedEmail,
  encryptedSpreadsheetURL,
  email,
  responsesEmailError,
  isGoogleAccountConnected,
  spreadsheetURL,
  onSave,
  onCancel,
  initialEmail,
  initialSpreadsheetURL,
  googleSheetResponseError,
}: Props) => {
  const { open, close } = useDialog()
  const discardChanges = useNodeDiscardChanges()
  const { t } = useTranslation()

  const [isSubmitted, setIsSubmitted] = useState<boolean>(!!initialForm)

  const formToSubmit: TNodeForm = useMemo(() => {
    const formNodeProps: TNodeForm = {
      type: NodeType.FORM,
      title,
      form: {
        title,
        action: action || '',
        response: responseRequestAdapter(
          postSubmissionBehavior,
          postSubmissionMessage,
          postSubmissionUrl,
        ),
        fields: formsFields,
      },
    }

    if (initialEmail === email) {
      formNodeProps.form.encryptedEmail = encryptedEmail
    }

    if (initialSpreadsheetURL === spreadsheetURL) {
      formNodeProps.form.encryptedSpreadsheetURL = encryptedSpreadsheetURL
    }

    if (typeof email !== 'undefined') {
      formNodeProps.form.email = email
    }

    if (typeof spreadsheetURL !== 'undefined') {
      formNodeProps.form.spreadsheetURL = spreadsheetURL
    }

    return formNodeProps
  }, [
    initialEmail,
    initialSpreadsheetURL,
    title,
    action,
    postSubmissionBehavior,
    postSubmissionMessage,
    postSubmissionUrl,
    formsFields,
    encryptedSpreadsheetURL,
    spreadsheetURL,
    email,
    encryptedEmail,
  ])

  const isEqual = useMemo(() => {
    if (!initialForm) {
      return false
    }

    return isEqualObjects(
      formToSubmit.form as unknown as Record<string, unknown>,
      {
        ...initialForm.form,
        email: initialEmail,
        spreadsheetURL: initialSpreadsheetURL,
      } as unknown as Record<string, unknown>,
      FORM_FIELDS_ALLOW_TO_CHANGE,
    )
  }, [initialForm, formToSubmit, initialEmail, initialSpreadsheetURL])

  const transformFormBeforeSave = useCallback(
    (form: INodeProps) => {
      const transformedForm = { ...form }
      if (
        transformedForm.form?.action === t('forms_submit_button_label_default')
      ) {
        transformedForm.form.action = ''
      }
      return transformedForm
    },
    [t],
  )

  const onSubmit = useCallback(() => {
    try {
      onSave(transformFormBeforeSave(formToSubmit as INodeProps))
      close()
    } catch (e) {
      close()
    }
  }, [close, onSave, formToSubmit, transformFormBeforeSave])

  const onFormCancel = useCallback(() => {
    if (!isEqual) {
      return discardChanges(close)
    }

    return onCancel && onCancel()
  }, [discardChanges, close, isEqual, onCancel])

  const showContinueAnywayDialog = useCallback(() => {
    open(
      <FormsSubmitWarning
        onSubmit={() => {
          onSubmit()
          close()
        }}
        onCancel={close}
        title={t('Some info is missing')}
        warningMessage={
          <ul className={styles.WarningList}>
            {!email ? <li>{t('info_missing_responses_email')}</li> : null}
            {/* 
              Temporary workaround before release Checkout - to be removed in next version
              TODO: Remove static values and uncomment the relevant code
             {isGoogleAccountConnected && !spreadsheetURL ? (
              <li>{t('info_missing_responses_google_sheet')}</li>
            ) : null} */}
          </ul>
        }
      />,
      {
        className: styles.Clear,
        withFullScreenDialogContainer: true,
      },
    )
  }, [open, close, onSubmit, t, email])

  const onFormSave = useCallback(() => {
    setIsSubmitted(true)

    if (
      isGoogleAccountConnected &&
      spreadsheetURL &&
      googleSheetResponseError
    ) {
      return undefined
    }

    if (email && responsesEmailError) {
      return undefined
    }

    if (
      postSubmissionUrl &&
      postSubmissionBehavior === CHECKOUT_POST_SUBMITTING_BEHAVIOUR.REDIRECT &&
      postSubmissionError
    ) {
      return undefined
    }

    if (!email) {
      return showContinueAnywayDialog()
    }

    // Temporary workaround before release Checkout - to be removed in next version
    // TODO: Remove static values and uncomment the relevant code
    // if (isGoogleAccountConnected && !spreadsheetURL) {
    //   return showContinueAnywayDialog()
    // }

    return onSubmit()
  }, [
    isGoogleAccountConnected,
    spreadsheetURL,
    googleSheetResponseError,
    email,
    responsesEmailError,
    postSubmissionUrl,
    postSubmissionBehavior,
    postSubmissionError,
    showContinueAnywayDialog,
    onSubmit,
    setIsSubmitted,
  ])

  return {
    isSubmitted,
    onFormSave,
    onFormCancel,
  } as const
}

export default useFormApi
