import { EventCategories } from '@features/analytics/models/logEvents'
import { useLogEvents } from '@features/analytics/useLogEvents'

export enum SubscriptionSourceEnum {
  Menu = 'menu',
  Account = 'account',
}

export enum PaywallSourceEnum {
  ACTIVATE_BOARD_LIST = 'activate_board_list',
  ACTIVATE_BOARD_SCREEN = 'activate_board_screen',
  ANALYTICS = 'analytics',
  BOARD_HEADER = 'board_header',
  BOARD_LIMIT_REACHED = 'boards_limit_reached',
  CAMPAIGN_BANNERS = 'campaign_banners',
  CHROME_EXTENSION_UPGRADE = 'chrome_extension',
  CREATE_BOARD_HOMESCREEN = 'create_board_homescreen',
  FORM_POST_SUBMISSION = 'form_post_submission',
  FREE_PLAN_UPDATE = 'free_plan_update',
  MESSAGES = 'messages',
  REMOVE_PAGE_CAMPAIGN = 'remove_page_campaign',
  VIDEO_LIMIT_REACHED = 'video_limit_reached',
  SUBSCRIPTIONS_CHANGE_PLAN = 'subscriptions_change_plan',
  COPY_SELECTION = 'copy_selection',
  COPY_CONTENT = 'copy_content',
  COPY_SHARED_FOLDER = 'copy_shared_folder',
  COPY_CONTENT_SHARED_FOLDER = 'copy_content_shared_folder',

  FORM_CREATE = 'form_create',
  CHECKOUT_CREATE = 'checkout_create',
  FORM_EDIT = 'form_edit',
  CHECKOUT_EDIT = 'checkout_edit',
  PAGE_EDITOR = 'page_editor',
  PAGE_PREVIEW = 'page_preview',
}

export enum PaywallSourceTypeEnum {
  DEFAULT = 'default',
  CAMPAIGN = 'campaign',
}

const useAccountSubscriptionEvents = () => {
  const { trigger } = useLogEvents()

  const subscriptionClickEvent = (source: SubscriptionSourceEnum) =>
    trigger(EventCategories.APP_SUBSCRIPTION_CLICK, {
      source,
    })
  const editPlanClickEvent = () => trigger(EventCategories.APP_EDIT_PLAN_CLICK)
  const changePlanClickEvent = () =>
    trigger(EventCategories.APP_CHANGE_PLAN_CLICK)
  const cancelPlanClickEvent = () =>
    trigger(EventCategories.APP_CANCEL_PLAN_CLICK)
  const confirmCancelClickEvent = () =>
    trigger(EventCategories.APP_CONFIRM_CANCEL_CLICK)
  const dismissCancelClickEvent = () =>
    trigger(EventCategories.APP_DISMISS_CANCEL_CLICK)
  const changeMethodClickEvent = () =>
    trigger(EventCategories.APP_CHANGE_METHOD_CLICK)
  const renewPlanEvent = () => trigger(EventCategories.APP_RENEW_PLAN)

  return {
    subscriptionClickEvent,
    editPlanClickEvent,
    changePlanClickEvent,
    cancelPlanClickEvent,
    confirmCancelClickEvent,
    dismissCancelClickEvent,
    changeMethodClickEvent,
    renewPlanEvent,
  }
}

export default useAccountSubscriptionEvents
