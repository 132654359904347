import React, { FC } from 'react'
import { Trans } from 'react-i18next'
import { BackIcon, ButtonIcon, Tooltip, TooltipContent } from 'boards-web-ui'

import CloseButtonWithTooltip from '../../../../ui/components/CloseButtonWithTooltip'

import { useEditForms } from '../hooks'
import { FORMS_ACTION_IDS } from '../models'

import styles from './EditFormsHeaderControls.module.css'

type Props = {
  onCancel?: () => void
}
const EditFormsHeaderControls: FC<Props> = ({ onCancel, ...rest }) => {
  const { action } = useEditForms()

  return (
    <>
      {action?.id === FORMS_ACTION_IDS.SETTINGS ? (
        <Tooltip
          data={
            <TooltipContent>
              <Trans i18nKey={'action_back'} />
            </TooltipContent>
          }
        >
          <ButtonIcon round size={38} variant="gray" onClick={action?.onCancel}>
            <BackIcon />
          </ButtonIcon>
        </Tooltip>
      ) : (
        <div className={styles.Space}></div>
      )}
      <div className={styles.HeaderControlsRoot} {...rest}>
        <div className={styles.HeaderControlsTitle}>
          {action?.title ? <h3>{action.title}</h3> : null}
        </div>
      </div>
      <CloseButtonWithTooltip onClose={onCancel} gray />
    </>
  )
}

export default React.memo(EditFormsHeaderControls)
