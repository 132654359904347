import { Trans } from 'react-i18next'

import useBoard from '../../../../hooks/useBoard'

import { ReactComponent as ActionShareIcon } from '../../../../icons/ActionShare.svg'

import AddShortcutIdButton from './AddShortcutIdButton'

import styles from './ShareShortcutButton.module.css'

const ShareShortcutButton = () => {
  const { isReadOnly, folder } = useBoard()

  const isPage = folder?.isPage

  return (
    <AddShortcutIdButton alignTooltipLeft={isReadOnly || isPage}>
      <ActionShareIcon className={styles.ShareBtn} />
      <Trans i18nKey={'action_share'} />
    </AddShortcutIdButton>
  )
}

export default ShareShortcutButton
