/* eslint no-console: ["error", { allow: ["warn"] }] */
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import { FirebaseError } from '@firebase/util'

import {
  Grid,
  ArrowBackIcon,
  ValidationRules,
  validateForm,
  Spacer,
} from 'boards-web-ui'
import useFeatureFlags from '@hooks/useFeatureFlags'
import usePhoneVerificationEvents from '@features/analytics/usePhoneVerificationEvents'

import Layout from '@features/layout/Layout'

import { authUserRecordChanged } from '_firebase/generalFunctions'
import { sendCodeToPhoneNumber, updateUserPhoneNumber } from '_firebase/auth'

import { PROFILING } from '../../constants/routes'
import Label from './components/Label'
import ButtonLink from './components/ButtonLink'
import SendCodeButton from './components/SendCodeButton'
import PhoneNumberInput from './components/PhoneNumberInput'
import VerificationCodeInput from './components/VerificationCodeInput'

import styles from './PhoneVerification.module.css'

const RECAPTCHA_CONTAINER = 'phone-number-input'

const MAX_SMS_CODES_SENT = 3
const MAX_CODE_SUBMISSION_ATTEMPTS = 10

function isFirebaseError(error: unknown): error is FirebaseError {
  return (error as FirebaseError).code !== undefined
}

const PhoneVerification = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const {
    verificationErrorEvent,
    verificationSkipClickEvent,
    verificationCodeSkipClickEvent,
    verificationCodeBackClickEvent,
    verificationCodeResendSkipViewEvent,
    verificationCodeErrorEvent,
    verificationCodeResendSkipClickEvent,
    verificationCodeVerifiedSuccessfullyEvent,
  } = usePhoneVerificationEvents()

  const [errors, setErrors] = useState<{ [fieldName: string]: string }>({})
  const [submitting, setSubmitting] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [verificationId, setVerificationId] = useState<string | undefined>()

  const [verificationCode, setVerificationCode] = useState('')
  const [forceSkip, setForceSkip] = useState(false)
  const [codesSent, setCodesSent] = useState(0)
  const [codesSentCountdown, setCodesSentCountdown] = useState(0)
  const [codeSubmissionAttempts, setCodeSubmissionAttempts] = useState(0)

  const { phoneVerificationFeatureFlag } = useFeatureFlags()

  const shortcutId = searchParams.get('shortcutId')
  const boardId = searchParams.get('boardId')
  const folderId = searchParams.get('folderId')

  const codesSentLimitReached = codesSentCountdown >= MAX_SMS_CODES_SENT
  const codeSubmissionAttemptsLimitReached =
    codeSubmissionAttempts >= MAX_CODE_SUBMISSION_ATTEMPTS
  const showTryAgainLaterMsgAndEnableBlueSkipBtn =
    forceSkip || codesSentLimitReached || codeSubmissionAttemptsLimitReached
  const enableSkipButton =
    showTryAgainLaterMsgAndEnableBlueSkipBtn ||
    phoneVerificationFeatureFlag.skipAllowed

  useEffect(() => {
    if (codeSubmissionAttempts === MAX_SMS_CODES_SENT) {
      verificationCodeResendSkipViewEvent()
    }
  }, [codeSubmissionAttempts, verificationCodeResendSkipViewEvent])

  const handleSkipClick = () => {
    if (phoneVerificationFeatureFlag.skipAllowed) {
      if (verificationId) {
        verificationCodeSkipClickEvent(showTryAgainLaterMsgAndEnableBlueSkipBtn)
      } else {
        verificationSkipClickEvent()
      }
    }

    if (codesSentLimitReached) {
      verificationCodeResendSkipClickEvent()
    }
    if (shortcutId && boardId && folderId) {
      navigate(
        `${PROFILING}?shortcutId=${shortcutId}&boardId=${boardId}&folderId=${folderId}`,
      )
    } else {
      navigate(PROFILING)
    }
  }

  const validatePhoneNumber = validateForm({
    number: [
      {
        rule: ValidationRules.isPhone,
        message: t('enter_phone_number_error'),
      },
    ],
  })

  const goBack = () => {
    setVerificationId('')

    setErrors({})
    setCodesSent(0)
    setCodeSubmissionAttempts(0)
    verificationCodeBackClickEvent()
  }

  const sendCode = async (phone: string) => {
    try {
      setErrors({})
      setSubmitting(true)

      const verificationIdCode = await sendCodeToPhoneNumber(
        phone,
        RECAPTCHA_CONTAINER,
      )

      setVerificationId(verificationIdCode)
      setCodesSent((prevCodesSent) => prevCodesSent + 1)
    } catch (error: unknown) {
      let errorMessage = t('enter_phone_number_error')

      if (isFirebaseError(error)) {
        console.warn('Send Code Firebase Error: ', error.code)

        switch (error.code) {
          case 'auth/account-exists-with-different-credential':
            errorMessage = t('enter_phone_number_used')
            setForceSkip(true)
            break
          case 'auth/too-many-requests':
            setForceSkip(true)
            return

          // no default
        }
      }

      setErrors({ input: errorMessage })
      verificationErrorEvent()
    } finally {
      setSubmitting(false)
      setVerificationCode('')
    }
  }

  const validateNumberAndSendCode = async (phone: string) => {
    const formErrors = validatePhoneNumber({ input: phone })
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors)
      return
    }
    setErrors({})

    setPhoneNumber(phone)
    sendCode(phone)
  }

  const updatePhoneNumber = async (verificationCodeId: string) => {
    if (!verificationId) {
      throw new Error(`Verification code is not present!`)
    }

    setErrors({})
    setSubmitting(true)
    setCodeSubmissionAttempts((prevAttempts) => prevAttempts + 1)

    try {
      await updateUserPhoneNumber(verificationId, verificationCodeId)
      verificationCodeVerifiedSuccessfullyEvent()
      authUserRecordChanged(phoneNumber)
      if (shortcutId && boardId && folderId) {
        navigate(
          `${PROFILING}?shortcutId=${shortcutId}&boardId=${boardId}&folderId=${folderId}`,
        )
      } else {
        navigate(PROFILING)
      }
    } catch (error: unknown) {
      let errorMessage = t('enter_code_incorrect_code')

      if (isFirebaseError(error)) {
        console.warn('Update Phone Firebase Error: ', error.code)

        switch (error.code) {
          case 'auth/account-exists-with-different-credential':
            errorMessage = t('enter_phone_number_used')
            break
          case 'auth/too-many-requests':
            setForceSkip(true)
            return

          // no default
        }
      }

      setErrors({ input: `${errorMessage}` })
      verificationCodeErrorEvent()
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Layout>
      <div className={styles.Root}>
        <div id={RECAPTCHA_CONTAINER} />

        <Grid justifyContent="space-between">
          {verificationId ? (
            <button onClick={goBack} aria-label="Go Back">
              <ArrowBackIcon />
            </button>
          ) : (
            <span />
          )}

          {enableSkipButton && (
            <ButtonLink
              primary={showTryAgainLaterMsgAndEnableBlueSkipBtn}
              onClick={handleSkipClick}
            >
              <Trans i18nKey={'action_skip'} />
            </ButtonLink>
          )}
        </Grid>

        {verificationId ? (
          <VerificationCodeInput
            errors={errors}
            submitting={submitting}
            phoneNumber={phoneNumber}
            verificationCode={verificationCode}
            onFocus={() => setErrors({})}
            onCodeSubmit={updatePhoneNumber}
            setVerificationCode={setVerificationCode}
            disabled={codeSubmissionAttemptsLimitReached}
          />
        ) : (
          <PhoneNumberInput
            errors={errors}
            submitting={submitting}
            disabled={showTryAgainLaterMsgAndEnableBlueSkipBtn}
            onFocus={() => setErrors({})}
            onSendNumber={validateNumberAndSendCode}
          />
        )}

        <Spacer height={60} />

        <div className={styles.FooterLinks}>
          {showTryAgainLaterMsgAndEnableBlueSkipBtn && (
            <Label>
              <Trans i18nKey={'enter_code_try_again'} />
            </Label>
          )}

          {verificationId && !showTryAgainLaterMsgAndEnableBlueSkipBtn && (
            <SendCodeButton
              iteration={codesSent}
              disabled={submitting}
              onCountdownEnds={() => {
                setCodesSentCountdown((prev) => prev + 1)
              }}
              onSendCodeClick={() => sendCode(phoneNumber)}
            />
          )}
        </div>
      </div>
    </Layout>
  )
}

export default PhoneVerification
