import { useSelector } from 'react-redux'
import { ViewOnlyIcon, HideIcon, Icon, BlackCheckedIcon } from 'boards-web-ui'

import Toast from '../elements/Toast'

import styles from './ToastMessageContainer.module.css'

const IconTypes = {
  hideIcon: 'HideIcon',
  viewOnly: 'ViewOnly',
}

const ToastMessageContainer = () => {
  const { msg, global, showIcon, customIcon } = useSelector(
    (state) => state.app.toast,
  )

  if (!msg) {
    return null
  }

  const toastMsg = global ? styles.Global : styles.Local

  return (
    <Toast className={toastMsg} variant="error">
      {showIcon && !customIcon && <BlackCheckedIcon className={styles.Icon} />}
      {customIcon === IconTypes.hideIcon && (
        <Icon size="20px">
          <HideIcon className={styles.Icon} />
        </Icon>
      )}
      {customIcon === IconTypes.viewOnly && (
        <Icon size="20px">
          <ViewOnlyIcon className={styles.Icon} />
        </Icon>
      )}
      {msg}
    </Toast>
  )
}

export default ToastMessageContainer
