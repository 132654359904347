import { FC, PropsWithChildren } from 'react'
import clsx from 'clsx'

import styles from './ButtonLink.module.css'

type Props = PropsWithChildren<{
  disabled?: boolean
  primary?: boolean
  onClick?: () => void
}>

const ButtonLink: FC<Props> = ({ primary, disabled, onClick, children }) => {
  return (
    <button
      className={clsx(styles.Root, primary && styles.Primary)}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  )
}

export default ButtonLink
