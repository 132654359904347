import { FormFields, NODE_LINK_TYPES } from 'boards-web-ui'

export enum NodeType {
  PDF = 'pdf',
  FORM = 'form',
  PAGE = 'page',
  TEXT = 'text',
  LINK = 'link',
  AUDIO = 'audio',
  IMAGE = 'image',
  VIDEO = 'video',
  VIDEO_STREAMING = 'video_streaming',
  BUTTON = 'button',
  FOLDER = 'folder',
  HEADLINE = 'headline',
  PARAGRAPH = 'paragraph',
  CHECKOUT = 'checkout',
}

export interface NodeFormField {
  type?: NodeType.CHECKOUT
  action?: string
  response?: string
  responseEmail?: string
  fields?: FormFields
}

export type Node = {
  id: string
  type: NodeType
  icon?: string
  title: string
  text: string
  thumbnail?: string
  size?: string
  duration?: number
  shortcut?: string
  diallingCode?: string
  countryCode?: string
  link_type?: NODE_LINK_TYPES
  state?: string
  isProcessing?: boolean
  height?: number
  image?: string
  width?: number
  form?: NodeFormField
}

export type ICountry = {
  countryCode?: string
  dialCode?: string
}
