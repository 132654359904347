import React from 'react'

import useToolbarTranslations from '../hooks/useToolbarTranslations'

import { SimpleDialog } from '../../../../ui/components/SimpleDialog'
import { SimpleButton } from '../../../../ui/components/SimpleButton'

import styles from './DeleteNodeConfirmation.module.css'

type Props = {
  title: string
  body: string
  onCancel: () => void
  onConfirm: () => void
  confirmTitle?: string
  cancelTitle?: string
  showVerticalActionButtons?: boolean
}
const DeleteNodeConfirmation: React.FC<Props> = ({
  title,
  body,
  onCancel,
  onConfirm,
  confirmTitle,
  cancelTitle,
  showVerticalActionButtons = false,
}) => {
  const { cancelDelete, confirmDelete } = useToolbarTranslations()
  return (
    <SimpleDialog
      className={[
        styles.Root,
        showVerticalActionButtons ? styles.WiderDialog : '',
      ].join(' ')}
      title={
        <p className={showVerticalActionButtons ? styles.WiderDialogTitle : ''}>
          {title}
        </p>
      }
      message={<p className={styles.Centred}>{body}</p>}
    >
      <div
        className={[
          styles.Controls,
          showVerticalActionButtons ? styles.VerticalActionButtons : '',
        ].join(' ')}
      >
        <SimpleButton
          secondary={!showVerticalActionButtons}
          primary={showVerticalActionButtons}
          large
          onClick={onCancel}
          fullWidth={showVerticalActionButtons}
          customBorderRadius={showVerticalActionButtons ? 19 : undefined}
        >
          {cancelTitle || cancelDelete()}
        </SimpleButton>
        <SimpleButton
          primary={!showVerticalActionButtons}
          secondary={showVerticalActionButtons}
          large
          regularFontWeight={showVerticalActionButtons}
          onClick={onConfirm}
          fullWidth={showVerticalActionButtons}
          customBorderRadius={showVerticalActionButtons ? 19 : undefined}
        >
          {confirmTitle || confirmDelete()}
        </SimpleButton>
      </div>
    </SimpleDialog>
  )
}

export default DeleteNodeConfirmation
