import { useCallback } from 'react'
import { TNodeCheckoutForm, urlHelper } from 'boards-web-ui'
import { useTranslation } from 'react-i18next'

import useShortcut from '@hooks/useShortcut'

import { Folder, Node, NodeType } from 'app/models'
import { AnalyticsNodeType, EventCategories, IRest } from './models/logEvents'
import { useLogEvents } from './useLogEvents'
import { getCountString } from './utils'

const usePageEvents = () => {
  const { t } = useTranslation()
  const { trigger } = useLogEvents()
  const { getShortcut } = useShortcut()

  const createPageEvent = (
    folder: Folder,
    boardId: string,
    isPageBoard?: boolean,
  ) =>
    trigger(EventCategories.APP_PAGE_CREATE, {
      key_id: folder.id,
      board_id: boardId,
      page_name: folder.title,
      label: folder.title,
      has_form: folder.content.some((n) => n.type === NodeType.FORM).toString(),
      has_checkout: folder.content
        .some((n) => n.type === NodeType.CHECKOUT)
        .toString(),
      items_count: getCountString(folder.content.length || 0),
      pages_in_homescreen: (!!isPageBoard).toString(),
    })
  const editPageEvent = (
    page: Folder,
    boardId: string,
    isPageBoard?: boolean,
  ) =>
    trigger(EventCategories.APP_PAGE_EDIT, {
      key_id: page.id,
      link_shortcut: getShortcut(page) || '',
      board_id: boardId,
      page_name: page.title,
      label: page.title,
      has_form: (
        page.content.filter((p) => p.type === NodeType.FORM).length > 0
      ).toString(),
      has_checkout: page.content
        .some((n) => n.type === NodeType.CHECKOUT)
        .toString(),
      items_count: getCountString(page.content.length),
      count: page.content.length,
      pages_in_homescreen: (!!isPageBoard).toString(),
    })
  const deletePageEvent = (
    node: Node,
    boardId: string,
    isPageBoard?: boolean,
  ) =>
    trigger(EventCategories.APP_PAGE_DELETE, {
      key_id: node.id,
      board_id: boardId,
      pages_in_homescreen: (!!isPageBoard).toString(),
    })
  const viewPageEvent = useCallback(
    (boardId: string, page: Folder, isPageBoard?: boolean) => {
      const checkoutNode = page.content.find(
        (node) => node.type === NodeType.CHECKOUT,
      )
      const hasCheckout =
        !!(checkoutNode as unknown as TNodeCheckoutForm)?.form?.paymentProviders
          ?.length || 'integration_error'

      trigger(EventCategories.APP_PAGE_VIEW, {
        key_id: page?.id,
        link_shortcut: getShortcut(page.shortcut) || '',
        board_id: boardId,
        has_checkout: checkoutNode ? hasCheckout : 'false',
        has_form: (
          page.content.filter((p) => p.type === NodeType.FORM).length > 0
        ).toString(),
        pages_in_homescreen: (!!isPageBoard).toString(),
      })
    },
    [trigger, getShortcut],
  )
  const pageContentClickEvent = useCallback(
    (boardId: string, rest?: IRest) =>
      trigger(EventCategories.APP_PAGE_CONTENT_CLICK, {
        board_id: boardId,
        ...rest,
      }),
    [trigger],
  )

  const pageCreateContentEvent = (
    node: Node,
    boardId: string,
    pageId: string,
    rest?: IRest,
  ) => {
    const extraParams: {
      post_submission_message?: string
      post_submission_redirect?: string
    } = {}

    if (node.type === NodeType.CHECKOUT) {
      return
    }

    if (node.type === NodeType.FORM) {
      if (node.form?.response) {
        extraParams.post_submission_message = (
          node.form?.response !== t('forms_thanks_message_default')
        ).toString()
      } else {
        extraParams.post_submission_message = 'false'
      }
      extraParams.post_submission_redirect = urlHelper
        .isValidUrl(node.form?.response)
        .toString()

      trigger(EventCategories.APP_FORM_CREATE, {
        key_id: node.id,
        label: node.title,
        board_id: boardId,
        try_free: 'false',
        button_type: '',
        page_id: pageId,
        type: 'form',
        ...rest,
        ...extraParams,
      })
      return
    }
    trigger(EventCategories.APP_PAGE_CONTENT_CREATE, {
      content_type: AnalyticsNodeType[node.type],
      key_id: node.id,
      label: node.title,
      board_id: boardId,
      try_free: 'false',
      button_type: node.type === NodeType.BUTTON ? node.link_type : '',
      page_id: pageId,
      ...rest,
      ...extraParams,
    })
  }
  const pageEditContentEvent = (
    node: Node,
    boardId: string,
    pageId: string,
    isGoogleAccountConnected?: boolean,
  ) => {
    const extraParams: {
      post_submission_message?: string
      post_submission_redirect?: string
      google_sheets_connected?: string
    } = {}
    if (node.type === NodeType.CHECKOUT) {
      return
    }

    if (node.type === NodeType.FORM) {
      extraParams.post_submission_message = (!urlHelper.isValidUrl(
        node.form?.response,
      )).toString()
      extraParams.post_submission_redirect = urlHelper
        .isValidUrl(node.form?.response)
        .toString()
      extraParams.google_sheets_connected =
        (!!isGoogleAccountConnected).toString()

      trigger(EventCategories.APP_FORM_EDIT, {
        key_id: node.id,
        board_id: boardId,
        page_id: pageId,
        type: 'form',
        ...extraParams,
      })
      return
    }

    trigger(EventCategories.APP_PAGE_CONTENT_EDIT, {
      content_type: AnalyticsNodeType[node.type],
      key_id: node.id,
      board_id: boardId,
      page_id: pageId,
      ...extraParams,
    })
  }
  const pageDeleteContentEvent = (
    node: Node,
    boardId: string,
    pageId: string,
  ) =>
    trigger(EventCategories.APP_PAGE_CONTENT_DELETE, {
      content_type: AnalyticsNodeType[node.type],
      key_id: node.id,
      board_id: boardId,
      page_id: pageId,
    })
  const pageInsertContentFromBoardsEvent = (
    count: number,
    boardId: string,
    pageId: string,
  ) =>
    trigger(EventCategories.APP_PAGE_CONTENT_INSERT_FROM_BOARDS, {
      items_count: getCountString(count),
      board_id: boardId,
      page_id: pageId,
    })
  const pageViewContentEvent = (node: Node, boardId: string, pageId: string) =>
    trigger(EventCategories.APP_PAGE_CONTENT_VIEW, {
      content_type: AnalyticsNodeType[node.type],
      key_id: node.id,
      board_id: boardId,
      page_id: pageId,
    })

  const addPageContentMenuItemClick = (
    type: NodeType,
    isTryFree: boolean,
    isBlocked: boolean,
  ) =>
    trigger(EventCategories.APP_PAGE_ADD_CONTENT_MENU_ITEM_CLICK, {
      content_type: type,
      try_free: isTryFree,
      blocked: isBlocked,
    })

  return {
    createPageEvent,
    editPageEvent,
    deletePageEvent,
    viewPageEvent,

    pageCreateContentEvent,
    pageEditContentEvent,
    pageDeleteContentEvent,
    pageInsertContentFromBoardsEvent,
    pageContentClickEvent,
    pageViewContentEvent,
    addPageContentMenuItemClick,
  } as const
}

export default usePageEvents
