import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

import { FormField, Grid, Icon } from 'boards-web-ui'

import { ADDITIONAL_FIELDS_TYPE } from '@features/forms/constants'
import { Node } from '../../../models'
import { ReactComponent as FormIcon } from '../icons/Form.svg'

import styles from './NodeForm.module.css'

interface Props {
  node: Node
}

const NodeForm: FC<Props> = ({ node }) => {
  const { t } = useTranslation()
  const hasTitle = !!node.title

  const fields = (node.form?.fields || []) as Array<FormField>
  const text = fields
    .map((f: FormField) => {
      return `${
        f.label || t(ADDITIONAL_FIELDS_TYPE[f.type]?.defaultValue) || ''
      }${f.required ? '*' : ''}`
    })
    .join(', ')

  return (
    <div className={clsx(styles.Root)}>
      <Icon size="44px">
        <FormIcon />
      </Icon>

      <Grid
        justifyContent="center"
        alignItems="flex-start"
        flexDirection="column"
      >
        {hasTitle && <div className={styles.Title}>{node.title}</div>}
        <div className={clsx(styles.Text, hasTitle && styles.WithOneLine)}>
          {text}
        </div>
      </Grid>
    </div>
  )
}

export default NodeForm
