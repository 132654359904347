import { useCallback, useMemo, useState } from 'react'

const useWindowURLTracking = (
  delay = 3000,
  withTrackingUrlChange?: boolean,
) => {
  const [changedParts, setChangedParts] = useState<string[]>([])

  const trackURLChange = useCallback(
    (newPopup?: Window | null, initialUrl = '') => {
      if (!newPopup) {
        return
      }

      try {
        const currentURL = newPopup?.location?.href || ''
        const currentURLParts = new URL(currentURL)
        const previousURLParts = new URL(initialUrl)

        const updatedChangedParts: string[] = []

        if (currentURLParts.hostname !== previousURLParts.hostname) {
          updatedChangedParts.push('hostname')
        }
        if (currentURLParts.pathname !== previousURLParts.pathname) {
          updatedChangedParts.push('pathname')
        }
        if (currentURLParts.search !== previousURLParts.search) {
          updatedChangedParts.push('search')
        }
        if (currentURLParts.hash !== previousURLParts.hash) {
          updatedChangedParts.push('hash')
        }

        setChangedParts(updatedChangedParts)
      } catch (e) {
        // nothing to do there ...
      }
    },
    [],
  )

  const openWindow = useCallback(
    (url: string, target: '_self' | '_blank' = '_blank') => {
      const newPopup = window.open(url, target)
      newPopup?.focus()
      let intervalId: unknown

      if (withTrackingUrlChange) {
        intervalId = setInterval(() => trackURLChange(newPopup, url), delay)
        newPopup?.addEventListener('beforeunload', () => {
          clearInterval(intervalId as number)
        })
      }

      return () => {
        if (intervalId) {
          clearInterval(intervalId as number)
        }

        newPopup?.close()
      }
    },
    [delay, trackURLChange, withTrackingUrlChange],
  )

  return useMemo(
    () =>
      ({
        changedParts,
        openWindow,
        trackURLChange,
      }) as const,
    [changedParts, openWindow, trackURLChange],
  )
}

export default useWindowURLTracking
