// TODO a lot of code was taken from deleted component MenuNewContentContainer - need to refactor it
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ButtonNew } from 'boards-web-ui'

import { DropDown, DropDownPosition } from '@ui/components/DropDown'
import useBulk from '@hooks/bulk/useBulk'
import AddContentMenu from './AddContentMenu'
import AddContentTooltip from './AddContentTooltip'

import useBoard from '../../../../hooks/useBoard'
import useAddFolderMenu from '../hooks/useAddFolderMenu'

import { ReactComponent as AddIcon } from '../icons/Add.svg'

import styles from './AddContentButton.module.css'

const AddContentButton: React.FC = () => {
  const { t } = useTranslation()
  const { folder, isPageBoard } = useBoard()
  const [isOpen, setIsOpen] = useState(false)

  const closeMenu = () => setIsOpen(false)

  const folderOptions = useAddFolderMenu(closeMenu)
  const { isSelectionModeEnabled } = useBulk()

  const isDisabled = isSelectionModeEnabled

  return isPageBoard ? (
    <ButtonNew
      color={'primary'}
      disabled={isDisabled}
      onClick={
        folderOptions.find((option) => option.title === t('menu_web_page'))
          ?.onClick
      }
      startIcon={<AddIcon />}
      size={'medium'}
    >
      {t('action_add_page')}
    </ButtonNew>
  ) : (
    <AddContentTooltip isOpen={!isOpen && folder?.content.length === 0}>
      <DropDown
        position={DropDownPosition.right}
        isOpen={isOpen}
        onToggle={setIsOpen}
        className={styles.Menu}
        disabled={isDisabled}
        content={<AddContentMenu options={folderOptions} />}
      >
        <ButtonNew
          color={'primary'}
          startIcon={<AddIcon />}
          size={'medium'}
          disabled={isDisabled}
        >
          {t('action_add')}
        </ButtonNew>
      </DropDown>
    </AddContentTooltip>
  )
}

export default AddContentButton
