import React, { FC } from 'react'

import usePaywall from '@hooks/usePaywall'
import { PaywallSourceEnum } from '@features/analytics/useAccountSubscriptionEvents'
import useCampaign from '../hooks/useCampaign'
import CampaignBanner from './CampaignBanner'

const CampaignBanners: FC = () => {
  const { banners, onCloseBanner } = useCampaign()
  const { openPaywall } = usePaywall()

  return banners ? (
    <>
      {Object.entries(banners).map(([id, banner]) => {
        return (
          <CampaignBanner
            key={id}
            banner={banner}
            onPaywall={() => openPaywall(PaywallSourceEnum.CAMPAIGN_BANNERS)}
            onClose={() => onCloseBanner(id)}
          />
        )
      })}
    </>
  ) : null
}

export default React.memo(CampaignBanners)
