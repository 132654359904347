import React, { PropsWithChildren } from 'react'
import { Trans } from 'react-i18next'
import { Tooltip, TooltipContent } from 'boards-web-ui'
import useCopyShortcuts from '@hooks/useCopyShortcuts'

import { SimpleButton } from '@ui/components/SimpleButton'

import useBulk from '@hooks/bulk/useBulk'
import { TooltipPositions } from '@ui/models/tooltip'
import useBoard from '../../../../hooks/useBoard'
import useShortcut from '../../../../hooks/useShortcut'
import useFeatureFlags from '../../../../hooks/useFeatureFlags'

import { ReactComponent as SuccessIcon } from '../../../../icons/ActionSuccess.svg'

import styles from './AddShortcutIdButton.module.css'

type Props = PropsWithChildren<{
  alignTooltipLeft?: boolean
}>
const AddShortcutIdButton: React.FC<Props> = ({
  alignTooltipLeft,
  children,
}) => {
  const { folder } = useBoard()
  const { sharedFolderFeatureFlag } = useFeatureFlags()
  const { getShortcut } = useShortcut()
  const { copyFolderShortcut } = useCopyShortcuts()
  const shortcut = getShortcut(folder)

  const { allowShareFolderAllowed } = useBoard()
  const { isSelectionModeEnabled } = useBulk()

  const isDisabled = isSelectionModeEnabled

  if (!sharedFolderFeatureFlag || !shortcut || !allowShareFolderAllowed) {
    return null
  }

  const shareBtnClassNames = (
    !isDisabled ? [styles.ShareBtn] : [styles.ShareBtn, styles.Disabled]
  ).join(' ')

  return (
    <Tooltip
      disabled={isDisabled}
      feedback
      position={
        alignTooltipLeft
          ? TooltipPositions.BOTTOM_RIGHT
          : TooltipPositions.BOTTOM
      }
      data={
        <TooltipContent icon={<SuccessIcon />} feedback>
          <Trans i18nKey={'toast_link_copied_to_clipboard'} />
        </TooltipContent>
      }
    >
      <SimpleButton
        name="generate shortcut link"
        lightBlue
        fitToContent
        mediumBtnIcon
        onClick={() => copyFolderShortcut(folder, false)}
        className={shareBtnClassNames}
      >
        {children}
      </SimpleButton>
    </Tooltip>
  )
}

export default AddShortcutIdButton
