// Temporary workaround before release Checkout - to be removed in next version
// Next line exists to allow isGoogleAccountConnected and isPayPalAccountConnected on findMissedFieldsInForm function
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Folder } from 'app/models'
import {
  isPageNodeCheckoutForm,
  isPageNodeForm,
  TPageNode,
  TPageNodeCheckoutForm,
  TPageNodeForm,
} from 'boards-web-ui'

export type IsMissedFormProperties = {
  paymentProvider?: boolean
  responseEmail?: boolean
  responseGoogleSheetUrl?: boolean
}

type findMissedFieldsInFormProps = {
  formNode: TPageNodeForm | TPageNodeCheckoutForm
  isGoogleAccountConnected?: boolean
  isPayPalAccountConnected?: boolean
}
export const findMissedFieldsInForm = ({
  formNode,
  isGoogleAccountConnected,
  isPayPalAccountConnected,
}: findMissedFieldsInFormProps): IsMissedFormProperties | undefined => {
  const missedProperties: IsMissedFormProperties = {}

  if (!formNode.form.encryptedEmail) {
    missedProperties.responseEmail = true
  }

  // Temporary workaround before release Checkout - to be removed in next version
  // TODO: Remove static values and uncomment the relevant code
  // if (
  //   formNode.form.type === NodeType.CHECKOUT &&
  //   (!isPayPalAccountConnected || !formNode.form.paymentProviders?.length)
  // ) {
  //   missedProperties.paymentProvider = true
  // }

  // if (isGoogleAccountConnected && !formNode.form.encryptedSpreadsheetURL) {
  //   missedProperties.responseGoogleSheetUrl = true
  // }

  return Object.keys(missedProperties).length ? missedProperties : undefined
}

type Props = {
  folder: Folder
  isGoogleAccountConnected?: boolean
  isPayPalAccountConnected?: boolean
}
export type IsMissedPageProperties = {
  [id: string]: IsMissedFormProperties
}
const findMissedFieldsInPgeForms = ({
  folder,
  isGoogleAccountConnected,
  isPayPalAccountConnected,
}: Props): IsMissedPageProperties => {
  return folder.content.reduce((res, node) => {
    if (
      isPageNodeForm(node as TPageNode) ||
      isPageNodeCheckoutForm(node as TPageNode)
    ) {
      const missedProperties = findMissedFieldsInForm({
        formNode: node as unknown as TPageNodeForm | TPageNodeCheckoutForm,
        isGoogleAccountConnected,
        isPayPalAccountConnected,
      })

      return missedProperties
        ? {
            ...res,
            [node.id]: {
              ...missedProperties,
            },
          }
        : { ...res }
    }

    return res
  }, {})
}

export default findMissedFieldsInPgeForms
