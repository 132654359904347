import { useDialog } from 'boards-web-ui'

import AddForm from '../components/AddForm'
import { IUseAddNode } from '../models/IUseAddNode'
import { useProfileInformation } from '../../profile/hooks/useProfileInformation'

const useAddForm: IUseAddNode = (save) => {
  const { open, close } = useDialog()
  const { hideFormsHint } = useProfileInformation()

  return () => {
    hideFormsHint()

    open(<AddForm onSave={save} onCancel={close} />, {
      fullScreen: true,
      blankDialog: true,
    })
  }
}

export default useAddForm
