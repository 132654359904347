import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { FirebaseError } from '@firebase/util'
import useIsOnline from '@hooks/useIsOnline'
import { useAuth } from '@hooks/useAuth'
import useFeatureFlags from '@hooks/useFeatureFlags'
import useOverwriteRouteRedirect from '@hooks/useOverwriteRouteRedirect'

import { EmailPasswordSignIn } from '_firebase/models/auth'
import {
  EDITOR,
  WELCOME,
  SIGN_UP,
  PROFILING,
  PHONE_VERIFICATION,
} from '../../../constants/routes'
import {
  firebaseEventsAsyncActions,
  userAsyncActions,
} from '../../../../actions'

import { isMobile } from '../../../../utils/deviceParser'
import getShortcuts from '../../../../utils/getShortcuts'

// eslint-disable-next-line
export const useSignUp = (setError: any) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { signUp } = useAuth()
  const [submitting, setSubmitting] = useState(false)
  const isOnline = useIsOnline()
  const { userProfilingFeatureFlag, phoneVerificationFeatureFlag } =
    useFeatureFlags()
  const { routeHasRedirect, redirectToOverwriteRoute } =
    useOverwriteRouteRedirect()

  const [searchParams] = useSearchParams()

  const source = searchParams.get('source')
  const linkShortcut = searchParams.get('link-shortcut')
  const shortcut = searchParams.get('shortcut')
  const shortcutId = searchParams.get('shortcutId')
  const boardId = searchParams.get('boardId')
  const folderId = searchParams.get('folderId')

  const shortcuts = getShortcuts(shortcut)

  useEffect(() => {
    dispatch(
      firebaseEventsAsyncActions.logEvent('app_screen_view', {
        screen: 'sign_up',
      }),
    )
  }, [dispatch])

  const onError = (errorField: string, message: string) => {
    setError(errorField, {
      type: 'server',
      message,
    })
  }

  const onSignUp = async (
    { name, ...data }: EmailPasswordSignIn & { name: string },
    { agreeEmailConsent }: { agreeEmailConsent: boolean },
  ) => {
    setSubmitting(true)
    dispatch(
      firebaseEventsAsyncActions.logEvent('app_button_click', {
        action: 'sign_up',
      }),
    )

    try {
      await signUp(data)

      const creationEventParams: Record<string, unknown> = {}

      if (source) {
        creationEventParams.source = source
      }

      if (shortcut) {
        creationEventParams.source_user_shortcut_id = shortcuts.user
        creationEventParams.source_folder_shortcut_id = shortcuts.node
        creationEventParams.link_shortcut = shortcut
      }

      if (linkShortcut) {
        creationEventParams.link_shortcut = linkShortcut
      }

      if (shortcutId) {
        creationEventParams.source = 'web_folder'
        creationEventParams.link_shortcut = linkShortcut
      }

      creationEventParams.email_consent_approved = agreeEmailConsent

      dispatch(
        userAsyncActions.finishUserCreation(name, creationEventParams, {
          agreeEmailConsent,
        }),
      )

      if (routeHasRedirect(SIGN_UP)) {
        redirectToOverwriteRoute()
        return
      }

      if (!phoneVerificationFeatureFlag.disabled) {
        if (shortcutId && boardId && folderId) {
          navigate(
            `${PHONE_VERIFICATION}?shortcutId=${shortcutId}&boardId=${boardId}&folderId=${folderId}`,
          )
          return
        }
        navigate(PHONE_VERIFICATION)
      } else if (userProfilingFeatureFlag) {
        if (shortcutId && boardId && folderId) {
          navigate(
            `${PROFILING}?shortcutId=${shortcutId}&boardId=${boardId}&folderId=${folderId}`,
          )
          return
        }
        navigate(PROFILING)
      } else if (isMobile) {
        navigate(WELCOME)
      } else {
        if (shortcutId && boardId && folderId) {
          navigate(
            `${EDITOR}?shortcutId=${shortcutId}&boardId=${boardId}&folderId=${folderId}`,
          )
          return
        }
        navigate(EDITOR)
      }
    } catch (error) {
      if (!isOnline) {
        setError('password', t('optimistic_ui_failed'))
        return
      }

      if (error instanceof FirebaseError) {
        onError('email', t([`errors.${error.code}`, 'errors.default']))
        dispatch(
          firebaseEventsAsyncActions.logEvent('app_error', {
            type: 'sign_up_failed',
            code: error.code,
          }),
        )
      }
    } finally {
      setSubmitting(false)
    }
  }

  return {
    submitting,
    onError,
    onSignUp,
  }
}
