import React, { DragEventHandler, FC } from 'react'

import { Board } from '../../../models'
import useRouterParams from '../../../../hooks/useRouterParams'

import BoardMenu from './BoardMenu'
import BoardMenuItem from './BoardMenuItem'

import styles from './BoardsMenu.module.css'

interface Props {
  boards: {
    [board: string]: Board
  }
  order: string[]
  inactive?: boolean
  title?: string
  draggedTo: number | undefined
  onDrop: () => void
  onDragOver: DragEventHandler<HTMLLIElement>
  onDragStart: DragEventHandler<HTMLLIElement>
  onDragLeave: () => void
}

const BoardsMenu: FC<Props> = ({
  title,
  boards,
  order,
  inactive,
  draggedTo,
  onDrop,
  onDragOver,
  onDragStart,
  onDragLeave,
}) => {
  const { boardId: selectedBoardId, folderId, bid } = useRouterParams()

  return (
    <>
      {title ? <h3 className={styles.Title}>{title}</h3> : null}
      <ul>
        {order.map((boardId, index) => {
          const isBoardSelected = boardId === selectedBoardId
          const initialStateCheck = boardId?.split('-')?.[1] === bid
          const selectedFolderId = isBoardSelected ? folderId : undefined
          const isActivated = boards?.[boardId]?.isActive

          return (inactive && isActivated) || (!inactive && !isActivated) ? (
            <li key={boardId} className={styles.Hidden} />
          ) : (
            <BoardMenu
              key={boardId}
              dataPosition={index}
              draggedTo={draggedTo}
              onDrop={onDrop}
              onDragOver={onDragOver}
              onDragStart={onDragStart}
              onDragLeave={onDragLeave}
            >
              <BoardMenuItem
                boardId={boardId}
                inactive={inactive}
                isBoardSelected={isBoardSelected}
                selectedFolderId={selectedFolderId}
                initialStateCheck={initialStateCheck}
              />
            </BoardMenu>
          )
        })}
      </ul>
    </>
  )
}

export default React.memo(BoardsMenu)
