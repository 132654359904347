import useToolbarEvents from '@features/analytics/useToolbarEvents'
import useFolderEvents from '@features/analytics/useFolderEvents'

import useBoard from './useBoard'
import useShortcut from './useShortcut'
import { useToast } from './useToast'
import { useClipboard } from './useClipboard'
import useShortcutTranslations from './useShortcutTranslations'

import { Folder, Node, NodeType } from '../app/models'

const useCopyShortcuts = () => {
  const toast = useToast()
  const { copyText } = useClipboard()
  const { boardId, isRoot, isPageBoard } = useBoard()
  const translations = useShortcutTranslations()
  const { shareFolderButtonClicked, sharePageButtonClicked } =
    useToolbarEvents()
  const { folderShareContentEvent } = useFolderEvents()
  const { getShortcut } = useShortcut()

  const copyFolderShortcut = async (folder: Folder, withToast = false) => {
    const shortcut = getShortcut(folder)
    await copyText(shortcut)

    if (withToast) {
      toast(translations.copyToast())
    }

    if (folder?.isPage) {
      const hasForm =
        folder.content.filter((n) => n.type === NodeType.FORM).length > 0
      const hasCheckout =
        folder.content.filter((n) => n.type === NodeType.CHECKOUT).length > 0
      sharePageButtonClicked(
        boardId,
        folder?.id,
        folder?.title,
        shortcut,
        hasForm,
        hasCheckout,
        isPageBoard,
      )
      return
    }

    shareFolderButtonClicked(
      boardId,
      folder.id,
      folder.title,
      isRoot ? 'board' : 'folder',
      shortcut,
    )
  }

  const copyNodeShortcut = async (node: Node, withToast = false) => {
    const shortcut = getShortcut(node)
    await copyText(shortcut)

    if (withToast) {
      toast(translations.copyToast())
    }

    folderShareContentEvent(1, boardId, node)
  }

  return { getShortcut, copyNodeShortcut, copyFolderShortcut }
}

export default useCopyShortcuts
