import { Trans, useTranslation } from 'react-i18next'

import React, { FC } from 'react'
import useBoards from '@hooks/useBoards'
import EmptyContent from '@features/pickers/components/EmptyContent'
import useBulkSelection from '@features/board/hooks/useBulkSelection'
import SelectBoardItem from './SelectBoardItem'
import { ReactComponent as EmptyBoardStateIcon } from '../../../../icons/EmptyBoardState.svg'

import styles from './BoardsSelection.module.css'
import useFirebaseListeners from '../../../../_firebase/hooks/useFirebaseListeners'

interface Props {
  selectBoard: (boardId: string) => void
  isReadMode?: boolean
}

const BoardsSelection: FC<Props> = ({ selectBoard, isReadMode }) => {
  const { t } = useTranslation()
  const { order, boards } = useBoards()
  const { isPageOnlySelected } = useBulkSelection()

  const hasActiveBoards = order.some((boardId) =>
    Boolean(boards[boardId]?.isActive),
  )

  const { startBoardPermissionsListener } = useFirebaseListeners()

  if (order.length === 0 || (isReadMode && !hasActiveBoards)) {
    return (
      <EmptyContent
        icon={<EmptyBoardStateIcon width="144" height="101" />}
        title={<Trans i18nKey={'desktop_no_boards_empty_state_title_2'} />}
      />
    )
  }

  return (
    <div className={styles.Root}>
      {order.map((boardId: string) => {
        const isSharedBoard = !boards[boardId]?.isOwn

        if (isReadMode && isSharedBoard) {
          const [uid, bid] = boardId.split('-')
          startBoardPermissionsListener(uid, bid)
        }

        const isDisabledPageBoard = Boolean(
          isReadMode && !isPageOnlySelected && boards[boardId]?.isPageBoard,
        )

        const isDisabled =
          (isSharedBoard && !boards[boardId]?.options.copyAllowed) ||
          isDisabledPageBoard

        return boards[boardId]?.isActive || boards[boardId]?.isPageBoard ? (
          <SelectBoardItem
            key={boardId}
            boardId={boardId}
            emoji={boards[boardId]?.icon}
            title={
              boards[boardId]?.isPageBoard
                ? t('label_web_pages')
                : boards[boardId]?.title
            }
            isShared={!boards[boardId]?.isOwn}
            onClick={() => selectBoard(boardId)}
            disabled={isDisabled}
            isPageBoard={boards[boardId]?.isPageBoard}
            isReadMode={isReadMode}
          />
        ) : null
      })}
    </div>
  )
}

export default BoardsSelection
