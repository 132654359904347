import { FC, memo } from 'react'
import { Icon, SpinnerIcon, Tag } from 'boards-web-ui'

import useUserFeatures, { Features } from '@hooks/useUserFeatures'

import CreateBoardButton from './components/CreateBoardButton'

import styles from './BoardsMenuTitle.module.css'

type Props = {
  className?: string
  loading?: boolean
  onCreate: () => void
}
const BoardsMenuTitle: FC<Props> = ({ className, loading, onCreate }) => {
  const { limit, usedCount } = useUserFeatures(Features.boards)
  return (
    <div
      className={[
        styles.TitleWrap,
        loading ? styles.Aligned : '',
        className,
      ].join(' ')}
    >
      <div className={styles.Root}>
        <h3 className={styles.Title}>Boards</h3>&nbsp;
        {limit !== 'unlimited' ? (
          <>
            <span>
              {usedCount}/{limit}
            </span>
            &nbsp;
            <Tag className={styles.Tag} secondary>
              Free
            </Tag>
          </>
        ) : null}
      </div>

      {loading ? (
        <Icon size="21px" color="#bcbcbc" className={styles.Margin}>
          <SpinnerIcon />
        </Icon>
      ) : (
        <CreateBoardButton onCreate={onCreate} rounded />
      )}
    </div>
  )
}

export default memo(BoardsMenuTitle)
