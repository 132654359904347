import React, { useState, FC, memo } from 'react'
import { useDialog } from 'boards-web-ui'

import ButtonIcon from '../../../../elements/ButtonIcon'
import Menu, { MenuItem } from '../../../../ui/components/Menu'
import DisableStateTooltip from '../../../../ui/components/DisableStateTooltip'
import { DropDown, DropDownPosition } from '../../../../ui/components/DropDown'
import { ReactComponent as MoreIcon } from '../../../../icons/More.svg'
import { ReactComponent as DeleteIcon } from '../../../../icons/Delete.svg'
import { TooltipPositions } from '../../../../ui/models/tooltip'

import { useUpdatesEvents } from '../../analytics/useUpdatesEvents'
import { useUpdatesTranslations } from '../hooks/useUpdatesTranslations'

import DeleteMessageConfirmation from './DeleteMessageConfirmation'
import styles from './UpdatesMessageMenu.module.css'

type Props = {
  onDelete?: () => void
}
const UpdatesMessageMenu: FC<Props> = ({ onDelete }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const { deleteButtonCLick } = useUpdatesEvents()
  const { deleteUpdateAction } = useUpdatesTranslations()

  const { open, close } = useDialog()

  const confirmDeleting = () => {
    setIsOpen(false)

    open(
      <DeleteMessageConfirmation
        onCancel={close}
        onDelete={() => {
          close()
          deleteButtonCLick()
          if (onDelete) {
            onDelete()
          }
        }}
      />,
      { closeOnOverlayClick: true },
    )
  }

  return (
    <DropDown
      isOpen={isOpen}
      onToggle={setIsOpen}
      position={DropDownPosition.right}
      className={styles.Menu}
      content={
        <Menu as="div">
          <MenuItem as="button" type="button" onClick={confirmDeleting}>
            <DeleteIcon className={styles.Icon} />
            {deleteUpdateAction()}
          </MenuItem>
        </Menu>
      }
    >
      <DisableStateTooltip
        isOpen={isOpen ? false : undefined}
        disabled={isOpen}
        data="Actions"
        position={TooltipPositions.BOTTOM_RIGHT}
      >
        <ButtonIcon size={38} variant="gray" round isActive={isOpen}>
          <MoreIcon />
        </ButtonIcon>
      </DisableStateTooltip>
    </DropDown>
  )
}

export default memo(UpdatesMessageMenu)
