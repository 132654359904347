import React, { PropsWithChildren, ReactNode } from 'react'
import styles from './SimpleDialog.module.css'

type Props = PropsWithChildren<{
  title?: string | ReactNode
  message?: string | ReactNode
  className?: string
}>

export const SimpleDialog: React.FC<Props> = ({
  children,
  title,
  message,
  className,
  ...rest
}) => (
  <div className={[styles.Root, className].join(' ')} {...rest}>
    {title && <span className={styles.Title}>{title}</span>}
    {message && <span className={styles.Message}>{message}</span>}

    <div className={styles.Controls}>{children}</div>
  </div>
)
