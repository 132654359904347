import { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import { ErrorIndicator, ErrorIndicatorSize } from 'boards-web-ui'

import { move } from '@helpers/array'
import { INodeProps } from '@features/nodeEditor/models/IUseNodeCreatorAPI'
import useEditNode from '@features/nodeEditor/useEditNode'
import { IsMissedPageProperties } from 'utils/findMissedFieldsInForm'

import useRouterParams from '../../../../hooks/useRouterParams'
import useConfirmDialog from '../../../../hooks/useConfirmDialog'
import useNode from '../../board/hooks/useNode'

import { BoardMode } from '../../board/models/nodes'
import { Folder, Node } from '../../../models'

import EmptyContent, {
  EmptyBoardVariants,
} from '../../../../components/EmptyContent'
import CreatePageBanner from './CreatePageBanner'
import PageNode from '../../nodes/PageNode'
import ListItem from './ListItem'

import useBrandedPagesBanner from '../hooks/useBrandedPagesBanner'

import styles from './ListItems.module.css'

interface Props {
  mode: BoardMode
  folder: Folder
  updateNode: (id: string, node: INodeProps) => void
  deleteNode?: (node: Node) => void
  missedPageProperties?: IsMissedPageProperties
}

const ListItems: FC<Props> = ({
  mode,
  folder,
  updateNode,
  deleteNode,
  missedPageProperties,
}) => {
  const { t } = useTranslation()
  const { boardId, folderId } = useRouterParams()
  const editNode = useEditNode(boardId, folder.id, updateNode, true)
  const confirmDialog = useConfirmDialog()
  const { moveNode } = useNode({ boardId, folderId: folder.id, mode })
  const [items, setItems] = useState<Node[]>(folder.content)
  const { onDeleteBrandedBanner, unbrandedPages } = useBrandedPagesBanner(
    boardId,
    folderId,
    folder,
  )

  useEffect(() => {
    setItems(folder.content)
  }, [folder.content, setItems])

  const moveItem = useCallback((dragIndex: number, hoverIndex: number) => {
    setItems((prevCards) => move(prevCards, dragIndex, hoverIndex))
  }, [])

  const moveItemAPI = useCallback(
    (nodeId: string, beforeIndex: number) => {
      const beforeNode = items[beforeIndex + 1]
      moveNode(nodeId, beforeNode?.id)
    },
    [items, moveNode],
  )

  const handleDeleteNode = (node: Node) => {
    confirmDialog({
      title: t('confirmation_message_delete_selected_key'),
      message: t('dialog_delete_this_key'),
      confirmText: t('action_delete'),
      cancelText: t('action_cancel'),
      onConfirm: () => {
        if (deleteNode) deleteNode(node)
      },
    })
  }

  if (items.length === 0) {
    return <EmptyContent variant={EmptyBoardVariants.pageEditor} />
  }

  return (
    <div className={styles.Content}>
      <DndProvider backend={HTML5Backend}>
        <div className={styles.Root}>
          {items.map((item: Node, index: number) => {
            const withIndicator = !!missedPageProperties?.[item.id]

            return (
              <ListItem
                key={item.id}
                id={item.id}
                index={index}
                disabled={mode !== BoardMode.write}
                move={moveItem}
                end={moveItemAPI}
                withIndicator={withIndicator}
                onClick={() => editNode(item, missedPageProperties?.[item.id])}
                onDelete={() => handleDeleteNode(item)}
              >
                <PageNode node={item} />
                {withIndicator ? (
                  <ErrorIndicator
                    rounded
                    size={ErrorIndicatorSize.SMALL}
                    className={styles.Indicator}
                    onClick={(e) => {
                      e.stopPropagation()
                      editNode(
                        item,
                        missedPageProperties?.[item.id],
                        'settings',
                      )
                    }}
                  >
                    {t('info_missing_add_missing_details')}
                  </ErrorIndicator>
                ) : null}
              </ListItem>
            )
          })}
          {folder.content.length > 0 && !unbrandedPages ? (
            <CreatePageBanner
              unbrandedPages={unbrandedPages}
              onDelete={onDeleteBrandedBanner}
            />
          ) : null}
        </div>
      </DndProvider>
    </div>
  )
}

export default ListItems
