import { EditForms } from '@features/forms'
import { TNodeForm } from 'boards-web-ui'

import { FORMS_ACTION_IDS } from '@features/forms/models'

import { ICreateOrUpdateNode } from '../models/IUseNodeCreatorAPI'

interface Props {
  initialScreen?: FORMS_ACTION_IDS
  isGoogleAccountConnected: boolean
  node: TNodeForm
  onSave: ICreateOrUpdateNode
  onCancel: () => void
}

const EditForm = ({
  node,
  initialScreen,
  isGoogleAccountConnected,
  onSave,
  onCancel,
}: Props) => {
  return (
    <EditForms
      isGoogleAccountConnected={isGoogleAccountConnected}
      initialScreen={initialScreen}
      initialNode={node}
      onSave={onSave}
      onCancel={onCancel}
    />
  )
}

export default EditForm
