import React, { FC } from 'react'
import {
  ButtonIcon,
  CloseIcon,
  ChromeWebStoreIcon,
  Icon,
  ForwardIcon,
} from 'boards-web-ui'
import { useTranslation } from 'react-i18next'
import useSidebarEvents from '@features/analytics/useSidebarEvents'
import useStyledSubtitle from '@features/sidebar/hooks/useStyledSubtitle'
import { useProfileInformation } from '../../profile/hooks/useProfileInformation'
import { ENV_KEYS } from '../../../../utils/envKeys'
import { configurationObjectFromEnv } from '../../../../utils/configurationObjectFromEnv'
import styles from './ChromeAndMobileBanner.module.css'

export interface ChromeExtensionBannerProps {
  uiState: boolean | null
}

const ChromeExtensionBanner: FC<ChromeExtensionBannerProps> = ({ uiState }) => {
  const { t } = useTranslation()
  const { hideChromeExtensionBanner } = useProfileInformation()
  const { desktopCtasChromeClick, desktopCtasDismissClick } = useSidebarEvents()

  const [before, bold, after] = useStyledSubtitle(
    'desktop_cta_chrome_subtitle',
    'desktop_cta_chrome_subtitle_placeholder',
  )

  const handleChromeClick = () => {
    desktopCtasChromeClick()
  }

  const handleCloseIconClick = () => {
    hideChromeExtensionBanner()
    desktopCtasDismissClick('chrome')
  }

  if (
    localStorage.getItem('boards-chrome-extension-is-installed') === '1' ||
    uiState
  ) {
    return null
  }

  const { chromeExtensionUrl } = configurationObjectFromEnv({
    chromeExtensionUrl: ENV_KEYS.CHROME_EXTENSION_URL,
  })

  return (
    <div className={styles.Root}>
      <div className={styles.Header}>
        <Icon size="32px">
          <ChromeWebStoreIcon />
        </Icon>
        <ButtonIcon
          size={10}
          className={styles.Close}
          onClick={handleCloseIconClick}
        >
          <Icon size="12px" color="#222222">
            <CloseIcon />
          </Icon>
        </ButtonIcon>
      </div>
      <p className={styles.Message}>
        {before}
        <span>{bold}</span>
        {after}
      </p>
      <a
        href={`${chromeExtensionUrl}`}
        target="_blank"
        rel="noreferrer"
        className={styles.Forward}
        onClick={handleChromeClick}
      >
        {t('desktop_cta_chrome_button')}
        <Icon size="16px">
          <ForwardIcon />
        </Icon>
      </a>
    </div>
  )
}

export default ChromeExtensionBanner
