import { Trans } from 'react-i18next'
import { ModalHeader, ModalHeaderLeft } from '../../../../components/Modal'
import ButtonIcon from '../../../../elements/ButtonIcon'
import { ReactComponent as VectorCross } from '../../../../icons/VectorCross.svg'
import { ReactComponent as DeleteIcon } from '../../../../icons/Delete.svg'

import styles from './MembersHeader.module.css'

interface Props {
  members: number
  onClose: () => void
  onDelete: () => void
}
const MembersHeader = ({ members, onClose, onDelete }: Props) => (
  <ModalHeader bgColor="#E5F2FF">
    <ModalHeaderLeft>
      <ButtonIcon onClick={onClose}>
        <VectorCross className={styles.MembersCloseIcon} />
      </ButtonIcon>
      <span className={styles.MembersCount}>
        <Trans i18nKey="share_screen_subtitle" values={{ count: members }} />
      </span>
    </ModalHeaderLeft>
    <ButtonIcon onClick={onDelete}>
      <DeleteIcon className={styles.MembersDeleteIcon} />
    </ButtonIcon>
  </ModalHeader>
)

export default MembersHeader
