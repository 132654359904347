import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ErrorIndicator, ErrorIndicatorSize, Spacer } from 'boards-web-ui'

import { CHECKOUT_POST_SUBMITTING_BEHAVIOUR } from '@features/checkout/constants'
import useCheckoutAnalytics from '@features/analytics/useCheckoutAnalytics'
import { useCheckout } from '@features/checkout/hooks'
import CheckoutSettingsPaymentInformation from './CheckoutSettingsPaymentInformation'
import CheckoutSettingsPostSubmissionInformation from './CheckoutSettingsPostSubmissionInformation'
import CheckoutSettingsResponses from './CheckoutSettingsResponses'

import styles from './CheckoutSettings.module.css'

const CheckoutSettings: FC = () => {
  const { t } = useTranslation()
  const { appCheckoutSettingsView } = useCheckoutAnalytics()
  const {
    isOwn,
    isSubmitted,

    responsesEmail,
    responsesEmailError,
    spreadsheetURL,

    isGoogleAccountConnected,
    googleSheetResponseError,

    postSubmissionBehavior,
    postSubmissionUrl,
    postSubmissionError,
  } = useCheckout()

  useEffect(() => {
    appCheckoutSettingsView()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const shouldShowWrongFieldsIndicator = (): boolean => {
    if (!isOwn) {
      return false
    }

    if (!isSubmitted) {
      return false
    }

    if (!!responsesEmail.trim().length && responsesEmailError) {
      return true
    }

    if (
      postSubmissionUrl?.trim().length &&
      postSubmissionBehavior === CHECKOUT_POST_SUBMITTING_BEHAVIOUR.REDIRECT &&
      postSubmissionError
    ) {
      return true
    }

    if (
      isGoogleAccountConnected &&
      spreadsheetURL?.trim()?.length &&
      googleSheetResponseError
    ) {
      return true
    }

    return false
  }

  return (
    <>
      {shouldShowWrongFieldsIndicator() ? (
        <>
          <ErrorIndicator
            inverseColors
            size={ErrorIndicatorSize.MEDIUM}
            className={styles.Indicator}
          >
            {t('validation_errors_fix_errors_to_continue')}
          </ErrorIndicator>
          <Spacer height={38} />
        </>
      ) : (
        ''
      )}

      <CheckoutSettingsPaymentInformation />
      <div className={styles.Line} />
      <CheckoutSettingsResponses />
      <div className={styles.Line} />
      <CheckoutSettingsPostSubmissionInformation />
    </>
  )
}

export default React.memo(CheckoutSettings)
