import { DocumentSnapshot } from 'firebase/firestore'
import { cloneDeep, merge } from 'lodash-es'
import { UserProfile, UserProfileFeatureLimit } from '@models/UserProfile'

export const DEFAULT_PROFILE: UserProfile = {
  activeBoards: [],
  name: undefined,
  shortcut: '',
  formResponseEmail: undefined,
  formResponseSpreadsheet: undefined,
  formResponseSpreadsheetStatus: undefined,
  googleAuthorization: {
    access_token: undefined,
    expiry_date: undefined,
    refresh_token: undefined,
    scope: undefined,
    token_type: undefined,
  },
  uiState: {
    pagesHintShown: false,
    videoHintShown: false,
    buttonsHintShown: false,
    formsHintShown: false,
    monetizationBannerShown: false,
    chromeExtensionBannerShown: false,
    installMobileAppHintShown: false,
    freemiumMessagesHintShown: false,
    pagesBoardHiddenFromChrome: false,
    checkoutHintShown: false,
  },
  plan: {
    features: {
      advancedForms: false,
      analytics: false,
      announcements: false,
      copyReceivedContent: false,
      contentRestrictions: false,
      saveImages: false,
      unbrandedPages: false,
      uploadAudio: false,
      uploadVideo: false,
      webApp: false,
      forms: false,
      checkout: false,
    },
    limits: {
      audios: 0,
      boards: 'unlimited' as UserProfileFeatureLimit,
      messages: 0,
      shares: 0,
      videos: 0,
    },
    monetized: false,
  },
  store: {
    accountToken: undefined,
    countryCode: undefined,
  },
  usage: {
    boards: 0,
    audios: 0,
    messages: 0,
    shares: 0,
    videos: 0,
  },
}

export const transformProfile = (doc: DocumentSnapshot) => {
  const data = doc.data()
  return merge(cloneDeep<UserProfile>(DEFAULT_PROFILE), data, {}) as UserProfile
}
