import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useFormsEvents from '@features/analytics/useFormsEvents'

import { isEmail } from '../../../../utils/validators'

const useSettingsResponses = (responsesEmail: string) => {
  const { t } = useTranslation()
  const { responsesEmailChangedEvent } = useFormsEvents()

  const [responsesEmailState, setResponsesEmailState] = useState<string>(
    responsesEmail || '',
  )

  const responsesEmailError: string = useMemo(() => {
    if (!responsesEmailState.trim().length) {
      return t('info_missing_enter_email_address')
    }

    if (!isEmail.test(responsesEmailState)) {
      return t('info_invalid_response_email')
    }

    return ''
  }, [responsesEmailState, t])

  const onResponsesEmailChange = useCallback(
    (email: string) => {
      responsesEmailChangedEvent()
      setResponsesEmailState(email)
    },
    [responsesEmailChangedEvent, setResponsesEmailState],
  )

  useEffect(() => {
    setResponsesEmailState(responsesEmail || '')
  }, [responsesEmail])

  return {
    responsesEmail: responsesEmailState,
    responsesEmailError,
    onResponsesEmailChange,
  } as const
}

export default useSettingsResponses
