import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDialog } from 'boards-web-ui'

import useBoard from '@hooks/useBoard'

import useBulk from '@hooks/bulk/useBulk'
import Menu, { MenuItem } from '@ui/components/Menu'
import { SimpleButton } from '@ui/components/SimpleButton'
import { DropDown, DropDownPosition } from '@ui/components/DropDown'
import DeleteNodeConfirmation from './DeleteNodeConfirmation'

import { ReactComponent as DeleteIcon } from '../icons/Delete.svg'
import { ReactComponent as MoreIcon } from '../../../../icons/More.svg'

import styles from './FolderActions.module.css'
import useNodeDeletorAPI from '../../nodeEditor/hooks/useNodeDeletorAPI'
import { NodeType } from '../../../models'

type MenuProps = {
  deleteAction: string
  deleteTitle: string
  deleteConfirmation: string
  deletedToastMsg: string
  onItemClick: () => void
}
export const FolderActionsMenu: React.FC<MenuProps> = ({
  deleteAction,
  deleteTitle,
  deleteConfirmation,
  deletedToastMsg,
  onItemClick,
}) => {
  const navigate = useNavigate()
  const { open, close } = useDialog()
  const { boardId, folderId, isFolderEmpty, parent } = useBoard()
  const { deleteNode } = useNodeDeletorAPI(boardId, folderId)

  const deleteFolder = (): void => {
    if (onItemClick) {
      onItemClick()
    }

    const node = {
      id: folderId,
      type: NodeType.FOLDER,
      icon: '',
      text: '',
      title: '',
    }
    deleteNode(node, deletedToastMsg, parent.id)
    navigate(`/${boardId}/${parent.id}`)
  }

  const confirmDelete = (): void => {
    onItemClick()
    open(
      <DeleteNodeConfirmation
        title={deleteTitle}
        body={deleteConfirmation}
        onCancel={close}
        onConfirm={() => {
          deleteFolder()
          close()
        }}
      />,
    )
  }

  return (
    <Menu as="div">
      <MenuItem
        as="button"
        name={deleteTitle}
        onClick={isFolderEmpty ? deleteFolder : confirmDelete}
      >
        <DeleteIcon className={styles.ItemIcon} />
        {deleteAction}
      </MenuItem>
    </Menu>
  )
}

type Props = {
  deleteAction: string
  deleteTitle: string
  deleteConfirmation: string
  deletedToastMsg: string
  className?: string
}
const FolderActions: React.FC<React.PropsWithChildren<Props>> = ({
  deleteAction,
  deleteTitle,
  deleteConfirmation,
  deletedToastMsg,
  className,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const { isReadOnly } = useBoard()

  const { isSelectionModeEnabled } = useBulk()

  const selectionActive = isSelectionModeEnabled

  if (isReadOnly) {
    return null
  }

  return (
    <DropDown
      disabled={selectionActive}
      position={DropDownPosition.left}
      className={[styles.Menu, className].join(' ')}
      isOpen={isOpen}
      onToggle={setIsOpen}
      content={
        <FolderActionsMenu
          deleteAction={deleteAction}
          deleteTitle={deleteTitle}
          deleteConfirmation={deleteConfirmation}
          deletedToastMsg={deletedToastMsg}
          onItemClick={() => setIsOpen(false)}
        />
      }
    >
      <SimpleButton
        lightGray
        mediumBtnIcon
        name="more"
        customSize={38}
        isActive={isOpen}
        className={selectionActive ? styles.Disabled : ''}
      >
        <MoreIcon className={[styles.Icon, styles.Centred].join(' ')} />
      </SimpleButton>
    </DropDown>
  )
}

export default React.memo(FolderActions)
